import gql from 'graphql-tag';

import { INSPECTION_FRAGMENT } from '../fragments/InspectionFragments';


export default gql`
mutation AddInspectionTemplateField(
    $templateId: ID!,
    $categoryId: ID!,
    $fieldName: String!,
    $fieldType: String!,
    $createWorkOrderOnFail: Boolean,
    $required: Boolean
   )  {
  addInspectionTemplateField (
      templateId: $templateId,
      categoryId: $categoryId,
      fieldName: $fieldName,
      fieldType: $fieldType,
      createWorkOrderOnFail: $createWorkOrderOnFail,
      required: $required
    ) {
      ...InspectionAllFields
  }
}
${INSPECTION_FRAGMENT}
`;

