import gql from "graphql-tag";

export default gql`
query InventoryItem($id:ID,$barcodeData:String,$barcodeType:String){
  inventoryItem(id: $id,barcodeType:$barcodeType,barcodeData:$barcodeData) {
    id
    partName
    location
    numberInStock
    category {
      id
      category
    }
    warningThreshold
    inventoryItems {
      id
      partNumber
      vendor
      cost
      numberInStock
      barcodeType
      barcodeData
      partDescription
      
    }

  }
}
`;