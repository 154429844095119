import React, {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import {Container,Card,CardHeader,CardBody,CardTitle, Row, Col, Form, Button} from 'reactstrap';
import AnimateHeight from 'react-animate-height';
import SearchInput from '../components/WorkOrders/SearchInput';

import InventoryTable from '../components/Inventory/InventoryTable';
import PurchaseOrdersTable from '../components/Inventory/PurchaseOrdersTable';
import InventoryStats from '../components/Inventory/InventoryStats';
import LowInventoryTable from '../components/Inventory/LowInventoryTable';
import InventoryActions from '../components/Inventory/InventoryActions';
import SelectCategory from '../components/Inventory/SelectCategory';
import Pagination from "../components/Inventory/InventoryPagination.js";

import Icon from '@mdi/react'
import {mdiRunFast,mdiClipboardTextOutline} from '@mdi/js'

export default function InventoryScreen() {
	const limit = 25;
  	// set card height for bottom cards to window minus 60 (padding at top)  minus 50 (page title) minus 34 (gab between rows) minus 6 just for spacing
  	const gapNumber =  70+50+34+6;
  	const [tableCardHeight,setTableCardHeight] = useState((window.innerHeight - gapNumber)/2); // divide by 2 card 2 cards
  	const [lowInventoryCardHeight,setLowInventoryCardHeight] = useState((window.innerHeight - 70-50-40-122)); // divide by 2 card 2 cards
	const [filterFields,setFilterFields] = useState({});
	const [offset,setOffset] = useState(0);

	const [height,setHeight] = useState('auto')
	const [hidePurchaseOrders,setHidePurchaseOrders] = useState(localStorage.getItem("hidePurchaseOrders") && localStorage.getItem("hidePurchaseOrders") === "true" ? true : false)

	const searchKeyword = throttle(1000,false,(keyword) => {
		setOffset(0);
		setFilterFields({...filterFields,keyword});
	})


	useEffect(() => {
		function handleResize() {
			setTableCardHeight((window.innerHeight - gapNumber)/2)
			setLowInventoryCardHeight(window.innerHeight - 70-50-40-122)

		}
		window.addEventListener('resize', handleResize)
	})

	const handleShowPOs = () => {
		
		const newVal = !hidePurchaseOrders
		localStorage.setItem("hidePurchaseOrders",newVal)
		setHidePurchaseOrders(newVal)
	}

	return (
		<Container fluid>
		{hidePurchaseOrders}
			<Link to="/Inventory/Log" className="float-right ml-2"><Button color="primary"><Icon path={mdiClipboardTextOutline} size={1}  /> Inventory Log</Button></Link>
			<Link to="/Inventory/MorningRush" className="float-right"><Button color="light"><Icon path={mdiRunFast} size={1}  /> Morning Rush View</Button></Link>
			<Button className="float-right" color="link" onClick={() => handleShowPOs()}>{hidePurchaseOrders ? "Show Purchase Orders" : "Hide Purchase Orders"}</Button>
			<h2>Inventory</h2>
			<Row>
				<Col md={3}>
					<InventoryStats />
					<Card className="shadow mb-4" style={{height:lowInventoryCardHeight,minHeight:178}}>
		        		<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
		        			<CardTitle className="m-0">Low Inventory</CardTitle>
		        		</CardHeader>
		        		<CardBody className="p-0 h-100" style={{overflow:"auto"}}>
		        			<LowInventoryTable />
		        		</CardBody>
					</Card>


				</Col>
				<Col md={9}>
				{!hidePurchaseOrders && 
				<AnimateHeight
					
		          id='example-panel'
		          duration={ 500 }
		          height={ height } // see props documentation below
		        >
					<Card className="shadow mb-4" style={{height:tableCardHeight,minHeight:150}}>
		        		<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
		        			<CardTitle className="m-0">Purchase Orders 
		        				<Link to="/PurchaseOrder/New" className="float-right"><Button color="primary">Add Purchase Order</Button></Link>
			        			{/*<Button onClick={() => {
			        				if (height === 'auto') {
			        					setHeight(600);
			        				}
			        				else {
			        					setHeight('auto')
			        				}
			        			}}>test</Button>*/}
		        			</CardTitle>
		        		</CardHeader>
		        			<CardBody className="pt-0 pb-0 h-100">
		        				<PurchaseOrdersTable  />
		        			</CardBody>
					</Card>
				</AnimateHeight>}
					<Card className="shadow mb-4" style={{height:hidePurchaseOrders ? (tableCardHeight*2)+24 : tableCardHeight,minHeight:150}}>
		        		<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
								<Pagination 
									offset={offset} 
									limit={limit}
									filterFields={filterFields}
									onPrev={() => offset > 0 ? setOffset(offset-limit) : null}
									onNext={() => setOffset(offset+limit)}
								/>		        			
								<div className="form-inline">
		        				<CardTitle className="m-0">Inventory</CardTitle>

		        				<SearchInput placeholder="Search Inventory" searchKeyword={searchKeyword} />
		        				<InventoryActions offset={offset} limit={limit} filterFields={filterFields} />
		        				<SelectCategory filterFields={filterFields} setFilterFields={setFilterFields} />
		        			</div>
		        		</CardHeader>
		        		<CardBody className="pt-0 pb-0 h-100">
		        			<InventoryTable offset={offset} limit={limit} filterFields={filterFields} />
		        		</CardBody>

					</Card>

				</Col>
			</Row>

		</Container>
	);
}