import React, {useState} from "react";

// reactstrap components
import {

  Container,
  Button,
  Table,
  Spinner,
Card,
}
 from "reactstrap";


import {useQuery} from '@apollo/client';
import Permit from '../graphql/queries/Permit';

import moment from 'moment';

import UpdatePermitDateModal from '../components/Permits/UpdatePermitDateModal';
import AddAssetToPermitModal from '../components/Permits/AddAssetToPermitModal';
import RemovePermitFromAssetModal from '../components/Permits/RemovePermitFromAssetModal';

export default function PermitDetail({match:{params:{id}}}) {

  const {data,loading} = useQuery(Permit,{variables:{id}})
  const [modalVisible_update,setModalVisible_update] = useState(false);
  const [modalVisible_add,setModalVisible_add] = useState(false);
  const [modalVisible_remove,setModalVisible_remove] = useState(false);
  const [startDate,setStartDate] = useState();
  const [renewalDate,setRenewalDate] = useState();
  const [assignedTo,setAssignedTo] = useState();


  if (loading) return <Spinner />

  const updatePermitTruck = (assignedTo) => {
    setAssignedTo(assignedTo);
    setStartDate(moment(assignedTo.startDate).format("YYYY-MM-DD"));
    setRenewalDate(moment(assignedTo.renewalDate).format("YYYY-MM-DD"))
    setModalVisible_update(true);
  }

  const updatePermits = () => {
    setAssignedTo(null);
    // use date from first entry
    setStartDate(moment(data.permit.assignedTo[0].startDate).format("YYYY-MM-DD"));
    setRenewalDate(moment(data.permit.assignedTo[0].renewalDate).format("YYYY-MM-DD"))
    setModalVisible_update(true);
  }

  const addToPermit = () => {
    setAssignedTo(data.permit.assignedTo);
    // use date from first entry
    setStartDate(moment(data.permit.assignedTo[0].startDate).format("YYYY-MM-DD"));
    setRenewalDate(moment(data.permit.assignedTo[0].renewalDate).format("YYYY-MM-DD"))
    setModalVisible_add(true);
  }
  const removeFromPermit = (assignedTo) => {
    setAssignedTo(assignedTo);
    setModalVisible_remove(true);
  }


	return (
        <Container fluid>
          <h2 >
            Permit: {data?.permit?.permitName}
                          <Button 
                            className="ml-3 mr-1 btn btn-primary"
                            size="sm"
                            onClick={() => updatePermits()}
                            >Update</Button>
                     
                        <Button color="primary" size="sm" onClick={() => addToPermit()}>
                          Add Permit
                        </Button>


          </h2>
               <Card className="shadow">

                <Table>
                  <thead>
                    <tr>
                      <th>Equipment</th>
                      <th>Permit Active</th>
                      <th>Permit Expiration</th>
                      <th>Update</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody>
                  
                    {data.permit.assignedTo && data.permit.assignedTo.map((a,index) => 
                      <tr key={index}>
                        <td>
                          <strong>{a.truck.identifier}</strong> 
                        </td>
                        <td>
                          {moment(a.startDate).format('L')}  
                        </td>
                        <td> 
                          {moment(a.renewalDate).format('L')}
                          {moment(a.renewalDate).diff(moment().add(30,'days'),'days') < 0 && 
                             
                              <span className="badge badge-danger ml-1">{(new Date(a.renewalDate) < new Date()) ? "OVERDUE" : "Expiring" }</span>
                          }
                        </td>
                        <td>
                          <button 
                            className="btn btn-primary"
                            onClick={() => updatePermitTruck(a)}
                            >Update</button>
                        </td>
                        <td>
                          <Button className="btn btn-danger"
                           onClick={() => removeFromPermit(a)}
                          >Remove</Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
   

              </Card> 

          <RemovePermitFromAssetModal
            permit={id}
            assignedTo={assignedTo}
            modalVisible={modalVisible_remove}
            setModalVisible={setModalVisible_remove}

           />
          <UpdatePermitDateModal 
            permit={id}
            startDate={startDate}
            renewalDate={renewalDate}
            assignedTo={assignedTo}
            modalVisible={modalVisible_update}
            setModalVisible={setModalVisible_update}

           />
           <AddAssetToPermitModal
            permit={id}
            startDate={startDate}
            renewalDate={renewalDate}
            assignedTo={assignedTo}
            modalVisible={modalVisible_add}
            setModalVisible={setModalVisible_add}

           />
        </Container>
      );

}
