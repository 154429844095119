import gql from 'graphql-tag';

export default gql`
mutation UpdateOdometer($id:ID!, $odometer: Float!) {
  updateOdometer(id: $id,odometer:$odometer) {
    id
    identifier
    type
    lastReportedMileage
  }
}`;