import gql from 'graphql-tag';

export default gql`
{
  paymentPlans {
    planName
    planId
    price
    available
    unitCount
  }
}
`;