import gql from 'graphql-tag';

export default gql`
query UsageReport($startDate: DateTime!,$endDate:DateTime!) {
  inventoryUsageReport(startDate:$startDate,endDate:$endDate) {
    partName
    partNumber
    vendor
    totalAdded
    totalRemoved
  }
}
`;

