import React from 'react';
import AssetForm from '../components/Assets/AssetForm';
import { Spinner } from 'reactstrap';

import { useMutation, useQuery } from '@apollo/client';
import UpdateEquipment from '../graphql/mutations/UpdateEquipment';
import Trucks from '../graphql/queries/Trucks';
import Truck from '../graphql/queries/Truck';

import { useHistory } from "react-router-dom";


export default function UpdateAssetScreen({match:{params:{id}}}) {
	const history = useHistory();
	//const id = navigation.getParam('id')

	const {data} = useQuery(Truck, {
		variables: {
			id
		}
	})

	const [updateEquipment,{loading}] = useMutation(UpdateEquipment,{
		refetchQueries:[
			{query:Trucks},
			{query:Truck,variables:{id}} // cache not updating based on id. Force update
		],
		onCompleted: () => history.goBack(),
		onError: error => {
				alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error updating equipment . Please try again.`)

			}

	})


  


  	// save function. putting it here so view can take advantage of loading var of mutation
	const saveEquipment = async (variables) => {

		updateEquipment({variables})
	} 



		
		return (
			<>
				{loading && <div className="h-100 w-100 text-center" style={{padding: "50%",position:"absolute",zIndex: 100,background:"rgba(0,0,0,.5)"}}><Spinner /></div>}
				{data && <AssetForm id={id}
								  title="Edit Asset" 
								  saveEquipment={saveEquipment} 
								  initialVariables={data.truck}
								  />}	
			</ >
			)


}



