import React, {useState} from 'react';
// reactstrap components
import {

  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import Trucks from '../../graphql/queries/Trucks';

export default function TruckFilterButton({truck,onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedTruck,setSelectedTruck] = useState()


	const {data,loading,error} = useQuery(Trucks);

	if (loading || error) return null;
	if (!data) return null;



	const truckSelected = (truck) => {
		// announce to parent that something changed
		if (onChange) onChange(truck.id);
		setSelectedTruck(truck)
		setIsOpen(false)
	}



	return (
		<ButtonDropdown color="info" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret color="white" >
				{selectedTruck ? selectedTruck.identifier : 'Select Asset' }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10}}>
				<div style={{height: 200,overflowY: "auto"}}>
				{data.trucks.map(truck =>
					<div key={truck.id} style={{cursor:"pointer"}} onClick={() => truckSelected(truck)}>{truck.identifier}</div>
				)}
				</div>
				<DropdownItem divider />
			      

				<Button color="link" onClick={() => {setSelectedTruck(null);onChange();setIsOpen(false)}}>
					Reset
				</Button>
			</DropdownMenu>
		</ButtonDropdown>
	);
}