import React from 'react';
import { Buffer } from 'buffer';
import axios from 'axios';

import {Modal,ModalBody,ModalHeader,ModalFooter, Button, Spinner} from 'reactstrap';
import PhotoList from '../PhotoList';

import {useMutation} from '@apollo/client';
import AddTruckPhotos from '../../graphql/mutations/AddTruckPhotos';

// refetch is needed to force images to load once they are uploaded to aws
export default function AddPhotosModal({modalVisible,setModalVisible,assetId,refetch}) {

	const [photos,setPhotos] = React.useState([])
	const [photosProcessing,setPhotosProcessing] = React.useState(false)

	const [addTruckPhotos,{loading}] = useMutation(AddTruckPhotos,{
		onCompleted: async (result) => {
			console.log("Result photos",result)
			setPhotosProcessing(true);
			await result.addTruckPhotos.photosToUpload.forEach(putFileOnAWS)
			setPhotosProcessing(false);
			setPhotos([]);
			setModalVisible(false);
			if (refetch) {
				console.log("refetching")
				refetch()
			}
		}
	});

	const addPhotos = () => {
		addTruckPhotos({variables:{
			id:assetId,
			photos: photos.map(file => ({
            	filename: file.name,
            	type: file.type,
            	contenttype: file.type,
            	height: null,
            	width: null

			}))
		}})
	}

	const cancel = () => {
		setModalVisible(false)
	}


	const  readFileAsync = (file) => {
	  return new Promise((resolve, reject) => {
	    let reader = new FileReader();

	    reader.onload = () => {
	      resolve(reader.result);
	    };

	    reader.onerror = reject;
			reader.readAsDataURL(file);
	    //reader.readAsArrayBuffer(file);
	  })
	}

	const putFileOnAWS = async (data,index) => {
		const {url} = data;
		// get file as base64
		const theFile = await readFileAsync(photos[index])
			const buffer = Buffer.from(theFile.replace(/^data:image\/\w+;base64,/, ""),'base64');

		await axios.put(
			url,
			buffer,
			{
				headers :{
					"Content-Type": "image/jpeg"
				}

			}
		)



	}





	return (
		<Modal isOpen={modalVisible}
			onClosed={() => setPhotos([])}
		>
			<ModalHeader>
				Add Photos
			</ModalHeader>
			<ModalBody>
				<PhotoList onChange={photos => setPhotos(photos)} />
			</ModalBody>
			<ModalFooter>
				<Button color="primary" onClick={() => addPhotos()}>Add Photos {(photosProcessing === true || loading) && <Spinner size="sm" />}</Button>
				<Button color="danger" onClick={() => cancel()}>Cancel</Button>
			</ModalFooter>
		</Modal>
	)
}