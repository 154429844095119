import React, {useState} from "react";

// reactstrap components
import {
  Spinner,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert,
  Table,
Card, CardHeader, CardTitle, CardBody
}
 from "reactstrap";


import { useQuery, useMutation } from "@apollo/client";
import ServicePlan from "../graphql/queries/ServicePlan";
import AddServicePlanService from "../graphql/mutations/AddServicePlanService"; 
import DeleteServicePlanService from "../graphql/mutations/DeleteServicePlanService"; 


export default function ServicePlanScreen({match:{params:{id}}}) {
 
  const [formFieldValues,setFormFieldValues] = useState({})

  const {data,loading,error} = useQuery(ServicePlan,{
    variables: {id}
  });


  const [addServicePlanService,{loading:loadingSave}] = useMutation(AddServicePlanService, {
    // service plan cache isn't refreshing on its own
    refetchQueryies: [{query: ServicePlan,variables:{id}}]
  });
  const [deleteServicePlanService,{loading:loadingDelete}] = useMutation(DeleteServicePlanService, {
    // service plan cache isn't refreshing on its own
    refetchQueryies: [{query: ServicePlan,variables:{id}}]
  });

  const save = () => {

    const {service,units,trackingMeasurement} = formFieldValues;

    // all fields required
    if (!service || !units) {
      alert("Please fill in all fields")
      return;
    }

    addServicePlanService({variables:{
        id,
        service,
        units: parseFloat(units),
        trackingMeasurement: trackingMeasurement || data.servicePlan.measurementType
      }})
      .then(() => setFormFieldValues({trackingMeasurement:data.servicePlan.measurementType}))
      .catch(error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the service. Please try again.`))
  }
 
  const handleDelete = ({serviceId,service}) => {
    const confirm = window.confirm(`Are you sure that you want to remove ${service}?`)

    if (!confirm) return;

    deleteServicePlanService({variables:{id,serviceId}})

  }


  if (loading) return <Spinner />;
  if (error) return <Alert danger>Error loading service</Alert>



	return (

        <Container fluid>

               <Card className="shadow mb-4">
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Add Service</CardTitle></CardHeader>
                <CardBody>
                  <Row className="align-items-center">
                    <Col>
     			            <Form onSubmit={e => e.preventDefault()} className="">
                        <FormGroup className="mb-0" >                          
                            <Input placeholder="Service (i.e. Oil Change, Check Tires, etc)" value={formFieldValues.service || ""} type="text" onChange={(e) => setFormFieldValues({...formFieldValues,service:e.target.value})} />
                        </FormGroup>
                        <FormGroup className="mb-0" >                          
                            <Input placeholder="Every.. (How often?)" type="text" value={formFieldValues.units || ""}  onChange={(e) => setFormFieldValues({...formFieldValues,units:e.target.value})} />
                        </FormGroup>
                        <FormGroup className="mb-1" >

                            <Input type="select" name="select" 
                               value={formFieldValues.trackingMeasurement || ""}  onChange={(e) => setFormFieldValues({...formFieldValues,trackingMeasurement:e.target.value})}
                            >
                              <option value={data.servicePlan.measurementType}>{data.servicePlan.measurementType}</option>
                              <option value="days">days</option>
                              <option value="weeks">weeks</option>
                            </Input>
                        </FormGroup>
                        <Button block color="primary" onClick={() => save()} >
                          Add Service
                          {loadingSave && <Spinner size="sm" className="ml-2" />}
                        </Button>
    			            </Form>
                    </Col>

                  </Row>

                  </CardBody>
                </Card>

                <Card className="shadow">
                  <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Service Items {loadingDelete && <Spinner size="sm" />}</CardTitle></CardHeader>
                  <CardBody>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">SCHEDULED ITEM</th>
                      <th scope="col">HOW OFTEN</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.servicePlan.serviceSchedule.map(({
                      id,
                      service,
                      units,
                      trackingMeasurement
                    }) => {
                         return (
                          <tr key={id} >
                            <td>{service}</td>
                            <td>Every {units.toLocaleString()} {trackingMeasurement}</td>
                            <td>
                              <Button link onClick={() => handleDelete({serviceId:id,service})}>remove</Button>
                            </td>
                          </tr>
                         )

                    }

                    )}
                  </tbody>
                </Table>
                {data.servicePlan.serviceSchedule.length === 0 && <p className="p-2">Please add a service</p>}
                </CardBody>
              </Card> 



                        
                        
        </Container>
 
      );

}
