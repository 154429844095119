
import React, {useState,useEffect} from "react";

// reactstrap components
import {
  Button,
  Badge,
  Card,

  CardBody,
  Container,
  Row,
  Col,
  Spinner
} from "reactstrap";
// core components

import {Link} from 'react-router-dom';

import { useQuery, useMutation } from '@apollo/client';
import Employee from '../graphql/queries/Employee';
import GeneratePassword from '../graphql/mutations/GeneratePassword';

import moment from 'moment';

import AssignedWorkOrders from '../components/People/AssignedWorkOrders';
import ReportedWorkOrders from '../components/People/ReportedWorkOrders';

import AuthSection from '../components/AuthSection';

export default function EmployeeDetail({match:{params:{id}}}) {
  // set card height for bottom cards to window minus 60 (padding at top)  minus 40 (page title) minus 24 (gab between rows) minus 6 just for spacing
  const gapNumber =  60+50+24+6;
  const [cardHeight,setCardHeight] = useState((window.innerHeight - gapNumber)/2); // divide by 2 card 2 cards
  

  useEffect(() => {
    function handleResize() {
      setCardHeight((window.innerHeight - gapNumber)/2)
    }
    window.addEventListener('resize', handleResize)
  })


  const {data,loading} = useQuery(Employee, {
    variables: {
      id
    }
  })

  const [generatePassword,{loading:loadingPassword}] = useMutation(GeneratePassword,{
      variables:{id},
      onCompleted: () => window.alert("Password sent")
    })


  const sendNewPassword = () => {
    const cont = window.confirm("This will create a new password and delete the current password for your employee. Are you sure you want to continue? ")

    if (!cont) return;

    generatePassword();

  }

  if (loading) return <Spinner />

    const {
      firstName,
      lastName,
      phones,
      email,
      driversLicenseExpiration,
      medicalCardExpiration,
      roles
    } = data.employee;

    return (

        <Container fluid>
          <h2>
            {firstName} {lastName}
                      <Link className="btn btn-link ml-2" style={{fontSize: 14}} to={`/People/edit/${id}/Update`}>

                        Edit
  
                      </Link>

          </h2>
          <Row>
             <Col md={4}> 
     <Card className="shadow" style={{height:(cardHeight*2)+24}} >


      <CardBody style={{overflowY:"auto"}}>
   
                
                  <Row>
                    <Col>
                      {phones.map(({type,number},index) => 
                          <div key={index} className="mb-2">
                            {type}
                          <div className="muted"><a href="sms:{number}">{number}</a></div>
                          </div>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      
                          <div className="mb-2">
                            E-mail
                          <div className="text-muted"><a href="mailto:{email}">{email}</a></div>
                          </div>
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      
                          <div className="mb-2">
                            Role(s)
                            <div className="text-muted">
                              {(!roles || roles.length === 0) && "n/a"}

                              {roles.join(', ')}
                            </div>
                          </div>
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      
                          <div className="mb-2">
                            Driver's License Expiration
                            <div className="text-muted">
                              {driversLicenseExpiration && moment(driversLicenseExpiration).diff(moment().add(30,'days'),'days') < 0 && <Badge color="danger" className="mb-1 mr-1">License Expiring</Badge>}
                              {driversLicenseExpiration ? moment(driversLicenseExpiration).format("M/D/YYYY") : "n/a"}
                             </div>
                          </div>
                     
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      
                          <div className="mb-2">
                            Medical Card Expiration
                          <div className="text-muted">
                              {medicalCardExpiration && moment(medicalCardExpiration).diff(moment().add(30,'days'),'days') < 0 && <Badge color="danger" className="mb-1 mr-1">Med Card Expiring</Badge>}

                            {medicalCardExpiration ? moment(medicalCardExpiration).format("M/D/YYYY") : "n/a"}</div>
                          </div>
                     
                    </Col>
                  </Row>
                  <AuthSection>
                    <Button onClick={() => sendNewPassword()} className="mt-4" color="danger" size="sm" block>Send New Password {loadingPassword && <Spinner size="sm" />}</Button>
                  </AuthSection>
                </CardBody>
               </Card>

            </Col>
   
            <Col md={8}>
              <AssignedWorkOrders id={id} cardHeight={cardHeight} />
              <ReportedWorkOrders id={id} cardHeight={cardHeight} />
            </Col>
          </Row>
        </Container>

    );

  
}

