import gql from 'graphql-tag';

export default gql`
mutation RemoveLocation ($id: ID!) {
  removeLocation(id: $id) {
  	  id
      locationName
  }

}

`;