import gql from 'graphql-tag';

export default gql`
mutation RemoveTruckType ($type: String!) {
  removeTruckType(type: $type) {
    type
    measure
  }

}

`;