import React from 'react';
import {
	  FormGroup, Input, Label,Card, CardHeader, CardTitle, CardBody, Col
} from 'reactstrap';

import {useQuery} from '@apollo/client';
import UdfFields from '../../graphql/queries/UdfFields';

const getFieldValue = ({fieldName,udfs}) => {
	// if there are no udfs, return empty
	if (!udfs || udfs.length === 0)
		return "";

	// find value in udfs for given field name if it exists. else return empty string
	const field = udfs.find(el => el.fieldName === fieldName);
	if (!field)
		return "";

	return field.fieldValue;
}

export default function UdfFormFields({udfs,setUdfs}) {

	const {data} = useQuery(UdfFields);

	if (!data) return null;


	return (
		<Card className="shadow mb-4">
		
        	<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Custom Fields</CardTitle></CardHeader>
       		<CardBody>
	       		{data.udfs.map(({fieldName},index) => 
		            <FormGroup key={index} row>
		              <Label sm={2}>{fieldName}</Label>
		              <Col sm={10}>
			              <Input 
			              		 //placeholder={fieldName} 
			              		 value={getFieldValue({udfs,fieldName})}
			              		onChange={e => {
			              			
			              			const newFieldValue = e.target.value;
			              			let newUdfs = [...udfs]; // can't update udf field directly
			              			
			              			// if udf doesn't exist in array, add it first
			              			if (!udfs.find(el => el.fieldName === fieldName)) {
			              				newUdfs.push({fieldName,fieldValue:""});
			              			}
			              		 	setUdfs(
			              		 		newUdfs.map(u => {
			              		 			// if fieldNames don't match, return current value
			              		 			if (u.fieldName !== fieldName) 
			              		 				return u;

			              		 			// update field value and return
			              		 			return {...u,fieldValue:newFieldValue};
			              		 		})
			              		 	)
			              		 }}
			              		 />
		              </Col>
		            </FormGroup>

	        	)}
        	</CardBody>
        </Card>		
	);

}