import gql from 'graphql-tag';

export default gql`
mutation AddInventoryItem(
  $partName:String!,
  $partNumber:String,
  $vendor:String!,
  $partDescription:String,
  $cost:Float,
  $barcodeData:String,
  $barcodeType:String,
  $numberInStock:Float,
  $warningThreshold:Float,
  $location: String,
  $category: Int
) {
  addInventoryItem(
    partName:$partName,
    location: $location,
    partNumber:$partNumber,
     vendor:$vendor,
    partDescription:$partDescription,
    cost:$cost,
    barcodeData:$barcodeData,
    barcodeType:$barcodeType,
    numberInStock:$numberInStock,
    warningThreshold: $warningThreshold,
    category: $category
  ) {
    id
    partName
    numberInStock,
    warningThreshold,
    location

  }
}
`;