import React from 'react';
import { Table, Alert, Badge, Spinner, Input} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

import { useQuery } from '@apollo/client';
import Employees from '../../graphql/queries/Employees';

export default function EmployeesTable({filterFields,showCheckboxes=false,onChange}) {
  const [randomVal,setRandomVal] = React.useState(1) // this is used to force a refetch when filters change.
  const [selectedEmployees,setSelectedEmployees] = React.useState([]);
  const [selectAll,setSelectAll] = React.useState(false);
	const {data,loading,error,refetch} = useQuery(Employees,{
      fetchPolicy:"cache-and-network",
      variables: {
        ...filterFields,
        randomVal
      }
    });


  React.useEffect(() => {
    const newRnd = parseInt(randomVal)+1
    setRandomVal(newRnd)
   
  },[filterFields])

  const handleSelectAll = () => {
    setSelectAll(true);

    // set all visible as selected
    const ids = data.employees
        .filter(({firstName,lastName}) => filterFields.keyword ? (`${firstName} ${lastName}`).toLowerCase().indexOf(filterFields.keyword.toLowerCase()) > -1 : true)
        .filter(({isActive}) => (filterFields.showInActive === true && isActive === false) || (filterFields.showInActive === false && isActive === true))
        .map(({id}) => id)

    setSelectedEmployees(ids)
    // announce to parent that something changed
    if (onChange) onChange(ids);

  }

  const employeeChecked = ({id}) => {

    setSelectAll(false);

    // if in array, remove. if not in there, add it
    const i = selectedEmployees.indexOf(id);
    let newSelectedEmployees = [...selectedEmployees];
    if (i >= 0) {
      newSelectedEmployees.splice(i,1);
    }
    else {
      newSelectedEmployees.push(id);
    }

    setSelectedEmployees(newSelectedEmployees)
    

    // announce to parent that something changed
    if (onChange) onChange(newSelectedEmployees);
  }


	if (loading) return <Spinner />;
	if (error) return <Alert danger>Error loading employees</Alert>

	return (

                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      {showCheckboxes &&
                         <th className="text-center">
                          <Input type="checkbox" 
                                style={{margin:0,position:"relative"}}
                               checked={selectAll}
                               onChange={() => handleSelectAll()}
                               />                           
                         </th>
                       }
                      <th scope="col">Name</th>
                      <th scope="col">Roles</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone</th>
                    </tr>
                  </thead>
                  <tbody>
                  	{data.employees
                      .filter(({firstName,lastName}) => filterFields.keyword ? (`${firstName} ${lastName}`).toLowerCase().indexOf(filterFields.keyword.toLowerCase()) > -1 : true)
                      .filter(({isActive}) => (filterFields.showInActive === true && isActive === false) || (filterFields.showInActive === false && isActive === true))
                      .map(({
                    		id,
                    		firstName,
                    		lastName,
                    		phones,
                    		email,
                    		roles,
                        driversLicenseExpiration,
                        medicalCardExpiration
                    	}) =>
	                    <tr key={id}>
                        {showCheckboxes && 
                          <td className="text-center">
                          <Input type="checkbox" 
                                style={{marginLeft:0}}
                               checked={selectedEmployees.indexOf(id) !== -1}
                               onChange={() => employeeChecked({id})}
                               />
                            
                          </td>
                        }
	                      <th scope="row">
                          <Link to={`/People/${id}`}>{firstName} {lastName}</Link>
                          {driversLicenseExpiration && moment(driversLicenseExpiration).diff(moment().add(30,'days'),'days') < 0 && <Badge color="danger" className="mb-1" style={{display:"block"}}>License Expiring</Badge>}
                          {medicalCardExpiration && moment(medicalCardExpiration).diff(moment().add(30,'days'),'days') < 0 && <Badge color="danger" className=" mb-1" style={{display:"block"}}>Med Card Expiring</Badge>}

                        </th>
	                      <td>{roles.join(', ')}</td>
                        <td>{email}</td>
	                      <td>
                          {phones.map(({type,number},index) =>
                            <div key={index}>{type}: {number}</div>
                          )} 
                        </td>
	                    </tr>

                  	)}
                  </tbody>
                </Table>

	);
}