import gql from "graphql-tag";

export default gql`
 {
	inventoryAlerts {
	id
    partName
    numberInStock
    warningThreshold
  }
}
`;