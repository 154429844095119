import gql from 'graphql-tag';

export default gql`
query UsageReport_PDF($startDate: DateTime!,$endDate:DateTime!) {
  inventoryUsageReport_PDF(startDate:$startDate,endDate:$endDate) {
   url
  }
}
`;

