import gql from "graphql-tag";



export default gql`
mutation SetComplete($id: Int!){	
	  setComplete(id:$id) {
	    id



	  }
	
}
`;


