import gql from 'graphql-tag';

export default gql`
mutation CancelPurchaseOrder(
				$poId: Int!,
				$cancelReason: String!


	) {
		cancelPurchaseOrder(
			poId:$poId,
			cancelReason:$cancelReason
		) {
		    id
		    cancelled
		    poDate
		    poNumber
		    supplier {
		      id
		      supplierName
		    }
		    items {
		      id
		      partName
		      partNumber
		      vendor
		      quantityOrdered
		      quantityReceived
		      dateReceived
		    }
		}
	}

`;

