import React, {useState} from 'react';

import {Form, FormGroup, Input, Button ,Alert, Spinner, Row, Col} from 'reactstrap';

import {useMutation} from '@apollo/client';
import AddLocation from '../../graphql/mutations/AddLocation';
import Locations from '../../graphql/queries/Locations';

export default function LocationForm() {
	const [locationName,setLocationName] = useState();
	const [errorMessage,setErrorMessage] = useState();

	const [addLocation,{loading}] = useMutation(AddLocation,{refetchQueries:[{query:Locations}]});

	const save = () => {
		setErrorMessage(null);
		if (!locationName || locationName.trim().length === 0) {
			setErrorMessage("Please enter a location")
			return;
		}

		addLocation({variables:{locationName}})
			.then(result => setLocationName(""))
			.catch(error => setErrorMessage(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the location. Please try again.`))
	}

	return (
		<Form onSubmit={e => e.preventDefault()} >
			{errorMessage && <Alert>{errorMessage}</Alert>}
			<Row>
				<Col md={9}>
					<FormGroup className="mb-0" >                          
						<Input placeholder="Location Name" value={locationName || ""} type="text" onChange={(e) => setLocationName(e.target.value)} />
					</FormGroup>
				</Col>
				<Col md={3}>
					<Button block color="primary" onClick={() => save()} >
						Add Location
						{loading && <Spinner size="sm" className="ml-2" />}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}