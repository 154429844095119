import React from 'react';

import {Card, CardHeader,CardBody, CardTitle} from 'reactstrap';
import WorkOrdersTable from '../WorkOrders/WorkOrdersTable';



export default function ReportedWorkOrders({id,cardHeight}) {
	//const {data,loading} = useQuery(WorkOrders,{variables:{assignedTo:[id]}})

	//* Display Work Orders Reported By or Assigned to*/}
	return (
		<Card className="shadow mb-4" style={cardHeight ? {height:cardHeight} : {}}>		
        	<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Reported Work Orders</CardTitle></CardHeader>
			<CardBody className="pt-0" style={{overflowY:"auto"}}>
	     		<WorkOrdersTable filterFields={{reportedBy:[id]}} />
	        </CardBody>
	      </Card>
	);
}

