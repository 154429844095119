import React, {useState,useEffect} from 'react';
import { Link } from 'react-router-dom';
import { throttle } from 'throttle-debounce';
import {Container,Card,CardHeader,CardBody,CardTitle, Row, Col, Form, Button} from 'reactstrap';
import AnimateHeight from 'react-animate-height';
import SearchInput from '../components/WorkOrders/SearchInput';

import InventoryLogTable from '../components/Inventory/InventoryLogTable';
import SelectCategory from '../components/Inventory/SelectCategory';
import Pagination from "../components/Inventory/InventoryLogPagination.js";



export default function InventoryLogScreen() {
	const limit = 25;
  	// set card height for bottom cards to window minus 60 (padding at top)  minus 50 (page title) minus 34 (gab between rows) minus 6 just for spacing
	const [filterFields,setFilterFields] = useState({});
	const [offset,setOffset] = useState(0);


	const searchKeyword = throttle(1000,false,(keyword) => {
		setOffset(0);
		setFilterFields({...filterFields,keyword});
	})




	return (
		<Container fluid>
			<h2>Inventory Log</h2>
			<Row>
				<Col md={12}>
					<Card className="shadow mb-4" >
		        		<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
								<Pagination 
									offset={offset} 
									limit={limit}
									filterFields={filterFields}
									onPrev={() => offset > 0 ? setOffset(offset-limit) : null}
									onNext={() => setOffset(offset+limit)}
								/>		        			
								{/*<div className="form-inline">
		        				<CardTitle className="m-0">Inventory</CardTitle>

		        				<SearchInput placeholder="Search Inventory" searchKeyword={searchKeyword} />
		        				<SelectCategory filterFields={filterFields} setFilterFields={setFilterFields} />
		        			</div>*/}
		        		</CardHeader>
		        		<CardBody className="pt-0 pb-0 h-100">
		        			<InventoryLogTable offset={offset} limit={limit} filterFields={filterFields} />
		        		</CardBody>

					</Card>

				</Col>
			</Row>

		</Container>
	);
}