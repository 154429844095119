import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';

export default gql`
mutation RemoveWorkOrderPart(
  $workOrderId:ID!
  $inventoryItemId:Int!
  ) {
    removeWorkOrderPart(
      workOrderId:$workOrderId,
      inventoryItemId:$inventoryItemId
    ) {
      ...WorkOrderAllFields
    }
}
${WORK_ORDER_FRAGMENT}
`;