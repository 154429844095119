import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";


//import {v4 as uuid} from 'uuid';
import { Buffer } from 'buffer';
import axios from 'axios';

import PhotoList from '../components/PhotoList';
import AuthSection from '../components/AuthSection';
import ServicePicker from '../components/WorkOrders/ServicePicker';

import { useQuery, useMutation } from '@apollo/client';
import Trucks from '../graphql/queries/Trucks';
import MostUsedTruck from '../graphql/queries/MostUsedTruck';
import AddWorkOrder from '../graphql/mutations/AddWorkOrder';
import WorkOrderStats from '../graphql/queries/WorkOrderStats';
import WorkOrders from '../graphql/queries/WorkOrders';

import Employees from '../graphql/queries/Employees';
import MyInfo from '../graphql/queries/MyInfo';


// reactstrap components
import {
  Spinner,
  Input,
  Card,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  FormGroup,
  Label,
  Form,

  Modal,
  ModalHeader,
  //ModalFooter,
  ModalBody
} from "reactstrap";



function NewWorkOrderScreen({match:{params:{id}}}) {
	const history = useHistory();

	const truckId = id; // leftover from copying from mobile app
	const {loading,data:trucksData} = useQuery(Trucks)
	const [addWorkOrder,{loading:loadingAddWorkOrder}] = useMutation(AddWorkOrder, {
		refetchQueries: [{query:WorkOrderStats},{query:WorkOrders}],
		onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the work order . Please try again.`)
	})


	const [workOrderType,setWorkOrderType] = useState("General");

	// filtering for truck picker (future option)
  	const [truckFilter] = useState("");
  	//const [photosUploading,setPhotosUploading] = useState(); // if this has a value, then photos are loading
 		const [fileModalOpen,setFileModalOpen] = useState(false); // if this has a value, then photos are loading

	// get most reported truck
	const {data:dataMostUsed} = useQuery(MostUsedTruck);
	// get employees for admins to set reported by
	const {data:dataEmployees,loading:loadingEmployees} = useQuery(Employees);
	// use to get my id...test caching but may want to move to context
	const {data:dataMyInfo} = useQuery(MyInfo);

	const [servicePlanId,setServicePlanId] = useState();


  	// form values
  	const [formFieldValues,setFormFieldValues] = useState({outOfService:false});


	// default reported by to current user
	useEffect(() => {
		if (!loadingEmployees && !formFieldValues.formReportedBy && dataEmployees && dataMyInfo) {
			setFormFieldValues({...formFieldValues,reportedBy:dataMyInfo.me.id})
		}
	},[dataEmployees])

	// set default truck
	useEffect(() => {
		setFormFieldValues({...formFieldValues,photos:[],outOfService:false})
		if (truckId) {
			setFormFieldValues({...formFieldValues,truck:truckId});
		}
	},[])

const  readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;
		reader.readAsDataURL(file);
    //reader.readAsArrayBuffer(file);
  })
}

		const putFileOnAWS = async (data,index) => {
			const {url} = data;

			//setPhotosUploading(`Uploading ${index+1} of ${formFieldValues.photos.length}`);
			// get file as base64
			const theFile = await readFileAsync(formFieldValues.photos[index])
				const buffer = Buffer.from(theFile.replace(/^data:image\/\w+;base64,/, ""),'base64');

			await axios.put(
				url,
				buffer,
				{
					headers :{
						"Content-Type": "image/jpeg"
					}

				}
			)



		}





  	const uploadPhotos = async ({data:{addWorkOrder}}) => {
				
				// if there are photos, then they need to be uploaded to S3
				if (addWorkOrder.photos && addWorkOrder.photos.length > 0) {
					// let user know that work order created, and now uploading photos
					setFileModalOpen(true)
					//const {photos} = addWorkOrder;
					//setPhotosUploading(`Uploading photos ... `);
					// loop over photos and upload
					
					await addWorkOrder.photos.forEach(putFileOnAWS)
					
					

				}
			setFileModalOpen(false)
			return addWorkOrder

  	}

  	// save function. putting it here so view can take advantage of loading var of mutation
	const saveWorkOrder = async () => {

		// check for required fields
		let errors = [];
		if (!formFieldValues?.truckId) {
			errors.push("Please select an asset")
		}

		if (workOrderType === 'Service') {
			if (!formFieldValues?.service) {
				errors.push("Please select a service")
			}
		} else {
			if (!formFieldValues?.description) {
				errors.push("Please enter details")
			}
		}

		if (errors.length > 0) {
			alert(errors.join('\n'))
			return;
		}

		// DEBUGGING CODE: console.log(formFieldValues);return;
		// set up photos to send to server if they exist
		/*const photos = formFieldValues.photos.map(({height,width}) => ({
			filename: `${uuid()}.jpg`,
			contenttype:'image/jpeg',
			height,
			width,
		}))*/
		// set variables based on work order type
		const variables = workOrderType === 'Service' ?
				  {
						truck:formFieldValues.truckId,
						servicePlan:servicePlanId,
						serviceType: formFieldValues.service,
						description: 'Scheduled Service'


					}
					:
				    {
						truck:formFieldValues.truckId,
						description:formFieldValues.description,
						outOfService:formFieldValues.outOfService,
						photos:formFieldValues.photos.map(file => ({
            	filename: file.name,
            	type: file.type,
            	contenttype: file.type,
            	height: null,
            	width: null

						})),
						reportedBy:formFieldValues.reportedBy



					}

/*
      // return file info for savings
      const returnObject = newFileArray.map(file => ({
      			...file,
            	filename: file.name,
            	type: file.type,
            	contenttype: file.type,
            	height: null,
            	width: null
            	
            }))
*/

		// save work order.
		addWorkOrder({
			variables,
			refetchQueries: [{query:WorkOrderStats}]
		})
			.then(uploadPhotos)
			.then((workOrder) => {

				// if driver, let them know work order was saved
				if (!dataMyInfo.me.roles || dataMyInfo.me.roles.length === 0 || dataMyInfo.me.roles[0] === "Driver") {
				
					alert(`Work Order Sent - Ticket #${workOrder.ticketNumber}`)
				}
				
				history.goBack();
			})
			.catch(err => console.log("err",err))

		

		
	} 

	if (loading ) {
		return <Spinner />
	}

	/*if (photosUploading && photosUploading.length > 0 ) {
		return (
				<div style={{justifyContent:'center',alignItems:'center'}}>
					<Spinner />
					<span>{photosUploading}</span>
				</div>
			)
	}*/


	const trucksDataDisplay = trucksData.trucks.filter(({identifier}) => !identifier || identifier.match(new RegExp(truckFilter,'ig')))
	// if most used truck exists, prepend array unless filtering. otherwise it will show twice
	if (!truckFilter && dataMostUsed && dataMostUsed.mostUsedTruck) {
		trucksDataDisplay.unshift(dataMostUsed.mostUsedTruck)
	} 

	// NOTE: Made some style accomodations for android. Should create a override header component to handle
	return (


        <Container className="mt--8" fluid>
        <h2>New Work Order</h2>
		 <Row>
		 <Col className="mb-5 mb-xl-0" xl="12">
		 <Card className="p-2">
		    <ButtonGroup className="w-100 mb-2">
		      <Button className="w-50" color="primary" outline={workOrderType !== "General"} onClick={() => setWorkOrderType("General")}>General</Button>
		      <Button className="w-50" color="primary" outline={workOrderType !== "Service"} onClick={() => setWorkOrderType("Service")}>Scheduled Sevice</Button>
		    </ButtonGroup>
 

	          <Form>

	            <FormGroup row>
	          		<Label sm={2}>Asset</Label>
	          		<Col sm={10}>
				          <Input type="select" name="truckId" onChange={e => {
				          	setFormFieldValues({...formFieldValues,truckId:e.target.value})
				          	// get service plan id for truck
				          	const selectedTruck = trucksDataDisplay.find(el => el.id === e.target.value)
				          	setServicePlanId(selectedTruck.servicePlan ? selectedTruck.servicePlan.id : null)
				          }}>
			              	<option>Select Asset...</option>
			              	{trucksDataDisplay.map(({id,identifier,type},index) => 
			              		<option key={index} value={id}>{`${type} ${identifier}`} </option>
			              	)}
				          </Input>
	              </Col>
	            </FormGroup>
	            {workOrderType === 'Service' &&
		            <FormGroup row>
		            	<Label sm={2}>Service</Label>
		            	<Col sm={10}>
		            		<ServicePicker servicePlanId={servicePlanId} selectedValue={formFieldValues.service} onValueChange={val => setFormFieldValues({...formFieldValues,service:val})} />
		            	</Col>
		            </FormGroup>
	        	}

	        	{workOrderType === 'General' &&
	        		<div>
						<FormGroup row>
						  <Label sm={2}>Details</Label>
						  <Col sm={10}>
						  	<Input type="textarea" onChange={(e) => {
						  		
						  		setFormFieldValues({...formFieldValues,description:e.target.value})
						  	}} />
						  </Col>
						</FormGroup>

						<FormGroup  row>
					        <Label sm={2}>Out of Service</Label>
					        <Col sm={10}>
							    <ButtonGroup >
							      <Button color="primary" outline={formFieldValues.outOfService} onClick={() => setFormFieldValues({...formFieldValues,outOfService:false})}>No</Button>
							      <Button color="danger" outline={!formFieldValues.outOfService} onClick={() => setFormFieldValues({...formFieldValues,outOfService:true})}>Yes</Button>
							    </ButtonGroup>

					        </Col>
					     </FormGroup>



						{!loadingEmployees && dataEmployees.employees &&
								
							<AuthSection>
					            <FormGroup row>
					          		<Label sm={2}>Reported By</Label>
					          		<Col sm={10}>
								          <Input type="select" onChange={e => setFormFieldValues({...formFieldValues,reportedBy:e.target.value})}>
							              	<option>- Me -</option>
							              	{dataEmployees.employees.map(({id,firstName,lastName}) => 
							              		<option key={id} value={id}>{`${firstName} ${lastName}`}</option>
							              	)}
								          </Input>
					              </Col>
					            </FormGroup>
							</AuthSection>
						}
						

					</div>
				}


	          </Form>
	          </Card>

	          {workOrderType === 'General' &&
	          	<Card className="mt-2 mb-2 p-2">
	          		<h3>Add Photos</h3>
							 	<PhotoList onChange={photos => setFormFieldValues({...formFieldValues,photos})} />
	          	</Card>
	        	}

	          <Card className="p-2">
						<div className="text-center">
	        		<Button 
	        				disabled={workOrderType === 'Service' && !formFieldValues.service ? true : false}
	        				onClick={() => saveWorkOrder({files:formFieldValues.photos})}
	        			>
	        			Add Work Order 
	        			{loadingAddWorkOrder && <Spinner size="sm" />}
	        		</Button>
	        	</div>
	          </Card>

	          </Col>
	        </Row>
				  <Modal
				    isOpen={fileModalOpen}
				    backdrop={"static"}
				  >
				    <ModalHeader toggle={function noRefCheck(){}}>
				      Work Order Added
				    </ModalHeader>
				    <ModalBody>
				      <Spinner className="mr-2" size="sm" /> Uploading Photos (1 of 1) 
				      
				    </ModalBody>

				  </Modal>

        </Container>

      


	);
}



export default NewWorkOrderScreen;