import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';
 

export default gql`
mutation RemovePermitFromAsset($permit: ID!,$assignedTo: ID!) {
  removePermitFromAsset(permit:$permit,assignedTo:$assignedTo) {
    ...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;


