import gql from 'graphql-tag';

export default gql`
query ServicePlanStatuses($criticalOnly: Boolean) {
  servicePlanStatuses(criticalOnly:$criticalOnly) {
    truck {
      id
      identifier
      type
      lastReportedMileage
    }
    items {
				planName
				servicePlan
				service
				trackingMeasurement
				units
				lastServiceMileage
				lastServiceDate
        serviceSchedule {
          id
          service
          trackingMeasurement
          units
        }

    }

  }
}
`;