import gql from 'graphql-tag';


export default gql`
{
  mostUsedTruck {
    id
    identifier
    type
    lastReportedMileage
    OpenWorkOrders
  	OutOfService
  	servicePlan {
  		id
  	}
  }
}

`;

