import React, {useState, useEffect, useContext} from 'react';

import {  Alert, Spinner, Form, FormGroup, Input, Label, Row, Col, Button  } from 'reactstrap';

import {Link} from 'react-router-dom';

import { useMutation } from '@apollo/client';
import LoginMutation from '../../graphql/mutations/LoginMutation';

import AuthContext from '../../contexts/AuthContext';
import Logo from "../../assets/images/Logo.png";

import { Helmet } from 'react-helmet';

export default function Home() {

	const [email,setEmail] = useState();
	const [password,setPassword] = useState();


	const [errorMessage,setErrorMessage] = useState();

	// after login, trigger loading my info which in turn will set loggedin to true. its an extra call, but want fields loaded in one place
	const { /*reloadMyInfo*/ setFullName,setRoles,setIsLoggedIn, setTrialInfo, setFeatures,setServices } = useContext(AuthContext);

	const [login,{loading}] = useMutation(LoginMutation, {
	onCompleted:  result => {
	  localStorage.setItem("Authorization",result.login.token);
	  // refetching in context won't work due to apollo oncomplete not firing, so set stuff from here
			setFullName({firstName:result.login.firstName, lastName:result.login.lastName})
			setRoles(result.login.roles)
			setTrialInfo({
				accountType:result.login.accountType,
				planExpiration:result.login.planExpiration
			})

			setIsLoggedIn(true)
			setFeatures(result.login.features)
			setServices(result.login.services || [])

	},
	onError: error => {
	        console.log("error",error)
	        // loading toast blocks header from being clicked
	        const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error logging in. Please try again.'
	        setErrorMessage(message);
	}
	})


	useEffect(() => {
		// when leaving page, reset variables
		return () => {
			setEmail(null);
			setPassword(null);
		}
	},[])

	return (
			<Row>
				<Helmet>
					<title>Login | Fleet Command</title>
				</Helmet>
				<Col md={{size: 7, offset:2}} className="text-center p-2 pt-4 mt-5" style={{background: "#ffffff"}}>
			      <div className="text-center mb-0 mt-5">
			        <img className="mb-2" src={Logo} alt="Fleet Command - Dispatch" style={{width:250,height: 145}}/>
			      </div>   
   					<Form className="text-left">
						{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
						<FormGroup>
							<Label>Email</Label>
							<Input type="text" onChange={e => setEmail(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>Password</Label>
							<Input type="password" onChange={e => setPassword(e.target.value)} />
							<Link to="/resetPassword" className="float-right mb-4">Forgot Password?</Link>
						</FormGroup>
						<Button block color="info" onClick={() => login({variables:{email,password}})}>
							Login
							{loading && <Spinner />}
						</Button>
					</Form>
					<p>
						Not a customer?<br /><Link to="/signup">Sign up for a free trial</Link>
					</p>
				</Col>
			</Row>

	);
}