import React, { useContext } from 'react';

import { Link } from 'react-router-dom';

import {

  Container,
  Row,
  Col,

Card, CardBody,CardTitle,CardText
}
 from "reactstrap";

import AuthContext from "../contexts/AuthContext";


export default function AccountSettings() {

	const {features} = useContext(AuthContext);

	return (

        <Container className="mt--8" fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
            	<Card>
			        <CardBody>
			          <CardTitle className="mb-0"><Link to="/AccountSettings/AssetCustomFields">Asset Custom Fields</Link></CardTitle>
			          <CardText>Custom fields allow you to track additional information on assets. Clicking on an asset name in a work order will display the additional information from custom fields. </CardText>
			        </CardBody>
			        <CardBody>
			          <CardTitle className="mb-0"><Link to="/AccountSettings/Locations">Locations</Link></CardTitle>
			          <CardText>
			          		Do your assets live at multiple places? You can tag assets by location and then filter work orders by location.
			          		{
			          			(!features || features.indexOf("locationTracking") === -1) && 
			          				<div style={{display:"block"}}>
			          					(<em>Your account doesn't have locations turned on. <Link to="/admin/Support">Contact support</Link> to activate</em>)
			          				</div>
			          		}
			          </CardText>
			        </CardBody>
			        <CardBody>
			          <CardTitle className="mb-0"><Link to="/AccountSettings/EmailNotifications">Email Notication</Link></CardTitle>
			          <CardText>Select which users should get daily emails</CardText>
			        </CardBody>
			        <CardBody>
			          <CardTitle className="mb-0"><Link to="/AccountSettings/AssetTypes">Asset Types</Link></CardTitle>
			          <CardText>By default, types are Truck, Trailer and Other. Add additional asset types here.</CardText>
			        </CardBody>
            	</Card>

            </Col>
          </Row>
        </Container>


	)
}