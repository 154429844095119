import gql from 'graphql-tag';

import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
mutation RemoveTruckPhoto($id:ID!,$photoId:ID!) {
	removeTruckPhoto(id:$id,photoId:$photoId) {
		...TruckAllFields
	}
}
${TRUCK_FRAGMENT}
`;


