import React from 'react';

// reactstrap components
import {


  Card,
  CardHeader,
  CardBody,

  Row,
  Col,

  Table,


} from "reactstrap";



export default function UdfsCard({udfs}) {

  if (!udfs || udfs.length === 0) return null;

	return (
          <Card className="mb-4">
           <CardHeader>
              <strong>Additional Info</strong></CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Table>
                    <tbody>
                    	{udfs.map(({fieldName,fieldValue}) =>
	                        <tr key={fieldName}>
	                          <th>{fieldName}</th>
	                          <td>{fieldValue}</td>
	                        </tr>
                    	)}
                 
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

	);
}