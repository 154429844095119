import React from 'react';

import {Card, CardHeader, CardTitle, CardBody, Alert} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

//import { useQuery } from '@apollo/client';
//import WorkOrders from '../../graphql/queries/WorkOrders'

export default function WorkOrdersTable({cardHeight,data,loading,error}) {
	//const {data,loading,error} = useQuery(WorkOrders,{variables: {limit: 20}});

	if (loading) return null;
	if (error) return <Alert danger>Error loading work orders</Alert>


  return (
        <Card  className="shadow" style={cardHeight ? {height:cardHeight,minHeight: 150} : {}}>
          <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Recent Activity</CardTitle></CardHeader>
 
          <CardBody style={{overflowY:"auto"}}>
            {data.workOrders && data.workOrders.length === 0 && <p>There hasn't been any activity yet. <Link to="/WorkOrder/New" ><u>Add a work order</u></Link></p>}
            {/* jon doe reported a new issue for truck 123/new line then description  */}
            {/* work order activity: assigned to/new line with Assigned to Marc Lichtenfeld  */}
            {data.workOrders && data.workOrders.map(({
                      id,
                      ticketNumber,
                      truck:{type,identifier,id:truckId},
                      status,
                      createdAt,
                      description,
                      outOfService,
                      reportedBy
                    }) =>
              <div key={id} className="row" style={{borderTop: "1px solid #cccccc",padding: 10,margin: 10}}>
                <div className="col-md-8">
                  {outOfService && status !== 'Work Complete' && <span className="badge badge-danger">Out of Service</span>}
                  <div>
                    <p className="mb-0">Ticket # <Link to={`/WorkOrder/${id}`}>{ticketNumber}</Link> for {type} <Link to={`/Equipment/${truckId}`}>{ticketNumber}</Link></p>
                    {reportedBy && <p className="text-muted mb-0"><em>Reported by {reportedBy.firstName} {reportedBy.lastName}</em></p>}
                    <p>{description}</p>
                  </div>

                </div>
                <div className="col-md-4 text-info text-right">
                  {moment(createdAt).fromNow()} <br />
                  <strong>{status}</strong>

                </div>
              </div>

              
            )}
          </CardBody>
            </Card>
  );


}