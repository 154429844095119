import gql from 'graphql-tag';

export default gql`
mutation AddWorkOrder(
		$truck: String!,
		$title: String,
		$description: String!,
		$outOfService: Boolean,
  	$photos: [FileTypeInput],
    $reportedBy: ID,
    $servicePlan: ID,
    $serviceType: ID
	) {
  addWorkOrder(
  	truck:$truck,
    title:$title,
    description:$description,
    outOfService:$outOfService,
    photos:$photos,
    reportedBy:$reportedBy,
    servicePlan:$servicePlan,
    serviceType:$serviceType
  ) {
      id
      truck {
        id
        identifier
        type
      }
      title
      description
      reportedBy {
        firstName
        lastName
      }
      status
      priority
      ticketNumber
      outOfService
      createdAt
      assignedTo {
        id
        firstName
        lastName
      }

      photos {
        filename,
        height,
        width,
        contenttype,
        url
      }


  }
}
`;