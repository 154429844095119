import React, {useState} from "react";
// reactstrap components
import {
  Alert,
  ListGroup,
  ListGroupItem,
  Card,
  CardHeader,
  CardBody,

  Modal
} from "reactstrap"; 

import {useMutation,useQuery} from '@apollo/client';
import Mechanics from '../../graphql/queries/Mechanics';
import AssignTo from '../../graphql/mutations/AssignTo';



export default function AssignModal({id,modalVisible,setModalVisible}) {
  const [errorMessage,setErrorMessage] = useState();


  const {data:dataMechanics} = useQuery(Mechanics);



  const [assignTo] = useMutation(AssignTo,{
    onError: error => {
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error updating status. Please try again.'
            setErrorMessage(message);
    }

  });

  const setAssignedTo = (assignedTo) => {

    assignTo({
      variables: {
        id,
        assignedTo
      }
    })
    .then(result => setModalVisible(false))
  }





  return (
    <div>
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={modalVisible}
              toggle={() => setModalVisible(false)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-2">
                    {errorMessage && 
                      <Alert color="danger">
                        {errorMessage}
                      </Alert>
                    }

                    <h2>Assign To...</h2>
                  </CardHeader>
                  <CardBody >
                      <ListGroup>
                        {dataMechanics && dataMechanics.mechanics.map(({id,firstName,lastName}) => 
                          <ListGroupItem key={id} 
                                         style={{cursor:"pointer"}}
                                         onClick={() => setAssignedTo(id)}
                                         className="justify-content-between">{firstName} {lastName}</ListGroupItem>
                        )}
                      </ListGroup>
                  </CardBody>
                </Card>
              </div>
            </Modal>

    </div>
  )
}
