import gql from 'graphql-tag';

export default gql`
query MySupportInfo {
	mySupportInfo {
	id
    firstName
    lastName
    businessName
    phones {
    	type
    	number
    }
    email

  }
}
`;