import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert,Card, CardBody, Form, FormGroup, Label, Col, Input,ListGroup,ListGroupItem} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import DateTime from 'react-datetime';
import moment from 'moment';

import { useMutation,useQuery } from '@apollo/client';
import UpdatePurchaseOrder from '../../graphql/mutations/UpdatePurchaseOrder';
import PurchaseOrders from '../../graphql/queries/PurchaseOrders';
import Suppliers from "../../graphql/queries/Suppliers";
import AddSupplierModal from './AddSupplierModal';


export default function UpdatePurchaseOrderModal({item,modalVisible,setModalVisible}) {



	const [formFieldValues,setFormFieldValues] = useState({});

	  useEffect(() => {
	  	setFormFieldValues(item);
	  },[item])
	  useEffect(() => {
	  	if (!modalVisible) {
	  		setFormFieldValues({});
	  		setErrors([])
	  	}
	  },[modalVisible])

	const [errors,setErrors] = useState([]);
   const [supplierName,setSupplierName] = useState()
	const {data:dataSuppliers} = useQuery(Suppliers);

	
	const [modalVisible_supplier,setModalVisible_supplier] = useState(false)




	const setValue = ({name,value}) => {
		setFormFieldValues({...formFieldValues,[name]:value});
	}



  const [updatePurchaseOrder,{loading}] = useMutation(UpdatePurchaseOrder,{
  	refetchQueries: [{query:PurchaseOrders,variables:{offset:0,limit:20}}]
  })

  const save = (vars) => {

setErrors([])
  	if (!vars?.poDate) {
  		setErrors(['Please enter a purchase order date'])
  		return;
  	}
  	const variables = {
      poId:parseInt(item.id),
  		poDate:vars.poDate,
  		poNumber:vars.poNumber || null,
  		supplier: vars.supplier.value || null  		
  	}
  	console.log("item",item)
  	console.log("vars",vars)
 	console.log("variables",variables)
  	updatePurchaseOrder({variables})
      .then(result => {
        // only close if valid result returned
        if (result) {
          setModalVisible(false);
        }
        
       })
     .catch(err => alert("There was an error updating the purchase order. Please try again")) 

  }


    const handleSupplierChange = (newValue, actionMeta) => {
    	

      // if actionMeta.action === create-option, then show add customer modal.
      if (actionMeta.action === 'create-option') {
      	//setValue({name:'supplier',value:null})
      	
      	setSupplierName(newValue.label);
        setModalVisible_supplier(true);
      }
      if (actionMeta.action === 'clear') {
      	setValue({name:'supplier',value:null})
      }

      if (actionMeta.action === 'select-option') {
        setValue({name:'supplier',value:{label:newValue.label,value:newValue.value}})

      }

    };


console.log("formFieldValues",formFieldValues)
  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}   keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add Inventory</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
        	<h2>New Purchase Order</h2>
            <Card className="shadow mb-4">
            	<CardBody>
					<FormGroup row>
						<Label sm={3}>PO Date</Label>
						<Col sm={9}>
							<DateTime timeFormat={false} value={moment(formFieldValues.poDate)} onChange={date => setValue({name:"poDate",value:date})} />
							{/*<Input type="date" value={formFieldValues.poDate} onChange={e => setValue({name:"poDate",value:e.target.value})} />*/}
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={3}>PO #</Label>
						<Col sm={9}>
							<Input type="text" value={formFieldValues.poNumber} onChange={e => setValue({name:"poNumber",value:e.target.value})} />
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={3}>Supplier</Label>
						<Col sm={9}>
			                 {/*<Select
							        defaultValue={formFieldValues.supplier}
							        
							        placeholder="Select Supplier...."
							        onChange={value => setValue({name:'supplier',value:{supplierName:value.label,id:value.value}})}
							        options={dataSuppliers?.suppliers?.map(({supplierName,id}) => ({label:supplierName,value:id}))}
							      />*/}
					        <CreatableSelect
					          isClearable
					          value={formFieldValues?.supplier}
					          defaultValue={formFieldValues.supplier}
					          onChange={handleSupplierChange}
					          //onInputChange={handleInputChange}
					          options={dataSuppliers?.suppliers?.map(({supplierName,id}) => ({label:supplierName,value:id}))}
					           
					           placeholder="Select Supplier...."
					           formatCreateLabel={value => `Add new supplier "${value}"`}
					        />

						</Col>
					</FormGroup>

				</CardBody>
			</Card>


        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save(formFieldValues)}>
            Update PO
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
 
	    	<AddSupplierModal 
	    		supplierName={supplierName}
	    		modalVisible={modalVisible_supplier}
	    		setModalVisible={setModalVisible_supplier}
	    		onSupplierAdded={({supplierName,id}) =>  setValue({name:'supplier',value:{label:supplierName,value:id}})}
	    	/>
      </Modal>

  );

}
