import React from 'react';

// reactstrap components
import {


  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,

  Table,


} from "reactstrap";

import moment from 'moment';
import {useQuery} from '@apollo/client';
import ServicePlanHistory from '../../graphql/queries/ServicePlanHistory';


export default function ServiceHistoryCard({id,lastReportedMileage}) {

	const {data} = useQuery(ServicePlanHistory,{variables:{id}})

	if (!data || data.servicePlanHistory.length === 0) return null;

  const renderProgressToService = ({lastReportedMileage,lastServiceMileage,lastServiceDate,units,trackingMeasurement}) => {
    // get percentage to service (diff between then and now divided by units)
    //const {serviceSchedule:{trackingMeasurement,units},lastServiceMileage,lastServiceDate} = service;

    let percentage = 0;
      // if this hours/miles or days/weeks
      if (trackingMeasurement === 'miles' || trackingMeasurement === 'hours') {
        percentage =  (lastReportedMileage-lastServiceMileage)/units
        
      }
      else {
        percentage = moment().diff(lastServiceDate,'days')/(trackingMeasurement === 'days' ? units : units*7)
      }

      percentage = Math.ceil(percentage*100);


    let progressBarClass = "progress-bar"
    if (percentage >= 100) {
      progressBarClass += " bg-danger progress-bar-striped"
    }
    else if (percentage >= 80) {
      progressBarClass += " bg-warning"
    }

    return (
      <div className="progress" style={{height: 20}}>
          <div className={progressBarClass}
          role="progressbar" 
          style={{width: `${percentage}%`}} 
          aria-valuenow={percentage}
          aria-valuemin="0" 
          aria-valuemax="100">
          {percentage.toLocaleString()}%
        </div>
      </div>

    );

  }


	return (
          <Card className="shadow mb-4">
          <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Service History</CardTitle></CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Table>
                  	<thead>
                  		<tr>
                  			<th>Service</th>
                  			<th>Last Service Date</th>
                  			<th>Odometer</th>
                        <th>Time to Service</th>
                  		</tr>
                  	</thead>
                    <tbody>
                    	{data.servicePlanHistory.map(({lastServiceDate,units,description,trackingMeasurement,measurementUnits,planActive},index) =>
	                        <tr key={index}>
	                          <td><strong>{description}</strong></td>
	                          <td>
                              {moment(lastServiceDate).format("M/D/YYYY")}
                              
                            </td>
	                          <td>{units ? units.toLocaleString() : ""}</td>
                            <td>
                              {(lastServiceDate && planActive) ? renderProgressToService({lastReportedMileage,lastServiceMileage:units,lastServiceDate,units:measurementUnits,trackingMeasurement}) : ''}
                              {!planActive && <em>(service plan changed)</em>}
                            </td>
	                        </tr>
                    	)}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

	);
}