import gql from 'graphql-tag';

export default gql`
query PurchaseOrders($poNumber: String,$offset: Int, $limit: Int){
  purchaseOrders(poNumber:$poNumber,offset:$offset,limit:$limit) {
    id
    cancelled
    poDate
    poNumber
    supplier {
      id
      supplierName
    }
    itemsOrdered
    quantityOrdered
    quantityReceived
  }
}
`;