import React, { useState } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner,Card, CardBody, FormGroup, Label, Col, Input} from 'reactstrap';

import { useMutation } from '@apollo/client';
import CancelPurchaseOrder from '../../graphql/mutations/CancelPurchaseOrder';
import PurchaseOrders from '../../graphql/queries/PurchaseOrders';


export default function CancelPurchaseOrderModal({poId,modalVisible,setModalVisible}) {

	const [note,setNote] = useState();


  const [cancelPurchaseOrder,{loading}] = useMutation(CancelPurchaseOrder,{
  	refetchQueries: [{query:PurchaseOrders,variables:{offset:0,limit:20}}]
  })

  const save = (vars) => {
  	if (!note || note.trim().length === 0) {
  		alert("Please enter a reason for cancelling this purchase order")
  		return;
  	}
   	cancelPurchaseOrder({variables:{poId:parseInt(poId),cancelReason:note}})
      .then(result => {
        // only close if valid result returned
        if (result) {
          setModalVisible(false);
        }
        
       })
     .catch(err => alert("There was an error updating the purchase order. Please try again")) 

  }


  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}   keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add Inventory</ModalHeader>
        <ModalBody>
        	
        	<h2>Cancel Purchase Order</h2>
            <Card className="shadow mb-4">
            	<CardBody>


					<FormGroup>
						<Label>Cancel Reason</Label>
						<Col sm={10}>
							<Input type="text" value={note} onChange={e => setNote(e.target.value)} />
						</Col>
					</FormGroup>



				</CardBody>
			</Card>


        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Cancel Purchase Order
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Close</Button>
        </ModalFooter>
 

      </Modal>

  );

}
