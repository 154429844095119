import React from 'react';

// reactstrap components
import {


  Card,
  CardBody,

  Row,
  Col,
  Alert,
  Table,

  Button


} from "reactstrap";

import OdometerHistoryModal from './OdometerHistoryModal';

import moment from 'moment';

const getDueDateColor = (dueDate) => {

    if (!dueDate) return "";

    const dueInDays = moment().diff(dueDate,'days');

    // if more than 30 days, then nothing to return
    if (dueInDays < -30)
      return null;

    let color;
    if ( dueInDays < -15) color = "#e2e2e2";
    else if ( dueInDays < -1) color = "yellow";
    else color = "red";


   return color
}


export default function EquipmentDetailCard({asset}) {
    const [modalVisibleOdometerHistory,setModalVisibleOdometerHistory] = React.useState(false);

    const {
	    lastReportedMileage,
	    OutOfService,

	    make,
	    model,
	    year,
	    vin,
	    licensePlate,
	    engineSerialNumber,
	    transmissionSerialNumber,
	    tireSize,
	    federalAnnualInspectionDueDate,
      registrationDueDate,
      location,
      servicePlan,
      photos,
      lastReportedMileageHistory

    } = asset

//<img src={photos[0].url} style={{width: 100,height:"auto"}} alt="Asset Photo" />
	return (
      <Row>
        <Col md={6}>
          <Card className="shadow mb-4" style={{height:250}}>          
           {OutOfService && <Alert color="danger">Out of Service</Alert>}
            {/*<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">{type} {identifier}</CardTitle></CardHeader>*/}
            <CardBody>
               <Row>
                 <Col>
                  {photos?.length > 0 && photos[0].url &&  <div className="mr-2 mb-2 float-left" style={{background: `url(${photos[0].url}) no-repeat`,height: 80,backgroundSize:"contain",width:200}}></div>}
                  {year} {make} {model}
                  <p>
                    <strong>Location:</strong> {location ? location.locationName : <em>(unassigned)</em>}<br/>
                    <strong>Service Plan:</strong> {servicePlan ? servicePlan.planName : <em>(not set)</em>}
                  </p>
                  <Table className="table-flush w-100"  >
                    <tbody>
                     
                       
                        <tr>
                          <th>License</th>
                          <td>{licensePlate ? licensePlate : 'n/a'}</td>
                        </tr>
                     
                     
                        <tr>
                          <th>VIN</th>
                          <td>{vin ? vin : 'n/a'}</td>
                        </tr>
                     
                    
                        <tr>
                          <th>Last Odometer</th>
                          <td>
                            {lastReportedMileage !== null ? parseFloat(lastReportedMileage).toLocaleString() : 'n/a'}

                            {lastReportedMileageHistory?.length > 0 && <Button onClick={() => setModalVisibleOdometerHistory(true)} size="sm" color="link">(<em>Corrected</em>)</Button>}
                          </td>
                        </tr>
                     
                    
                    </tbody>
                  </Table>


                </Col>

              </Row>
            </CardBody>

          </Card>
        </Col>
        <Col md={6}>
           <Card className="shadow mb-4" style={{height:250}}>   

            <CardBody>
              <Row>
                <Col>
                  <Table>
                    <tbody>
                         <tr>
                          <th style={{borderTop: "0px",background:getDueDateColor(federalAnnualInspectionDueDate)}}>Federal Annual Due Date</th>
                          <td style={{borderTop: "0px",background:getDueDateColor(federalAnnualInspectionDueDate)}}>{federalAnnualInspectionDueDate ? moment(federalAnnualInspectionDueDate).format("M/D/YYYY") : "n/a"}</td>
                        </tr>

                         <tr>
                          <th style={{borderTop: "0px",background:getDueDateColor(registrationDueDate)}}>Registration Renewal Date</th>
                          <td style={{borderTop: "0px",background:getDueDateColor(registrationDueDate)}}>{registrationDueDate ? moment(registrationDueDate).format("M/D/YYYY") : "n/a"}</td>
                        </tr>


                        <tr>
                          <th>Engine Serial #</th>
                          <td>{engineSerialNumber ? engineSerialNumber : 'n/a'}</td>
                        </tr>
                     

                        <tr>
                          <th>Transmission Serial #</th>
                          <td>{transmissionSerialNumber ? transmissionSerialNumber : 'n/a'}</td>
                        </tr>
                     

                        <tr>
                          <th>Tire Size</th>
                          <td>{tireSize ? tireSize : 'n/a'}</td>
                        </tr>


                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>


        </Col>
          <OdometerHistoryModal modalVisible={modalVisibleOdometerHistory} setModalVisible={setModalVisibleOdometerHistory} history={lastReportedMileageHistory} />

      </Row>

	);
}