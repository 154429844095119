import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';


export default gql`
query WorkOrder($id:String!) {
  workOrder(id:$id) {
    ...WorkOrderAllFields
    
  }
}
${WORK_ORDER_FRAGMENT}
`;