import React, {useEffect,useState} from 'react';
import { Table, Alert, Spinner, Badge, Button} from 'reactstrap';
import { Link } from "react-router-dom";
import Icon from '@mdi/react'
import { mdiDownload} from '@mdi/js'


import { useQuery, useLazyQuery } from '@apollo/client';
import Equipment from '../../graphql/queries/Trucks'
import TruckPDF from '../../graphql/queries/TruckPDF';

import moment from 'moment';

const renderDueDateBadge = ({label,dueDate}) => {
    const dueInDays = moment().diff(dueDate,'days');

    // if more than 30 days, then nothing to return
    if (dueInDays < -30)
      return null;

    let color;
    if ( dueInDays < -15) color = "info";
    else if ( dueInDays < -1) color = "warning";
    else color = "danger";


   return <Badge style={{display:"block",marginBottom: 4}} color={color}>{label} due in {dueInDays} days</Badge>
}

export default function EquipmentTable({offset=0,limit=5,filterFields,keyword,cardHeight}) {
	const [currentId,setCurrentId] = useState();

  const {data,loading,error,refetch} = useQuery(Equipment,{
      variables: {
        ...filterFields,
        offset,
        limit
      }
    });
    const [getTruckPDF,{loading:pdfLoading}] = useLazyQuery(TruckPDF,{
      fetchPolicy:"network-only",
      onCompleted: (result) => window.open(result.truckPDF.url),
      onError: err => console.log("pdf error",err)
    });

  useEffect(() => {
    refetch()
  },[filterFields,refetch])

	if (loading) return <Spinner />;
	if (error) return <Alert danger>Error loading equipment</Alert>

  const filterKeyword = keyword && keyword.length > 0 ? keyword.toLowerCase() : null;
console.log("filterFields",filterFields)
  return (
        <div className="table-responsive" style={cardHeight ? {height:cardHeight} : {}}>
                <Table className="align-items-center table-flush"  >

      <thead>
        <tr>
            <th>Asset</th>
            <th>Last Odometer</th>
            <th>Open Work Orders</th>
            <th>Download Report</th>
        </tr>
      </thead>
      <tbody>
          {data.trucks
            .filter(el => filterKeyword ? 
                el.type.toLowerCase().indexOf(filterKeyword) > -1 
                ||
                el.identifier.toLowerCase().indexOf(filterKeyword) > -1 
                ||
                (el.make && el.make.toLowerCase().indexOf(filterKeyword) > -1)
                ||
                (el.model && el.model.toLowerCase().indexOf(filterKeyword) > -1)
                ||
                (el.year && el.year.toString().indexOf(filterKeyword) > -1)
              : true)
            .map(({
            id,
            identifier,
            type,
            lastReportedMileage,
            year,
            make,
            model,
            OpenWorkOrders,
            OutOfService,
            federalAnnualInspectionDueDate,
            registrationDueDate
          }) =>
            <tr key={id} style={OutOfService ? {backgroundColor:'red',color:"#ffffff"} : {}}>
              <th scope="row" style={{overflow: "hidden",maxWidth: 200}}>
                <Link to={`/Asset/${id}`}>{type} {identifier}</Link><br />
                {year} {make} {model}
                {/* alert badges - 30 days=alert 15 days=urgent 0 or more= red alert*/}
                {federalAnnualInspectionDueDate && renderDueDateBadge({label:"Fed Annual",dueDate:federalAnnualInspectionDueDate})}
                {registrationDueDate &&renderDueDateBadge({label:"Registration",dueDate:registrationDueDate})}
              </th>
              <td>{lastReportedMileage && !isNaN(lastReportedMileage) ? lastReportedMileage.toLocaleString() : "n/a"}</td>
              <td className="text-center">{OpenWorkOrders}</td>
              <td className="text-center">
                <Button onClick={() => {
                  setCurrentId(id)
                  getTruckPDF({variables:{id}})
                }}>

                <Icon path={mdiDownload} size={1} style={{display:"inline"}} className="mr-1" />
                Download Report
                {currentId === id && pdfLoading && <Spinner size="sm" className="ml-2" />}
                </Button>
               </td>
            </tr>
         )}

      </tbody>
    </Table>
    </div>
  );


}