import gql from 'graphql-tag';
import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';

export default gql`
mutation SetOutOfService($id: ID!,$outOfService: Boolean!) {
	setOutOfServiceStatus(id:$id,outOfService:$outOfService) {
		...WorkOrderAllFields


	}
}
${WORK_ORDER_FRAGMENT}
`;