import gql from 'graphql-tag';

export default gql`
mutation UpdatePartDetails(
  $id: Int!,
  $partName:String!
  $warningThreshold:Float,
  $location: String,
  $category: Int
) {
  updatePartDetails(
    id:$id,
    partName:$partName,
    warningThreshold:$warningThreshold,
    location: $location,
    category: $category
  ) {


    id
    partName
    category {
      id
      category
    }
    location,
    numberInStock
    warningThreshold
    inventoryItems {
      id
      partNumber
      location
      vendor
      numberInStock
      barcodeType
      barcodeData
      partDescription
      
    }



  }
}
`;