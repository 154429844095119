import React, {useState} from 'react';

import {Container, Card, CardBody, Spinner, Table, Button, Alert} from 'reactstrap';

import {useQuery} from '@apollo/client';
import PurchaseOrder from '../graphql/queries/PurchaseOrder';

import moment from 'moment';

import Icon from '@mdi/react'
import {mdiCheckCircleOutline} from '@mdi/js'

import ReceiveInventoryModal from '../components/Inventory/ReceiveInventoryModal'
import UpdatePurchaseOrderModal from '../components/Inventory/UpdatePurchaseOrderModal'
import CancelPurchaseOrderModal from '../components/Inventory/CancelPurchaseOrderModal'

export default function PurchaseOrderDetail({match:{params:{id}}}) {
	const [modalVisible_receive,setModalVisible_receive] = useState(false);
	const [modalVisible_po,setModalVisible_po] = useState(false);
	const [modalVisible_cancel,setModalVisible_cancel] = useState(false);
	const [currentItem,setCurrentItem] = useState();


	const {data,loading} = useQuery(PurchaseOrder,{variables:{id:parseInt(id)}});

	if (loading) return <Spinner />

	const {
		cancelled,
	    poDate,
	    poNumber,
	    supplier: {
	      supplierName
	    },
	    items 	
	} = data.purchaseOrder;

	const handleItemSelect = (item) => {
		// don't do anything if ordered === received
		if (item.quantityOrdered === item.quantityReceived) {
			return;
		}
		setCurrentItem({...item,poId:id});
		setModalVisible_receive(true);
	}

	return (
		<Container fluid>
			<h2>
				Purchase Order
				<Button color="link" onClick={() => setModalVisible_po(true)}>Edit</Button>
				<Button color="danger" onClick={() => setModalVisible_cancel(true)}>Cancel</Button>
			</h2>
			{cancelled && <Alert color="danger">Cancelled</Alert>}
			<Card className="shadow mb-4">

				<CardBody className="pt-0 pb-0">
					<Table className="mb-0">
						<tbody>
							<tr>
								<td className="text-muted pt-2 pb-2">PO Date</td>
								<td className="pt-2 pb-2">{moment(poDate).format('ll')}</td>
							</tr>
							<tr>
								<td className="text-muted pt-2 pb-2">PO Number</td>
								<td className="pt-2 pb-2">{poNumber}</td>
							</tr>
							<tr>
								<td className="text-muted pt-2 pb-2">Supplier</td>
								<td className="pt-2 pb-2">{supplierName}</td>
							</tr>


						</tbody>
					</Table>
				</CardBody>
			</Card>
			<Card className="shadow mb-4">

				<CardBody className="pt-0 pb-0">
					<Table className="mb-0">
						<thead>
							<tr>
								<th>Part</th>
								<th className="text-center">Ordered</th>
								<th className="text-center">Received</th>
								<th className="text-center">Update</th>
							</tr>
						</thead>
						<tbody>
							{items?.map(({id,partName,vendor,quantityOrdered,quantityReceived}) => 
								<tr key={id}>
									<td>

										{partName}
									</td>
									<td className="text-center">{quantityOrdered}</td>
									<td className="text-center">{quantityReceived}</td>
									<td className="text-center">
									{!cancelled &&
										quantityOrdered !== quantityReceived ?
											<Button onClick={() => handleItemSelect({id,partName,vendor,quantityOrdered,quantityReceived})}>Update</Button>
											:
											<Icon path={mdiCheckCircleOutline} size={1} style={{color:"#0000ff"}}  />
										
									}
									</td>
								</tr>

							)}


						</tbody>
					</Table>
				</CardBody>
			</Card>
			<ReceiveInventoryModal 
				item={currentItem}
				modalVisible={modalVisible_receive}
				setModalVisible={setModalVisible_receive}
			/>
			<UpdatePurchaseOrderModal 
				modalVisible={modalVisible_po}
				setModalVisible={setModalVisible_po}
				item={{...data.purchaseOrder,supplier:{label:data.purchaseOrder.supplier.supplierName,value:data.purchaseOrder.supplier.id}}}
			/>
			<CancelPurchaseOrderModal poId={id}
				modalVisible={modalVisible_cancel}
				setModalVisible={setModalVisible_cancel}

			 />

		</Container>
	);
}

			