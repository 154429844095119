import React, {useState} from 'react';
// reactstrap components
import {

  Input,
  FormGroup,
  Label,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import Mechanics from '../../graphql/queries/Mechanics';

export default function EmployeeFilterButton({onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedEmployees,setSelectedEmployees] = useState([])
	const [employeeCount,setEmployeeCount] = useState(0) // this is used to force a re-render when selected statuses change


	const {data,loading,error} = useQuery(Mechanics);

	if (loading || error) return null;
	if (!data) return null;



	const statusChecked = ({id}) => {

	

		// if in array, remove. if not in there, add it
		const i = selectedEmployees.indexOf(id);
		let newSelectedEmployees = selectedEmployees;
		if (i >= 0) {
			newSelectedEmployees.splice(i,1);
		}
		else {
			newSelectedEmployees.push(id);
		}

		setSelectedEmployees(newSelectedEmployees)
		setEmployeeCount(newSelectedEmployees.length)

		// announce to parent that something changed
		if (onChange) onChange(newSelectedEmployees);
	}



	return (
		<ButtonDropdown color="info" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret color="white" >
				{employeeCount === 0 ? "Assigned To All" : `${employeeCount} Assigned` }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10}}>
				{data.mechanics.map(({firstName,lastName,id}) =>
			      <FormGroup key={id} check>
			        <Label check>
			          <Input type="checkbox" 
			          		// defaultChecked={selectedEmployees.indexOf(name) != -1}
			          		 checked={selectedEmployees.indexOf(id) !== -1}
			          		 onChange={() => statusChecked({id})}
			          		 />{' '}
			          {firstName} {lastName}
			        </Label>
			      </FormGroup>

				)}
				<DropdownItem divider />
			      <FormGroup check>
			        <Label check>
			          <Input type="checkbox" 
			          		// defaultChecked={selectedEmployees.indexOf(name) != -1}
			          		 checked={selectedEmployees.indexOf(null) !== -1}
			          		 onChange={() => statusChecked({id:null})}
			          		 />{' '}
			          UNASSIGNED
			        </Label>
			      </FormGroup>

				<Button color="link" onClick={() => {setSelectedEmployees([]);setEmployeeCount(0);onChange([])}}>
					Clear All
				</Button>
			</DropdownMenu>
		</ButtonDropdown>
	);
}