import React, {useState, useContext} from "react";

import AuthContext from "../contexts/AuthContext";

import { Link } from 'react-router-dom';

// reactstrap components
import {

  Container,
  Row,
  Col,
  Button,
  CustomInput,
  Form
}
 from "reactstrap";

import { throttle } from 'throttle-debounce';


import EmployeesTable from "../components/People/EmployeesTable.js";
import SearchInput from "../components/WorkOrders/SearchInput.js";
import RoleFilterButtons from "../components/People/RoleFilterButton";

import ScanSheetModal from "../components/People/ScanSheetModal";

export default function Employees() {

  const {features} = useContext(AuthContext);

  const [filterFields,setFilterFields] = useState({showInActive:false});
  const [selectedEmployees,setSelectedEmployees] = useState([]);
  const [modalVisibleScanSheet,setModalVisibleScanSheet] = useState(false);


  const searchKeyword = throttle(1000,false,(keyword) => {
    setFilterFields({...filterFields,keyword});
  })

	return (
	      <Container  fluid>
          <h2>
            People
            <Link to="/People/New" className="ml-4">
              <Button color="primary">
                Add Person
              </Button>
            </Link>
            {features.includes('equipmentCheckout') &&
              <Button color="primary" className="float-right" onClick={() => setModalVisibleScanSheet(true)}>
                  Create Scan Sheet
              </Button>
            }
          </h2>
         
          <Row className="mb-2">
            <Col>
              <SearchInput searchKeyword={searchKeyword} />
              <Form inline className="float-left">
              <RoleFilterButtons onChange={filterRoles => setFilterFields({...filterFields,filterRoles})} />
              <CustomInput type="switch" 
              id="ActiveToggle"
              label="Show Inactive People"
              checked={filterFields.showInActive === true}
              onChange={e => setFilterFields({...filterFields,showInActive:e.target.checked})}
              />
              </Form>
            </Col>
          </Row>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">

              	<EmployeesTable filterFields={filterFields} showCheckboxes={features.includes('equipmentCheckout')} onChange={employees => setSelectedEmployees(employees)} />

            </Col>
 
          </Row>

          {features.includes('equipmentCheckout') && 
            <ScanSheetModal employees={selectedEmployees} modalVisible={modalVisibleScanSheet} setModalVisible={setModalVisibleScanSheet} />
          }
        </Container>

      );

}
