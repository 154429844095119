import gql from 'graphql-tag';

import { INSPECTION_FRAGMENT } from '../fragments/InspectionFragments';


export default gql`
mutation SortFields(
    $templateId: ID!,
    $categoryId: ID!,
    $fieldIds: [ID]!
   )  {
  sortFields (
      templateId: $templateId,
      categoryId: $categoryId,
      fieldIds: $fieldIds
    ) {
      ...InspectionAllFields
  }
}
${INSPECTION_FRAGMENT}
`;


