import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


import GraphQL from './GraphQL';

import { AuthProvider } from './contexts/AuthContext';
import { AppProvider } from './contexts/AppContext';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';

ReactDOM.render(
  <React.Fragment>
  	<GraphQL>
  		<AuthProvider>
  			<AppProvider>
	    		<App />
	    	</AppProvider>
	    </AuthProvider>
    </GraphQL>

  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
