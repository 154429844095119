// gqlFragments.js
import gql from 'graphql-tag';

export const INSPECTION_FRAGMENT = gql`
    fragment InspectionAllFields on InspectionTemplate {
      id
      templateName
      appliesTo
      odometerRequired
      isPretrip
      categories {
        id
        category
        fields {
          id
          fieldName
          fieldType
          createWorkOrderOnFail
          required
        }

      }

      


    }
`;