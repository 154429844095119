import React from 'react';
import PurchaseOrderForm from '../components/Inventory/PurchaseOrderForm';

import { Spinner} from 'reactstrap';

import { useMutation } from '@apollo/client';
import AddPurchaseOrder from '../graphql/mutations/AddPurchaseOrder';
import PurchaseOrders from '../graphql/queries/PurchaseOrders';


import { useHistory } from "react-router-dom";

export default function NewPurchaseOrderScreen() {
	const history = useHistory();


  const [addPurchaseOrder,{loading}] = useMutation(AddPurchaseOrder,{
		//onCompleted: () => history.goBack(),
		refetchQueries:[{query:PurchaseOrders,variables:{offset: 0,limit:0}}],
		onError: error => {
				return alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding purchase order . Please try again.`)
		}

  })

  const save = (vars) => {

	// are there any items?
	if (!vars.poItems || vars.poItems.length === 0) {
		alert("Please add parts to this purchase order")
		return;
	}
	// is there a supplier
	if (!vars.supplier) {
		alert("Please select a supplier")
		return;
	}

  	const variables = {
  		poDate:vars.poDate,
  		poNumber:vars.poNumber || null,
  		supplier: vars.supplier.value || null,
  		items: vars.poItems.map(item => ({inventoryItemId:item.item.id,quantity:parseFloat(item.quantity)}))
  		
  	}

  	addPurchaseOrder({variables})
      .then(result => {
        // only close if valid result returned
        if (result) {
          history.goBack()
        }
        
       })
     //.catch(err => Alert.alert("Add PO Error","There was an error adding the purchase order. Please try again")) 

  }


	return (
			<>
				{loading && <div className="h-100 w-100 text-center" style={{padding: "50%",position:"absolute",zIndex: 100,background:"rgba(0,0,0,.5)"}}><Spinner /></div>}
				<PurchaseOrderForm title="New" save={save} />
			</>
		   )


}



