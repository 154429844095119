import React, {useEffect} from 'react';
import { Table, Alert} from 'reactstrap';
import { Link } from "react-router-dom";

import moment from 'moment';

import { useQuery } from '@apollo/client';
import InventoryLog from '../../graphql/queries/InventoryLog'

import Icon from '@mdi/react'
import { mdiPlus,mdiMinus} from '@mdi/js'


export default function InventoryLogTable({offset=0,limit=20,filterFields}) {
	const {data,loading,error,refetch} = useQuery(InventoryLog,{
      variables: {
        ...filterFields,
        offset,
        limit
      }
    });

  useEffect(() => {
    refetch()
  },[filterFields,refetch])

	if (loading) return null;
	if (error) return <Alert danger>Error loading inventory log</Alert>
    

	return (
        <div className="table-responsive h-100">
                <Table className="align-items-center table-flush"  >

                
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Part/Vendor</th>
                      <th scope="col" className="text-center">Action</th>
                      <th scope="col" className="text-center">Count</th>
                      <th scope="col">Person</th>
                      <th scope="col">Notes</th>
                      <th scope="col">Work Order</th>
                    </tr>
                  </thead>
                  <tbody>
                  	{data?.inventoryLog.map(({
                  		id,
                      log_date,
                      inventory_item: {
                       partName,
                       partNumber,
                       vendor
                      },
                      inventory_added,
                      inventory_removed,
                      count,
                      user: {
                        firstName,
                        lastName
                      },
                      log_note,
                      work_order

                  	}) => {
  
                        return (
                          <tr key={id} >
                            <td>{moment(log_date).format("ll")}</td>
                            <td>
                                <strong>{partName}</strong><br />
                                <span className="note">{vendor} - {partNumber}</span>
                            </td>
                            <td className="text-center">
                              {inventory_added && <Icon path={mdiPlus} size={1} color="green" />}
                              {inventory_removed && <Icon path={mdiMinus} size={1} color="red" />}
                            </td>
                            <td className="text-center">{count}</td>
                            <td>{firstName} {lastName}</td>
                            <td>{log_note}</td>
                            <td>
                              {work_order?.ticketNumber && `#${work_order.ticketNumber}`}<br />
                              {work_order?.truck && `${work_order.truck.type} ${work_order?.truck.identifier}`}
                            </td>
                          </tr>
                         )

                    }

                  	)}
                  </tbody>
                </Table>
                {data?.inventory?.length === 0 && <div className="ml-2 text-muted">No inventory found</div>}
               </div>

	);
}