import React, { useState, useEffect } from 'react';

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

	Label,
	Button,
	Input,
	FormGroup,
	Col,
	Spinner
} from "reactstrap";


import {useMutation} from '@apollo/client';
import EmployeeBarcodeSheet from "../../graphql/mutations/EmployeeBarCodeSheet";

export default function ScanSheetModal({modalVisible=false,setModalVisible,employees=[]}) {

	const [employeeBarcodeSheet,{loading:loadingBarcodeSheet}] = useMutation(EmployeeBarcodeSheet,{
      
      onCompleted: (result) => window.open(result.employeeBarcodeSheet.url),
      onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error generating the PDF. Please try again.`)

	});


	const cancel = () => {
		setModalVisible(false);

	}

	const save = (count) => {

		employeeBarcodeSheet({
			variables: {
				people: employees,
				cellsPerRow: parseInt(count)
			}
		})
		//setModalVisible(false);

	}

	return (
      <Modal isOpen={modalVisible} toggle={() => cancel()}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => cancel()}>Print Scan Sheet</ModalHeader>
        <ModalBody className="text-center">
        		<h3>How many people per row?</h3>

        		<Button style={{width:100}} className="mr-2" onClick={() => save(1) }>1</Button>
        		<Button style={{width:100}} className="mr-2" onClick={() => save(2) }>2</Button>
        		<Button style={{width:100}} className="mr-2" onClick={() => save(3) }>3</Button>
        		<br /><br />
        		{loadingBarcodeSheet && <Spinner />}
        </ModalBody>
        <ModalFooter>
         
          <Button color="secondary" onClick={() => cancel()}>Cancel</Button>
        </ModalFooter>
      </Modal>


	)
}