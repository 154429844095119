
import gql from 'graphql-tag';
import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
mutation TakeTruckOutOfService(
    $id: ID!
  ) {
  takeTruckOutOfService(
  	id:$id

  ) {
 	...TruckAllFields 
  }
}
${TRUCK_FRAGMENT}
`;