import gql from 'graphql-tag';

export default gql`
query Employees($filterRoles: [String])
{
  employees(filterRoles:$filterRoles) {
    id
    firstName
    lastName
    phones {
      type
      number
    }
	 email
	 roles
   isActive
  }
}
`;