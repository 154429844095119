import React, {useState} from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Spinner,
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from "reactstrap";


import InspectionTemplateCategories from '../components/Inspections/InspectionTemplateCategories';


import { useQuery } from '@apollo/client';
import InspectionTemplate from '../graphql/queries/InspectionTemplate';

export default function InspectionTemplateDetail({match:{params:{id}}}) {

 
    const {data,loading,error} = useQuery(InspectionTemplate,{variables:{id}});



    if (loading) return <Spinner />
    if (error) return <Container>There was an error loading the template. Please try refreshing this page.</Container>


    const {
      templateName,
      appliesTo,
      odometerRequired,
      isPretrip,
      categories
    } = data?.inspectionTemplate

    return (


        <Container className="mt--8" fluid>
          {/* TEMPLATE INFO */}
          <Card className="shadow mb-4">
            <CardHeader className="pb-0" style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
              <CardTitle className="m-0 float-left">
                <h2>Inspection Template: {templateName}</h2>
              </CardTitle>
            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <Col>
                  <Table className="table-flush w-100"  >
                    <tbody>
                        <tr>
                          <th style={{width: 200}}>Template For:</th>
                          <td>
                            {(!appliesTo || appliesTo.length === 0) && "ALL ASSETS" }
                            <ul>{appliesTo?.map(t => <li key={t}>{t}</li>)}</ul>
                          </td>
                        </tr>
                        <tr>
                          <th>Odometer Required?</th>
                          <td>{odometerRequired ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                          <th>Is Pretrip?</th>
                          <td>{isPretrip ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <p>
            <strong>Categories and fields are optional.</strong> Any inspection without fields will allow drivers and mechanics to select "No defects" or manually add issues to the inspection report.  
          </p>

          <InspectionTemplateCategories templateId={id} categories={categories} />
        </Container>

  
    );
  
}


