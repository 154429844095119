import React, {useState} from 'react';


import {
	Container,
	Row,
	Col,
	Card,
    CardHeader,
	Spinner,

	ListGroup,
	ListGroupItem,

	Button
} from 'reactstrap';

import LocationForm from '../../components/AccountSettings/LocationForm';

import { useQuery, useMutation } from "@apollo/client";
import Locations from "../../graphql/queries/Locations";
import RemoveLocation from '../../graphql/mutations/RemoveLocation';


export default function LocationsView() {

	const {data,loading,error} = useQuery(Locations);
    const [removeLocation,{loading:loadingRemove}] = useMutation(RemoveLocation,{refetchQueries:[{query:Locations}]});
    const [currentLocation,setCurrentLocation] = useState();

	if (loading) return <Spinner />

    const remove = (location) => {

        const { id, locationName} = location;
        setCurrentLocation(locationName);
        removeLocation({variables:{id}});
    }


	return (

        <Container fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
          		
            	<Card>
                    <CardHeader >
                    <Row>
                        <Col sm={12}>
                            <LocationForm />
                        </Col>
                        
                    </Row>
                    </CardHeader>

            		{error && <span>There was a problem loading locations. Please try again.</span>}
            		<ListGroup>
	            		{data && data.locations && data.locations.map(({locationName,id}) => 
	            			<ListGroupItem key={id}>
                                {locationName}
                                {loadingRemove && currentLocation === locationName ?
                                   <Spinner className="ml-1" />
                                   :
                                    <Button color="link" className="ml-1" onClick={() => remove({locationName,id})}>
                                        remove
                                    </Button>

                                }
                             </ListGroupItem>
	            		)}
            		</ListGroup>
            	</Card>
            </Col>
          </Row>
        </Container>


	);
}