import React from 'react';
import {Spinner,Card, CardHeader, CardTitle, Table, Alert, Badge} from 'reactstrap';
import { Link } from "react-router-dom";



const badgeBackgroundColor = ({OpenWorkOrders,OutOfService}) => {
	if (OpenWorkOrders === 0) {
		return {backgroundColor:"green"};
	}

	if (OutOfService) {
		return {backgroundColor:"green"};
	}

	return {backgroundColor:"yellow",color:"#000000"};
}

export default function EquipmentTable({data,loading,error}) {
	//const {data,loading,error} = useQuery(Trucks);

	
	if (error) return <Alert danger>Error loading Trucks</Alert>

	return (
               <Card className="shadow mb-4" style={{height: 250}}>
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Equipment Status</CardTitle></CardHeader>

                {loading && <Spinner />}
                {!loading && (!data.trucks || data.trucks.length === 0) &&
                	<div style={{textAlign:'center',marginTop:"10%"}}>
                		<p>You haven't added any assets yet.</p>
                		<Link className="btn btn-primary" to="/Asset/New">
                			Add Asset
                		</Link>
                	</div>
                }
                <Table className="align-items-center table-flush" responsive>
                  <tbody>
                  	{data?.trucks?.map(({
                  		id,
                  		type,
                  		identifier,
                  		OpenWorkOrders,
                  		OutOfService
                  	}) =>
	                    <tr key={id}>
	                      <td><Badge style={badgeBackgroundColor({OpenWorkOrders,OutOfService})}>{OpenWorkOrders}</Badge></td>
	                      <td><Link to={`/Asset/${id}`}>{type} {identifier}</Link></td>

	                    </tr>

                  	)}
                  </tbody>
                </Table>
              </Card>
	);
}