import React, {useState} from 'react';

import {useQuery} from '@apollo/client';
import MyInfo from '../graphql/queries/MyInfo';

const AuthContext = React.createContext();



export const AuthProvider = ({children}) => {
	
	const [isLoggedIn,setIsLoggedIn] = useState(false)
	const [roles,setRoles] = useState([]);
	const [trialInfo,setTrialInfo] = useState();
	const [fullName,setFullName] = useState({firstName:"Test",lastName:"Account"});
	const [features,setFeatures] = useState([]);
	const [services,setServices] = useState([]);

	// get users info when page loaded
	const {loading, refetch} = useQuery(MyInfo,{
		fetchPolicy:'network-only',
		// if success, set state fields
		onCompleted: (result) => {
			
			if (result && result.me) {

				setFullName({firstName:result.me.firstName,lastName:result.me.lastName})
				setRoles(result.me.roles);
				setTrialInfo({
					accountType:result.me.accountType,
					planExpiration:result.me.planExpiration
				});
				setIsLoggedIn(true);
				setFeatures(result.me.features);
				setServices(result.me.services);

			}
			else {
				// if you go here, you aren't logged in
				setIsLoggedIn(false);
			}
		}
	});

	// if user in roles? (default to isadmin type role)
	function userInRole(args) {
		const a = args || {};
		const authRoles = a.authRoles || ['Owner','Office Manager','Shop Manager'];
		// loop over roles passed in and user's roles
		const test = roles.find(role => {
			// is this role in auth roles?
			return authRoles.find(el => el === role) !== undefined
		});

		// if undefined, then no matches
		return test !== undefined;
	}



	if (loading) return <div>Loading....</div>;

	return <AuthContext.Provider value={{
			userInRole,
			isLoggedIn,

			setIsLoggedIn,
			setFullName,
			setRoles,
			roles,
			features,setFeatures,
			services,setServices,
			fullName,
			trialInfo,setTrialInfo,
			reloadMyInfo:() => refetch()



		}}>
		{children}
	</AuthContext.Provider>

}


export default AuthContext;