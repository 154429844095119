import gql from 'graphql-tag';

import { INSPECTION_FRAGMENT } from '../fragments/InspectionFragments';

export default gql`
mutation RemoveInspectionTemplateField(
    $templateId: ID!,
    $categoryId: ID!,
    $fieldId: ID!
   )  {
  removeInspectionTemplateField (
      templateId: $templateId,
      categoryId: $categoryId,
      fieldId: $fieldId
    ) {
       ...InspectionAllFields
  }
}
${INSPECTION_FRAGMENT}
`;



