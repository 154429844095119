import gql from 'graphql-tag';

import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
query Truck($id:ID!){
  truck(id:$id) {
    ...TruckAllFields
  }
}
${TRUCK_FRAGMENT}
`;