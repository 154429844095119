import React, {useState} from "react";

// reactstrap components
import {

  Container,


  Button,

Card, 
}
 from "reactstrap";




import PermitsTable from "../components/Permits/PermitsTable.js";

import {Link} from 'react-router-dom';

export default function Permits() {
	const limit = 20;
	const [offset] = useState(0);





	return (

        <Container fluid>
          <h2 >
            Permits
            <Link to="/Permit/new" className="ml-4">
              <Button color="primary" size="sm">
               New Permit
              </Button>
            </Link>
          </h2>

               <Card className="shadow">

              		<PermitsTable offset={offset} 
              						 limit={limit}

              				 />

              </Card> 

        </Container>

      );

}
