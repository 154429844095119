import React from 'react';

import { createBrowserHistory } from 'history';
   
// graphql stuff
import { ApolloProvider,ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
//import { WebSocketLink } from '@apollo/client/link/ws';


//import { getMainDefinition } from '@apollo/client/utilities';







// to mimic mobile app var
const API_URL = process.env.REACT_APP_API_URL;
//const WS_URL = process.env.REACT_APP_WS_URL

// link to graphql api
const httpLink = createHttpLink({
  uri: API_URL + '/graphql'
});

// this adds auth token to headers for graphql queries and mutations
const authLink = setContext((_, { headers }) => {
  
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('Authorization');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      ...headers,
      authorization: token ? token : null
    }
  }
});

// link to graphql subscriptions
/*const wsLink = new WebSocketLink({
  uri: `${WS_URL}:4000/graphql`,
  options: {
    reconnect: true,
    // auth headers for subscriptions
   connectionParams:async () => ( {
           headers: {
             authorization: localStorage.getItem('Authorization')
           }   
        }),
  }
});*/

// choose proper link bases on subscription or query/mutation
/*const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);*/

// need this to redirect to login in case logged out by api
const browserHistory = createBrowserHistory();


const linkError = onError(({ graphQLErrors, networkError,operation,response }) => {
  console.log("ERROR TRAPPED",graphQLErrors,networkError)
 
  if (graphQLErrors)
    {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
  if (networkError) {
    // ignore 401 errors for login
    
    const {operationName} = operation;
    console.log("operationName",operationName)
    if (operationName && operationName === 'getLoginInfo') {
      return;
    }

    // if 401 error and no token, send to auth page (this is a backup in case logged out and navigate to interior page)
    if (!localStorage.getItem("Authorization")) {
      browserHistory.push("/");
      
    }

    // if not authorized to do something, let user know
    if (networkError.statusCode && networkError.statusCode === 401) {
      alert("You don't have permission to view this.")

    }

  }


});

// graphql client
const client = new ApolloClient({
  link: authLink.concat(linkError).concat(httpLink), //.concat(splitLink),
  cache: new InMemoryCache({
    dataIdFromObject: o => o.id
  })
});


export default function GraphQL({children}) {
  return (<ApolloProvider client={client}>{children}</ApolloProvider>)

} 