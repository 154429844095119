import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';
 

export default gql`
mutation UpdatePermitDates($permit: ID!,$assignedTo: PermitTruckAssignedInput,$startDate:DateTime,$renewalDate: DateTime) {
  updatePermitDates(permit:$permit,assignedTo:$assignedTo,startDate:$startDate,renewalDate:$renewalDate) {
    ...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;
