import React, { useState, useEffect } from 'react';



import {

  Container,
  Row,
  Col,
  FormGroup, Label, Input,FormText,Spinner,Button,
Card, CardBody,CardHeader
}
 from "reactstrap";


import {useQuery,useMutation} from '@apollo/client';
import MySupportInfo from '../graphql/queries/MySupportInfo';
import SupportRequest from '../graphql/mutations/SupportRequest';

export default function Support() {

	const [message,setMessage] = useState();
	const [contactAddr,setContactAddr] = useState();
	const [contactType,setContactType] = useState("email");

	const {data} = useQuery(MySupportInfo);
	const [supportRequest, {loading:sending}] = useMutation(SupportRequest);

	useEffect(() => {
		setContactAddr(data?.mySupportInfo?.email)
	},[data])

	const send = () => {
		supportRequest({variables:{
			message,
			contactAddr,
			contactType
		}})
		.then (() => {
			alert("Message sent. We will be in touch ASAP");
			setMessage();
			setContactAddr();
			setContactType();
		})
		.catch(err => alert("There was a problem sending your message. Please try again or email support@fleetcommand.io"))
	}

	return (

        <Container  fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
            	<Card>
            		<CardHeader>Need help? We will get back to you ASAP.</CardHeader>
			        <CardBody>
			        	<FormGroup>
			        		<Label>How can we help you?</Label>
			        		<Input type="textarea" value={message || ""} onChange={e => setMessage(e.target.value)} />
			        	</FormGroup>
			        	{data && data.mySupportInfo &&
				        	<FormGroup>
				        		<Label>What's the best way to get back to you?</Label>
				        		<Row>
				        			<Col sm={2}>
						        		<Input type="select" value={contactType} onChange={(e) => setContactType(e.target.value)}>
						        			<option value="email">Email</option>
						        			<option value="text">Text</option>
						        			<option value="call">Call</option>
						        		</Input>

				        			</Col>
				        			<Col sm={10}>
						        		{contactType === 'email' && <FormText className="mt-2" color="normal" tag="lg">{data.mySupportInfo.email}</FormText>}
						        		{(contactType === 'call' ||contactType === 'text') && 
						        			<Input type="select" value={contactAddr} onChange={e => setContactAddr(e.target.value)}>
						        				{data.mySupportInfo.phones.map(({type,number},index) => <option key={index} value={number}>{number}</option>)}
						        			</Input>
						        		}
				        			</Col>
				        		</Row>
				        	</FormGroup>
				        	
			        	}
			        	<Button color="primary" onClick={() => send()}>Send{sending && <Spinner />}</Button>
			        </CardBody>
            	</Card>

            </Col>
          </Row>
        </Container>
     

	)
}