import React, {useEffect,useState} from 'react';
import { Table, Alert,
  Spinner,
  Button,
  Form,
  FormGroup

} from 'reactstrap';
import { Link } from "react-router-dom";


import { useQuery, useMutation } from '@apollo/client';
import Inventory from '../../graphql/queries/PendingMorningRushInventory';
import Employees from '../../graphql/queries/Employees';
import SetForUser from '../../graphql/mutations/SetInventoryForUser';
import SetComplete from '../../graphql/mutations/SetInventoryComplete';

import Select from 'react-select';


const customStyles = {
  container: (base) =>  ({
    ...base,
    flex:1
  })
  /*({
    width: "100%"
  })*/,

  /*
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
  }),

  control: (_, { selectProps: { width }}) => ({
    width: width
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }*/
}



export default function MorningRushInventoryTable({offset=0,limit=20,filterFields}) {
  // current record being updated
  const [updateRecordId,setUpdateRecordId] = useState();
  // current record being completed
  const [completing,setCompleting] = useState();
  // used to keep most recent entry at top of employee list. (assuming employee may have multiple items)
  const [recentEmployee,setRecentEmployee] = useState({isdisabled: true});

  const [setForUser,{loading:loading_forUser}] = useMutation(SetForUser, {
    refetchQueries: [{query:Inventory}]
  }); 
  const [setComplete,{loading:loading_forComplete}] = useMutation(SetComplete, {
    refetchQueries: [{query:Inventory}],
    onCompleted: (data) => setCompleting(null)
  }); 


  const {data:data_employees} = useQuery(Employees);
	const {data,loading,error,refetch} = useQuery(Inventory, {
    pollInterval: 5000
  });

  useEffect(() => {
    refetch()
  },[filterFields,refetch])

	if (loading) return null;
	if (error) return <Alert danger>Error loading inventory</Alert>


  const handleEmployeeSelect = (value) => {
    setRecentEmployee(value);
  }
  const handleSetForUser = ({id,forUser,forUserName,partName,partNumber,vendor,quantityReceived}) => {
      // update API and hide record assuming it will be a success
      setForUser({
        variables: {
          id:parseInt(id),
          forUser,
          forUserName
        },
        optimisticResponse: {
          setForUser: {
            id,
            partName,
            partNumber,
            vendor,
            quantityReceived,
            for_user: forUser,
            for_user_name: forUserName,
            __typename: "InventoryItemType"
          }
        }
      })
      setUpdateRecordId(null)


  }

  const handleSetComplete = (id) => {
    setCompleting(id);
    // set that it's processed
    setComplete({variables:{id:parseInt(id)}})
  }

	return (
        <div className="table-responsive h-100">


        
                <Table className="align-items-center table-flush"  >

                
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Part Name</th>
                      <th scope="col">Vendor</th>
                      <th scope="col" className="text-center">Quantity Pulled</th>
                      <th scope="col">Pulled For</th>
                      {/*<th scope="col">Notes</th>*/}
                      <th scope="col" style={{width:100}}>Complete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.pendingMorningRushInventory.length === 0 && <tr><td colSpan="100%">You're all set. Nothing to update.</td></tr>}
                  	{data.pendingMorningRushInventory.map(({
                      id,
                      partName,
                      partNumber,
                      vendor,
                      quantityReceived,
                      for_user,
                      for_user_name
                  	}) => {

                        return (
                          <tr key={id} id={id}>
                            <th scope="row">
                              {partName}
                              {loading_forComplete && completing === id && <Spinner className="ml-2" size="sm" />}
                            </th>
                            <td>{vendor}<br /><span className="text-muted">{partNumber}</span></td>
                            <td className="text-center">{quantityReceived}</td>
                            <td>
                              {updateRecordId !== id && !for_user && <Button onClick={() => {
                                // if another row isn't being edited, then edit this one
                                if (!updateRecordId) {
                                  setUpdateRecordId(id);
                                }
                              }}>Set Team Member</Button>}
                              {for_user && <span>
                                {for_user_name}
                                <Button onClick={() => {setUpdateRecordId(id);}} color="link">change</Button>
                                </span>}
                              {updateRecordId === id &&
                                <Form inline>
                                <FormGroup style={{flex:1}}>
                                   <Select
                                     isOptionDisabled={(option) => option.isdisabled}
                                       styles={customStyles}
                                      defaultValue={recentEmployee}
                                      
                                      placeholder="Type name...."
                                      onChange={handleEmployeeSelect}
                                      
                                      options={[recentEmployee,...data_employees?.employees.map(({id,firstName,lastName}) => ({label:`${firstName} ${lastName}`,value:id}))]}
                                  />
                                  </FormGroup>
                                  <Button className="mr-1" onClick={() => handleSetForUser({id,forUser:recentEmployee.value,forUserName:recentEmployee.label,id,partName,partNumber,vendor,quantityReceived})}>Set</Button>
                                  <Button color="light" onClick={() => setUpdateRecordId(null)}>Cancel</Button>
                               </Form>

                              }
                            </td>
                            {/*<td>Note field goes here</td>*/}
                            <td><Button onClick={() => handleSetComplete(id)} color="primary">Done</Button></td>
                          </tr>
                         )

                    }

                  	)}
                  </tbody>
                </Table>
               </div>

	);
}