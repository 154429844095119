import gql from "graphql-tag";



export default gql`
query InventoryExport($offset: Int, $limit: Int,$keyword: String,$noBarcodeOnly: Boolean,$missingCost: Boolean,$missingQuantity:Boolean) {
	
	  inventoryExport(offset:$offset,limit:$limit,keyword: $keyword,noBarcodeOnly:$noBarcodeOnly,missingCost:$missingCost,missingQuantity:$missingQuantity) 


	  
	
}
`;