import React, {useEffect} from 'react';
import { Table, Alert, Badge} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

import { useQuery } from '@apollo/client';
import WorkOrders from '../../graphql/queries/WorkOrders'

export default function WorkOrdersTable({offset=0,limit=5,filterFields, cardHeight}) {
	const {data,loading,error,refetch} = useQuery(WorkOrders,{
      variables: {
        ...filterFields,
        offset,
        limit
      }
    });

  useEffect(() => {
    refetch()
  },[filterFields,refetch])

	if (loading) return null;
	if (error) return <Alert danger>Error loading work orders</Alert>

	return (
        <div className="table-responsive" style={cardHeight ? {height:cardHeight} : {}}>
                <Table className="align-items-center table-flush"  >
                  <thead>
                    <tr>
                      <th scope="col">Ticket #</th>
                      <th scope="col">Equipment</th>
                      <th scope="col">Assigned To</th>
                      <th scope="col">Description</th>
                      <th scope="col">Date Reported</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(!data.workOrders || data.workOrders.length === 0) && 
                      <tr><td>No work orders found</td></tr>
                    }
                  	{data.workOrders.map(({
                  		id,
                  		ticketNumber,
                  		truck:{type,identifier},
                  		status,
                  		createdAt,
                  		description,
                      assignedTo,
                      outOfService
                  	}) =>
	                    <tr key={id} style={(outOfService && status !== "Work Complete") ? {backgroundColor:'red',color:"#ffffff"} : {}}>
	                      <th scope="row"><Link to={`/WorkOrder/${id}`}>{ticketNumber}</Link></th>
	                      <td>{type} {identifier}</td>
                        <td>{assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : "UNASSIGNED"}</td>
	                      <td>{description}</td>
	                      <td>{moment(createdAt).format("M/D/YYYY h:mm a")}</td>
	                      <td><Badge  color={status === "Open" ? "primary" : status === "Work Complete" ? "success" : "info"}>{status}</Badge></td>
	                    </tr>

                  	)}
                  </tbody>
                </Table>

             </div>
	);
}