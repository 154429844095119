import gql from 'graphql-tag';
import { INSPECTION_FRAGMENT } from '../fragments/InspectionFragments';


export default gql`
mutation AddInspectionTemplateCategory(
    $templateId: ID!,
    $category: String!
   )  {
  addInspectionTemplateCategory (
      templateId: $templateId,
      category: $category
    ) {
      ...InspectionAllFields
  }
}
${INSPECTION_FRAGMENT}
`;




