import gql from 'graphql-tag';

export default gql`
mutation AddPurchaseOrder(
				$poDate: DateTime,
				$poNumber: String,
				$supplier: ID,
				$items: [PurchaseOrderItem]

	) {
		addPurchaseOrder(
			poDate:$poDate,
			poNumber:$poNumber,
			supplier:$supplier,
			items: $items
		) {
			id
		}
	}

`;