import React, {useState} from 'react';
// reactstrap components
import {

  Input,
  FormGroup,
  Label,
  Button,
  ButtonDropdown,
  
  DropdownToggle,

  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import Roles from '../../graphql/queries/Roles';

export default function RoleFilterButton({onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedRoles,setSelectedRoles] = useState([])
	const [roleCount,setRoleCount] = useState(0) // this is used to force a re-render when selected roles change


	const {data,loading,error} = useQuery(Roles);

	if (loading || error) return null;

	if (!data) return false;



	const roleChecked = ({role}) => {

	

		// if in array, remove. if not in there, add it
		const i = selectedRoles.indexOf(role);
		let newSelectedRoles = selectedRoles;
		if (i >= 0) {
			newSelectedRoles.splice(i,1);
		}
		else {
			newSelectedRoles.push(role);
		}

		setSelectedRoles(newSelectedRoles)
		setRoleCount(newSelectedRoles.length)

		// announce to parent that something changed
		if (onChange) onChange(newSelectedRoles);
	}



	return (
		<ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret color="white" >
				{roleCount === 0 ? "All Roles" : `${roleCount} Roles` }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10,minWidth: 200}}>
				{data.roles.map((role) =>
			      <FormGroup key={role} check>
			        <Label check style={{marginTop:6}}>
			          <Input type="checkbox" 
			          		
			          		 checked={selectedRoles.indexOf(role) !== -1}
			          		 onChange={() => roleChecked({role})}
			          		 />{' '}
			          {role}
			        </Label>
			      </FormGroup>

				)}
				<Button color="link" onClick={() => {setSelectedRoles([]);setRoleCount(0);onChange([])}}>
					Clear All
				</Button>
			</DropdownMenu>
		</ButtonDropdown>
	);
}