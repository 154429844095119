import React, {useState,useEffect} from "react";

// reactstrap components
import {

  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  CustomInput
}
 from "reactstrap";

import { throttle } from 'throttle-debounce';

import InspectionTemplateTable from "../components/Inspections/InspectionTemplateTable.js";
import AuthSection from '../components/AuthSection';

import {Link} from 'react-router-dom';

export default function InspectionTemplate() {
	//const limit = 20;
  //const [filterFields,setFilterFields] = useState({});
  //const [keyword,setKeyword] = useState({});
	//const [offset] = useState(0);

    // set card height for bottom cards to window minus 60 (padding at top) minus 140 (for rows above table) minus 6 just for spacing
    //const bottomCardHeight = window.innerHeight - 80-6-140;
  const [bottomCardHeight,setBottomCardHeight] = useState(window.innerHeight - 80-6);
  

  useEffect(() => {
    function handleResize() {
      setBottomCardHeight(window.innerHeight - 80-6)
    }
    window.addEventListener('resize', handleResize)
  })



 /* const filterAssets = throttle(1000,false,(keyword) => {
    setKeyword(keyword)
  })*/



	return (

        <Container fluid>
          <h2>Inspection Templates
              <AuthSection>
                      <Link to="/InspectionTemplate/new" className="ml-4">
                        <Button color="primary">
                          Add Template
                        </Button>
                      </Link>
              </AuthSection>
          </h2>

         {/*} <Row className="mb-2">
            <Col>
              <SearchInput searchKeyword={filterAssets} placeholder="Keyword..." />
            </Col>
          </Row> 
          <Row className="mb-2">
            <Col>
              <Form inline className="float-left">
                <TypeFilterButton onChange={(types) => setFilterFields({...filterFields,types})} />
                <FormGroup className="p-2">
                  <CustomInput type="switch" 
                       id="ExpiringToggle"
                       label="Expiring Items"
                       checked={filterFields.expiringFedAnnual === true}
                       onChange={e => setFilterFields({...filterFields,expiringFedAnnual:e.target.checked})}
                       />
                </FormGroup> 
              </Form>

            </Col>

          </Row>*/}


          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">

              		<InspectionTemplateTable 
                           
                           cardHeight={bottomCardHeight}
              				 />

            </Col>
 
          </Row>
        </Container>
  
      );

}
