import React, {useState} from 'react';


// reactstrap components
import {
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
  Button
} from "reactstrap";

import InspectionFieldsTable from './InspectionFieldsTable';


import { useMutation } from '@apollo/client';
import AddInspectionTemplateField from '../../graphql/mutations/AddInspectionTemplateField';
import RemoveInspectionTemplateField from '../../graphql/mutations/RemoveInspectionTemplateField';
import SortInspectionTemplateFields from '../../graphql/mutations/SortInspectionTemplateFields';

export default function InspectionTemplateFields({templateId,categoryId,fields}) {
  const defaultFormValues = {fieldName:"",fieldType:"Pass/Fail",category:null}
  const [formFieldValues,setFormFieldValues] = useState(defaultFormValues);

  const [currentRow,setCurrentRow] = useState();

  const [addInspectionTemplateField,{loading}] = useMutation(AddInspectionTemplateField,{
    onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the field . Please try again.`)
  })
  const [removeInspectionTemplateField,{loading:removing}] = useMutation(RemoveInspectionTemplateField,{
    onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error removing the field . Please try again.`)
  })
  const [sortInspectionTemplateFields,{loading:sorting}] = useMutation(SortInspectionTemplateFields,{
    onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error sorting the fields . Please try again.`)
  })

  const save = () => {

    const {
      fieldName,
      required
    } = formFieldValues;





    if (!fieldName || fieldName.trim().length === 0) {
      return alert("Please enter a field name")
    }

    addInspectionTemplateField({variables:{
      ...formFieldValues,
      // convert required to boolean
      required: !required ? true : required === "false" ? false : true,
      templateId,
      categoryId
    }})
    .then((result) => {
      // if there wasn't an error, clear form
      if (result)
        setFormFieldValues(defaultFormValues);
    })
  }
  const remove = (fieldId) => {

    removeInspectionTemplateField({variables:{
      templateId,
      categoryId,
      fieldId
    }})
  }

  const handleDrop = (e) => {
    e.preventDefault();
    

  

    // NEED TO PASS NEW ORDER TO DATABASE
      // get rows
      const rows = Array.from(e.target.parentNode.parentNode.children);
      // get _ids in order
      const fieldIdsInOrder = rows.map(row => row.getAttribute("fieldid"));
      console.log("dropped",fieldIdsInOrder)
       sortInspectionTemplateFields({variables: {
        templateId,
        categoryId,
        fieldIds: fieldIdsInOrder
      }})

       return false

  }


  const handleStart = (e) => {
    setCurrentRow(e.target);
  }
  const handleDragOver = (e) => {
    e.preventDefault();

    let children= Array.from(e.target.parentNode.parentNode.children);
      if(children.indexOf(e.target.parentNode)>children.indexOf(currentRow))
        e.target.parentNode.after(currentRow);
      else
        e.target.parentNode.before(currentRow);




  }


  return (
      <>
                <Form onSubmit={(e) => {e.preventDefault();save()}} inline style={{border: "1px solid #cccccc",padding: 10,background: "#e2e2e2"}}>

                  <FormGroup>
                    <Label className="mr-1" style={{fontWeight: 'bold'}}>Name:</Label>
                    <Input className="mr-2" placeholder="Field Name" value={formFieldValues.fieldName} onChange={e => setFormFieldValues({...formFieldValues,"fieldName":e.target.value})} />
                  </FormGroup>
                  <FormGroup>
                    <Label className="mr-1" style={{fontWeight: 'bold'}}>Type: </Label>
                    <Input className="mr-2" type="select" value={formFieldValues.fieldType} onChange={e => setFormFieldValues({...formFieldValues,"fieldType":e.target.value})}>
                      <option value="Pass/Fail">Pass/Fail</option>
                      <option value="Yes/No">Yes/No</option>
                      <option value="Text">Text</option>
                      <option value="Number">Number</option>
                      <option value="Date">Date</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label className="mr-1" style={{fontWeight: 'bold'}}>Required?: </Label>
                    <Input className="mr-2" type="select" value={formFieldValues.required || true} onChange={e => setFormFieldValues({...formFieldValues,"required":e.target.value})}>
                      <option value={true}>Yes</option>
                      <option value={false}>No</option>
                    </Input>
                  </FormGroup>
                  <Button type="submit" color="primary" >
                    Add Field {loading && <Spinner size="sm" />}
                  </Button>
                </Form>
                {!fields?.length > 0 && <p>Please add some fields for this category</p>}
                <InspectionFieldsTable
                    fields={fields}
                    sorting={sorting}
                    removing={removing}
                    remove={remove}
                    handleDrop={handleDrop}
                    handleStart={handleStart}
                    handleDragOver={handleDragOver}
                 />

      </>
  );
}

/*
            draggable="true"
            onDragStart={handleDragStart}
            onDragLeave={handleDragLeave}

*/
