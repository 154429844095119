
import React from "react";

import { Link } from 'react-router-dom';

// reactstrap components
import {

  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
  
}
 from "reactstrap";

export default function Reports() {


	return (
	      <Container  fluid>
          <h2>
            Reports
          </h2>
          <Row className="mb-2">
            <Col>
              <ListGroup>
  
              <ListGroupItem>
                <ListGroupItemHeading>Inventory Reports</ListGroupItemHeading>
                <ListGroupItemText>
                  <ul>
                    <li><Link to="/Reports/Inventory/PartsUsage">Parts Usage Report</Link></li>
                  </ul>
                </ListGroupItemText>
                <ListGroupItemHeading>Vehicle Reports</ListGroupItemHeading>
                <ListGroupItemText>
                  <ul>
                    <li><Link to="/Reports/Inspections/Pretrip">Pre-trip Inspections</Link></li>
                  </ul>
                </ListGroupItemText>
              </ListGroupItem>

              </ListGroup>
            </Col>
          </Row>

        </Container>

      );

}
