import React, {useState} from 'react';


import InventoryItemCard from '../components/Inventory/InventoryItemCard';

import AddInventoryModal from '../components/Inventory/AddInventoryModal';
import RemoveInventoryModal from '../components/Inventory/RemoveInventoryModal';
import AddToExistingPartModal from '../components/Inventory/AddToExistingPartModal';
import EditPartDetails from '../components/Inventory/EditPartDetails';
import EditPartVendorDetails from '../components/Inventory/EditPartVendorDetails';

import {
	Container,

	Card,
	CardBody,
	Spinner,
	Button,
    Row,
    Col
} from 'reactstrap';

import { useQuery } from '@apollo/client';
import InventoryItem from '../graphql/queries/InventoryItem';

export default function InventoryDetail({match:{params:{id}}}) {

	const {data,loading,error} = useQuery(InventoryItem,{variables:{id:parseInt(id)}})

	const [editItem,setEditItem] = useState({});

	const [modalVisible_AddInventory,setModalVisible_AddInventory] = useState(false);
	const [modalVisible_RemoveInventory,setModalVisible_RemoveInventory] = useState(false);
	const [modalVisible_InventoryAdd,setModalVisible_InventoryAdd] = useState(false);
	const [modalVisible_EditPartDetails,setModalVisible_EditPartDetails] = useState(false);
	const [modalVisible_EditPartVendorDetails,setModalVisible_EditPartVendorDetails] = useState(false);

	if (loading) return <Spinner />
  	if (error) return <div>Error loading inventory</div>

	  const {
	    partName,
	    numberInStock,
	    warningThreshold,
	    location,
	    inventoryItems,
        category
	  } = data.inventoryItem;


	return (
        <Container fluid>
            <Row>
                <Col md={6}>
                    <h2>{partName}</h2>
                </Col>
                <Col md={6}>
                    <Button className="float-right" color="primary" size="sm" onClick={() => setModalVisible_EditPartDetails(true)}>
                        <i className="fas fa-edit mr-1" /> 
                        Edit
                    </Button>
                    <Button className="float-right mr-1" color="primary" size="sm" onClick={() => setModalVisible_InventoryAdd(true)}>
                        <i className="fas fa-edit mr-1" /> 
                        Add Part from Other Vendor
                    </Button>

                </Col>
            </Row>
            <Row>
                <Col md={3}>
                    <Card className="shadow">
                        <CardBody>
                            <div className="mb-1" style={{fontWeight: 500}}>
                                # in stock
                                <div className="text-muted">{numberInStock || 'n/a'}</div>
                            </div>
                            <div className="mb-1" style={{fontWeight: 500}}>
                                Alert Threshold
                                <div className="text-muted">{warningThreshold || 'n/a'}</div>
                            </div>
                            <div className="mb-1" style={{fontWeight: 500}}>
                                Location
                                <div className="text-muted">{location ? location : "(unassigned)"}</div>
                            </div>
                            <div className="mb-1" style={{fontWeight: 500}}>
                                Category
                                <div className="text-muted">{category?.category ? category?.category : "(unassigned)"}</div>
                            </div>
                        </CardBody>
                    </Card>

                </Col>
                <Col md={9}>
                    {inventoryItems.map(item => 
                        <InventoryItemCard 
                                key={item.id} 
                                item={item} 
                                partName={partName}
                                add={() => {setEditItem(item);setModalVisible_AddInventory(true)}} 
                                remove={() => {setEditItem(item);setModalVisible_RemoveInventory(true)}} 
                                edit={() => {setEditItem(item);setModalVisible_EditPartVendorDetails(true)}} 
                                />)}

                </Col>
            </Row>

        	<AddInventoryModal
        		partName={partName}
        		item={editItem}
        		modalVisible={modalVisible_AddInventory}
        		setModalVisible={setModalVisible_AddInventory}
        	/>
        	<RemoveInventoryModal
        		partName={partName}
        		item={editItem}
        		modalVisible={modalVisible_RemoveInventory}
        		setModalVisible={setModalVisible_RemoveInventory}
        	/>
        	<AddToExistingPartModal
        		inventoryId={id}
        		modalVisible={modalVisible_InventoryAdd}
        		setModalVisible={setModalVisible_InventoryAdd}
        	/>
        	<EditPartDetails
        		id={id}
        		location={location}
                category={category}
        		partName={partName}
        		warningThreshold={warningThreshold}
        		modalVisible={modalVisible_EditPartDetails}
        		setModalVisible={setModalVisible_EditPartDetails}
        	/>
        	<EditPartVendorDetails
        		id={editItem?.id}
        		vendor={editItem?.vendor}
        		partDescription={editItem?.partDescription}
        		partNumber={editItem?.partNumber}
        		cost={editItem?.cost}

        		modalVisible={modalVisible_EditPartVendorDetails}
        		setModalVisible={setModalVisible_EditPartVendorDetails}
        	/>
        </Container>

	);
}

