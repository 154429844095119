import gql from 'graphql-tag';

export default gql`
mutation UpdateEmployee(
    $id: ID!,
    $firstName: String!,
    $lastName: String!,
    $phones: [PhoneInput],
    $email: String,
    $roles: [String],
    $isActive: Boolean,
    $driversLicenseExpiration: DateTime,
    $medicalCardExpiration: DateTime 
) {
  updateDriver(
    id: $id,
    firstName: $firstName,
    lastName: $lastName,
    phones: $phones,
    email: $email,
    roles: $roles,
    isActive: $isActive,
    driversLicenseExpiration: $driversLicenseExpiration,
    medicalCardExpiration: $medicalCardExpiration

  ) {
        id
        firstName
        lastName
        phones {
          type
          number
        }
        email
        roles
        isActive
        driversLicenseExpiration
        medicalCardExpiration      

  }
}

`;