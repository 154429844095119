import gql from 'graphql-tag';

export default gql`
{
  workOrderStats {
    label
    count
    outOfServiceCount
  }
}
`;