import gql from 'graphql-tag';

export default gql`
query getLoginInfo {
	me {
	id
    firstName
    lastName
    businessName
    features
    services
    roles
    accountType,
    planExpiration
  }
}
`;