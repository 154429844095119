import React from "react";
// reactstrap components
import {
 
  Card,

  CardBody,
  Modal,
  Table,
} from "reactstrap"; 

import moment from 'moment';

export default function OdometerHistoryModal({history,modalVisible,setModalVisible}) {


  return (
    <div>
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={modalVisible}
              toggle={() => setModalVisible(false)}
            >
              <div className="modal-body p-0">
                <Card className="shadow border-0">
                    <CardBody >
                      <Table>
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Reason</th>
                            <th>By</th>
                          </tr>
                        </thead>
                        <tbody>
                          {history?.map(({createdAt,changedBy,newMileage,previousMileage,notes},index) => 
                            <tr key={index}>
                              <td>{moment(createdAt).format("lll")}</td>
                              <td>{previousMileage.toLocaleString()}</td>
                              <td>{newMileage.toLocaleString()}</td>
                              <td>{notes}</td>
                              <td>{changedBy?.firstName} {changedBy?.lastName}</td>
                            </tr>
                          )}
                        </tbody>
                      </Table> 
                   </CardBody>
                </Card>
              </div>
            </Modal>

    </div>
  )
}
