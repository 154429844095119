import React from 'react';
import { Table, Spinner, Alert, Button} from 'reactstrap';
import { Link } from "react-router-dom";


import { useQuery,useMutation } from "@apollo/client";
import ServicePlans from "../../graphql/queries/ServicePlans";
import DeleteServicePlan from "../../graphql/mutations/DeleteServicePlan";

export default function ServicePlanTable({offset=0,limit=5,filterFields}) {
  const {data,loading,error} = useQuery(ServicePlans);
  const [deleteServicePlan] = useMutation(DeleteServicePlan,{
    refetchQueries:[{query:ServicePlans}]
  });

  const handleDelete = ({id,planName}) => {
    const confirm = window.confirm(`Are you sure that you want to delete ${planName}?`)

    if (!confirm) return;

    deleteServicePlan({variables:{id}})

  }


	if (loading) return <Spinner />;
	if (error) return <Alert danger>Error loading service plans</Alert>


	return (

                <Table>

                  <tbody>
                  	{data.servicePlans.map(({
                  		id,
                  		planName
                  	}) => {
                         return (
                          <tr key={id} >
                            <th scope="row">
                              <Link to={`/ServicePlan/${id}`}>{planName}</Link>
                              <Button className="ml-4" danger onClick={() => handleDelete({id,planName})}>Delete Plan</Button>
                            </th>

                          </tr>
                         )

                    }

                  	)}
                  </tbody>
                </Table>

	);
}