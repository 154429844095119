import gql from 'graphql-tag';

export default gql`
{
  totalInventoryValue {
    totalPartValue
    noStockSet
  	noCostCount
  }
}
`;