import React, {useState} from 'react';

import {Form, FormGroup, Input, Button ,Alert, Spinner, Row, Col} from 'reactstrap';

import {useMutation} from '@apollo/client';
import AddUdf from '../../graphql/mutations/AddUdf';
import UdfFields from '../../graphql/queries/UdfFields';

export default function AssetCustomFieldForm() {
	const [fieldName,setFieldName] = useState();
	const [errorMessage,setErrorMessage] = useState();

	const [addUdf,{loading}] = useMutation(AddUdf,{refetchQueries:[{query:UdfFields}]});

	const save = () => {
		setErrorMessage(null);
		if (!fieldName || fieldName.trim().length === 0) {
			setErrorMessage("Please enter a field name")
			return;
		}

		addUdf({variables:{fieldName}})
			.then(result => setFieldName(""))
			.catch(error => setErrorMessage(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the service. Please try again.`))
	}

	return (
		<Form onSubmit={e => e.preventDefault()} >
			{errorMessage && <Alert>{errorMessage}</Alert>}
			<Row>
				<Col md={9}>
					<FormGroup className="mb-0" >                          
						<Input placeholder="Field Name" value={fieldName || ""} type="text" onChange={(e) => setFieldName(e.target.value)} />
					</FormGroup>
				</Col>
				<Col md={3}>
					<Button block color="primary" onClick={() => save()} >
						Add Custom Field
						{loading && <Spinner size="sm" className="ml-2" />}
					</Button>
				</Col>
			</Row>
		</Form>
	);
}