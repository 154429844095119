import React, {useState} from 'react';


import {
	Container,
	Card,
	CardBody,
	Form,
	FormGroup,
	Row,
	Col,
	Label,
	Input,
	Button,
	ButtonGroup,
	Spinner,
	Table
} from 'reactstrap';



import Select from 'react-select'

import {useQuery,useMutation} from '@apollo/client';
import TruckTypes from "../graphql/queries/TruckTypes";
import AddInspectionTemplate from "../graphql/mutations/AddInspectionTemplate";
import InspectionTemplates from "../graphql/queries/InspectionTemplates";


import { useHistory } from "react-router-dom";


export default function InspectionTemplateNew({navigation}) {
	const history = useHistory();
  	const [formFieldValues,setFormFieldValues] = useState({
   			templateName:"",
   			appliesTo:[],
   			odometerRequired:true,
   			isPretrip: false
  	})

	const {data,loading} = useQuery(TruckTypes);
	const [addInspectionTemplate] = useMutation(AddInspectionTemplate,{
		// need to convert form field to an array of ids
		variables: {
			...formFieldValues,
		},
		refetchQueries:[{query:InspectionTemplates}],
		onCompleted: (result) => history.push(`/InspectionTemplate/${result.addInspectionTemplate.id}`),
		onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the template . Please try again.`)
	});

 
  	// simplify setting formFieldValues
  	const setValue =  ({name,value}) => {
  		
  		 setFormFieldValues({...formFieldValues,[name]:value})
  	}


  	const save = () => {
  		addInspectionTemplate()


  	}




  	if (loading) return <Spinner />

	return (


        <Container className="mt--8" fluid>
        	<h2>New Inspection Template</h2>
        	<p>
        		<strong>How are these templates used?</strong><br />
        		Inspection templates can be used for pre-trip inspections or as part of anoter inspection process that your company has in place. Selecting the "Pre-Trip" option will make a template available to drivers. 
        	</p>
            <Card className="shadow mb-4">
            	<CardBody>
					<FormGroup row>
						<Label sm={2}>*Template Name</Label>
						<Col sm={10}>
							<Input type="text" value={formFieldValues.templateName} onChange={e => setValue({name:"templateName",value:e.target.value})} />
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm={2}>What asset(s) is this template for?<br /><em>(Leave empty to apply to all)</em></Label>
						<Col sm={10}>
			                 <Select
							        defaultValue={formFieldValues.appliesTo}
							        isMulti
							        placeholder="Select assets that this is for...."
							        onChange={value => {

							        	// update appliesTo (value is an array of this field's values)
							        		// loop over trucks from value field. if in appliesTo then ignore. If not, then add
							        		const appliesTo = formFieldValues.appliesTo;
							        		value.forEach(v => {
							        			// if not found, add it
							        			if (!appliesTo.find(el => el === v.value)) {
							        				appliesTo.push(v.value)
							        			}
							        		})
							        		// remove anything that shouldn't be in appliesTo
							        		const appliesToClean = appliesTo.filter(a => value.find(v => v.value === a))
							        		setFormFieldValues({
							        			...formFieldValues,
							        			appliesTo: appliesToClean
							        		})
							        }}
							        options={data.truckTypes.map(truckType => ({label:truckType.type,value:truckType.type}))}
							      />

						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm={2}>Odometer Reading Required?</Label>
						<Col sm={10}>
						    <ButtonGroup >
						      <Button color="primary" outline={!formFieldValues.odometerRequired} onClick={() => setValue({name:"odometerRequired",value:true})}>Yes</Button>
						      <Button color="danger" outline={formFieldValues.odometerRequired} onClick={() => setValue({name:"odometerRequired",value:false})}>No</Button>
						    </ButtonGroup>
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm={2}>Is this a Pre-Trip template?</Label>
						<Col sm={10}>
						    <ButtonGroup >
						      <Button color="primary" outline={!formFieldValues.isPretrip} onClick={() => setValue({name:"isPretrip",value:true})}>Yes</Button>
						      <Button color="danger" outline={formFieldValues.isPretrip} onClick={() => setValue({name:"isPretrip",value:false})}>No</Button>
						    </ButtonGroup>
						</Col>
					</FormGroup>

			    	<div className="p-4 text-center">
			    		<Button onClick={() => save()} color="primary">Save Inspection Template</Button>
			    	</div>
				</CardBody>
			</Card>		
 	    </Container>


	)
}