import gql from "graphql-tag";



export default gql`
mutation SetForUser($id: Int!,$forUser: ID!,$forUserName: String!){	
	  setForUser(id:$id,forUser:$forUser,forUserName:$forUserName) {
	    id
	    partName
	    partNumber
	    vendor
	    quantityReceived
	    for_user
	    for_user_name


	  }
	
}
`;


