
import React, {useState} from "react";

import {useLazyQuery} from '@apollo/client';
import InventoryUsageReport from '../../graphql/queries/reports/InventoryUsageReport'; 
import InventoryUsageReport_PDF from '../../graphql/queries/reports/InventoryUsageReport_PDF'; 

// reactstrap components
import {
  Container,
  Label,
  Form,
  FormGroup,
  Button,
  Card,
  CardBody,
  Table,
  Spinner,
  Input
  
}
 from "reactstrap";

import moment from 'moment';
import DateTime from 'react-datetime';

export default function Reports() {
	const [reportType,setReportType] = useState("yesterday");
	const [startDate,setStartDate] = useState(moment().add(-1,'days'));
	const [endDate,setEndDate] = useState(moment().add(-1,'days'));

	const [inventoryUsageReport,{data,loading}] = useLazyQuery(InventoryUsageReport,{
		
	});
	const [inventoryUsageReport_PDF,{loading:pdfLoading}] = useLazyQuery(InventoryUsageReport_PDF,{
		fetchPolicy:'network-only',
		onCompleted: (result) => {
			window.open(result.inventoryUsageReport_PDF.url)
		},
		onError: () => alert("There was an error loading the PDF. Please try again.")
	});

	const runReport = () => {
		
		if (!startDate || !endDate) {
			alert("Please enter a start date and an end date")
			return;
		}

		inventoryUsageReport({variables: {
			startDate,
			endDate
		}});
	}

	const handleReportType = (e) => {
		const rType = e.target.value;
		setReportType(rType);
		if (rType === 'today') {
			setStartDate(moment().startOf('day'))
			setEndDate(moment().startOf('day'))
		}
		else if (rType === 'yesterday') {
			setStartDate(moment().add(-1,'days').startOf('day'))
			setEndDate(moment().add(-1,'days').startOf('day'))
		}
		else if (rType === 'thisMonth') {
			setStartDate(moment().startOf('month'))
			setEndDate(moment().endOf('month'))
		}
	}

	return (
	      <Container  fluid>
          <h2>
            Parts Usage Report
          </h2>
          <Card>
          	<CardBody>
          <Form inline>
          	<FormGroup className="mr-2">
          		
				<Input type="select" value={reportType} onChange={handleReportType}>
					<option value="today">Today</option>
					<option value="yesterday">Yesterday</option>
					<option value="thisMonth">This Month</option>
					<option value="custom">Custom</option>
				</Input>
			</FormGroup>
			{reportType === "custom" &&
		          	<FormGroup className="mr-2">
		          		<Label className="mr-1">Start Date:</Label>
						<DateTime inputProps={{style:{maxWidth: 200}}}  placeholder="Start Date" timeFormat={false} value={startDate} onChange={date => setStartDate(date)} />
					</FormGroup>
			}
			{reportType === "custom" &&
		          	<FormGroup className="mr-2">
		          		<Label className="mr-1">End Date: </Label>
						<DateTime style={{maxWidth: 200}} placeholder="End Date" timeFormat={false} value={endDate} onChange={date => setEndDate(date)} />
					</FormGroup>
				
			}
			<Button color="primary" onClick={() => runReport()}>
				Run Report
				{loading && <Spinner size="sm" />}
			</Button>
			{data?.inventoryUsageReport && 
				<Button className="ml-2" color="primary" onClick={() => inventoryUsageReport_PDF({variables:{startDate,endDate}})}>
					Export to PDF
					{pdfLoading && <Spinner size="sm" />}
				</Button>}
		  </Form>
		  	</CardBody>
		  </Card>

		  {data?.inventoryUsageReport &&
			  <Card>
			  	<CardBody>
			  		<Table>
			  			<thead>
			  				<tr>
			  					<th>Part</th>
			  					<th>Vendor</th>
			  					<th>Part #</th>
			  					<th>Total Pulled From Inventory</th>
			  					<th>Total Returned/Added To Inventory</th>
			  					<th>Total Used</th>
			  				</tr>
			  			</thead>
			  			<tbody>
			  				{data.inventoryUsageReport.map(({partName,vendor,partNumber,totalAdded,totalRemoved}) => 
				  				<tr>
				  					<td>{partName}</td>
				  					<td>{vendor}</td>
				  					<td>{partNumber}</td>
				  					<td className="text-center">{totalRemoved}</td>
				  					<td className="text-center">{totalAdded}</td>
				  					<td className="text-center">{totalRemoved-totalAdded}</td>
				  				</tr>

			  				)}
			  			</tbody>
			  		</Table>
			  	</CardBody>
			  </Card>
		  }
          
        </Container>
    

      );

}
