import gql from 'graphql-tag';

export default gql`
mutation RemoveUdf($fieldName: String!)  {
  removeUdf (fieldName: $fieldName) {
  	id,
  	fieldName,
  	sortOrder
  }
}
`;

