import React from 'react';

import {Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';

import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'


export default function SearchInput({placeholder="Keyword or Ticket Number",searchKeyword}) {
	return (
		<Form onSubmit={e => e.preventDefault()} className="navbar-search navbar-search-light form-inline  d-none d-md-flex ml-lg-1">
			<FormGroup className="w-100">
				<InputGroup className="input-group-alternative w-100">
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
							<Icon path={mdiMagnify} size={1} />
						</InputGroupText>
					</InputGroupAddon>
					<Input placeholder={placeholder} type="text" onChange={(e) => searchKeyword(e.target.value)} />
				</InputGroup>
			</FormGroup>
		</Form>
	);
}