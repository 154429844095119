import React, {useState} from 'react';


import {
	Container,
	Card,
	Form,
	FormGroup,
	Row,
	Col,
	Label,
	Input,
	Button,
	Spinner,
	Table
} from 'reactstrap';
// core components


import Select from 'react-select'

import {useQuery,useMutation} from '@apollo/client';
import Trucks from "../graphql/queries/Trucks";
import AddPermit from "../graphql/mutations/AddPermit";
import Permits from "../graphql/queries/Permits";

import moment from 'moment';
import { useHistory } from "react-router-dom";


export default function PermitNew() {
	const history = useHistory();
  	const [formFieldValues,setFormFieldValues] = useState({
   			permitName:"",
   			renewalSchedule: null,
   			trucks:[],
   			assignedTo:[],
   			//startDate:"",
   			//renewalDate:""
  	})

	const {data,loading} = useQuery(Trucks);
	const [addPermit] = useMutation(AddPermit,{
		// need to convert form field to an array of ids
		variables: {
			...formFieldValues,
			startDate: null,
			renewalDate: null,
			//trucks:formFieldValues.trucks ? formFieldValues.trucks.map(el => el.value) : [],
			assignedTo: formFieldValues.assignedTo.map(el => ({truck:el.truck,startDate:new Date(el.startDate),renewalDate: new Date(el.renewalDate)}))
		},
		refetchQueries:[{query:Permits}],
		onCompleted: () => history.goBack() ,
		onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the permit . Please try again.`)
	});


  	// if permit covers all vehicles, this will be set to true. If permit goes into effect at different times, then false
  	const [sameDates,setSameDates] = useState(true);

 
  	// simplify setting formFieldValues
  	const setValue =  ({name,value}) => {
  		
  		 setFormFieldValues({...formFieldValues,[name]:value})
  	}

	// if renewal date is empty, set based on selected schedule
	const getRenewalDate = (renewalSchedule) => {
		//const { startDate } = formFieldValues;
		const startDate = new Date();
		let newRenewalDate;
		switch(renewalSchedule) {
			case 'Monthly': 
				newRenewalDate = moment(startDate).add(1,'months');
				break;
			case 'Quarterly': 
				newRenewalDate = moment(startDate).add(3,'months');
				break;
			case 'Annually': 
				newRenewalDate = moment(startDate).add(1,'years');
				break;
			default:
				newRenewalDate = moment(startDate).add(1,'months');
				break;
		}

		return newRenewalDate.format('YYYY-MM-DD');
	}

	const setDateDefaults =  (e) => {
		const startDate = moment().format('YYYY-MM-DD')
		const renewalDate = getRenewalDate(e.target.value)
		// can't use setValues becuase it works async and won't reliably update variables
		setFormFieldValues({
			...formFieldValues,
			renewalSchedule:e.target.value,
			startDate,
			renewalDate,
			assignedTo: formFieldValues.assignedTo.map(a => ({...a,startDate,renewalDate }))
		})

	}


  	if (loading) return <Spinner />

	return (


        <Container className="mt--8" fluid>
 		 <Row>
		 <Col className="mb-5 mb-xl-0" xl="12">

			<Card className="p-2" style={{ backgroundColor: "#e2e2e2"}}>
	          <Form  style={{marginBottom: 20}}>
	          	<Card className="p-2 mb-4">
	          		{/* Get permite name */}
		            <FormGroup row>
		          		<Label sm={2}>*Permit Name</Label>
		          		<Col sm={10}>
					          <Input type="text" value={formFieldValues.permitName} onChange={e => setValue({name:"permitName",value:e.target.value})} />
		              	</Col>
		            </FormGroup>
		        	{/* Get assets once name is entered*/}
		            {formFieldValues.permitName && 
		            	<FormGroup row>
			          		<Label sm={2}>*Assets</Label>
			          		<Col sm={10}>
			                 <Select
							        defaultValue={formFieldValues.trucks}
							        isMulti
							        placeholder="Select Equipment Tied to Permit...."
							        onChange={value => {
							        	// add to trucks for now (may not be needed)
							        	setValue({name:'trucks',value})
							        	// update assignedTo (value is an array of this field's values)
							        		// loop over trucks from value field. if in assignedTo then ignore. If not, then add
							        		const assignedTo = formFieldValues.assignedTo;
							        		value.forEach(v => {
							        			// if not found, add it
							        			if (!assignedTo.find(el => el.truck === v.value)) {
							        				assignedTo.push({identifier:v.label,truck:v.value,startDate:formFieldValues.startDate,renewalDate:formFieldValues.renewalDate})
							        			}
							        		})
							        		// remove anything that shouldn't be in assignedTo
							        		const assignedToClean = assignedTo.filter(a => value.find(v => v.value === a.truck))
							        		setFormFieldValues({
							        			...formFieldValues,
							        			assignedTo: assignedToClean
							        		})
							        }}
							        options={data.trucks.map(({identifier,id}) => ({label:identifier,value:id}))}
							      />

			              	</Col>
			            </FormGroup>
			        }
		        	{/* don't show this until at least 1 truck is selected */}
		            {formFieldValues.assignedTo && formFieldValues.assignedTo.length > 0 && 
			            <FormGroup row>
			          		<Label sm={2}>*Permit Renews...</Label>
			          		<Col sm={10}>
				                <Input type="select" onChange={e => {

									setDateDefaults(e)
									

				                }}>
				                	<option>Select Renewal Schedule...</option>
				                	<option value="Annually">Annually</option>
				                	<option value="Quarterly">Quarterly</option>
				                	<option value="Monthly">Monthly</option>
				                </Input>
				            </Col>
			            </FormGroup>
		            }

		        	{/* if multiple vehicles, assume all dates are shared. First row has form fields and rest display date. */}
		        	{formFieldValues.assignedTo && formFieldValues.assignedTo.length > 1 &&
				      <FormGroup >
				        Does all equipment share the same start and expiration dates?
				        <FormGroup check>
				          <Label check>
				            <Input type="radio" name="radio1" checked={sameDates} onChange={(e) => setSameDates(e.target.checked)} />{' '}
				            Yes
				          </Label>
				        </FormGroup>
				        <FormGroup check>
				          <Label check>
				            <Input type="radio" name="radio1" checked={!sameDates} onChange={(e) => setSameDates(!e.target.checked)}  />{' '}
				            No
				          </Label>
				        </FormGroup>

				      </FormGroup>
	        	

		        	}

		        	{/* for each truck, show start date and end date assume same date. create switch to change if more than 1 */}
		        	{formFieldValues.assignedTo && formFieldValues.assignedTo.length > 0 && formFieldValues.renewalSchedule &&
						<Table>
							<thead>
								<tr>
									<th>Equipment</th>
									<th>Permit Start</th>
									<th>Permit Renewal</th>
								</tr>
							</thead>
							<tbody>	
								{formFieldValues.assignedTo.map(({identifier,startDate,renewalDate},index) =>
									<tr key={index}>
										<td>{identifier}</td>
										{/* only show input for first assignedTo if samedates is true*/}

										<td>
											{(index === 0 || sameDates === false) ? 
												<Input type="date" value={startDate || ""} onChange={e => {
														// if same date and index===0, set all assignedTos
														if (sameDates && index === 0) {
															setValue({name:"assignedTo",value: formFieldValues.assignedTo.map(el => ({...el,startDate:e.target.value}))})
														}
														// else, just set this one
														else {													
															let newAssignedTo = formFieldValues.assignedTo;
															newAssignedTo[index].startDate = e.target.value;
															setValue({name:"assignedTo",value:newAssignedTo})
														}
													}} />
												:
												moment(startDate).format("M/D/YYYY")
											}
										</td>
										<td>
											{(index === 0 || sameDates === false) ? 
												<Input type="date" value={renewalDate || ""} onChange={e => {
													// if same date and index===0, set all assignedTos
													if (sameDates && index === 0) {
														setValue({name:"assignedTo",value: formFieldValues.assignedTo.map(el => ({...el,renewalDate:e.target.value}))})
													}

													// else, just set this one
													else {
														let newAssignedTo = formFieldValues.assignedTo;
														newAssignedTo[index].renewalDate = e.target.value;
														setValue({name:"assignedTo",value:newAssignedTo})													
													}
												}} />
												:
												moment(renewalDate).format("M/D/YYYY")
												
											}
										</td>
									</tr>
								)}
							</tbody>
						</Table>	        		
		        	}

		        	{formFieldValues.assignedTo && formFieldValues.assignedTo.length > 0 && formFieldValues.renewalSchedule &&
							<Button onClick={() => addPermit()} color="primary">
								Save Permit
							</Button>
		        	}


					
					{/*sameDates !== null && formFieldValues.permitName && formFieldValues.renewalSchedule  && 
							<Button onClick={() => addPermit()} color="primary">
								Save Permit
							</Button>

						*/}
					



	          	</Card>
	          </Form>
	        </Card>

	     </Col>
	     </Row>
	    </Container>


	)
}