import React, {useState, useEffect,} from 'react';

import {  Alert, Spinner, Form, FormGroup, Input, Label, Row, Col, Button  } from 'reactstrap';

import {useHistory} from 'react-router-dom';

import { useMutation } from '@apollo/client';
import SetNewPassword from '../../graphql/mutations/auth/SetNewPassword';



import Logo from "../../assets/images/Logo.png";

const getQueryStringValue = (key) => {  
   return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[.+*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));  
} 

export default function SetNewPasswordScreen() {
	const history = useHistory();
	const [password,setPassword] = useState();

	const [errorMessage,setErrorMessage] = useState();


	const [setNewPassword,{loading}] = useMutation(SetNewPassword, {
	onCompleted:  result => {
		alert("Password Updated Successfully");
		history.push('/')
	},
	onError: error => {
	        //console.log("error",error)
	        // loading toast blocks header from being clicked
	        const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error resetting your password. Please try again.'
	        setErrorMessage(message);
	}
	})


	useEffect(() => {
		// when leaving page, reset variables
		return () => {
			setPassword(null);
			
		}
	},[])



	return (
			<Row>
				<Col md={{size: 7, offset:2}} className="text-center p-2 pt-4 mt-5" style={{background: "#ffffff"}}>
			      <div className="text-center mb-0 mt-5">
			        <img className="mb-2" src={Logo} alt="Fleet Command - Dispatch" style={{width:250,height: 145}}/>
			      </div>   
   					<Form className="text-left" onSubmit={e => {e.preventDefault();e.stopPropagation()}}>
						{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
						<FormGroup>
							<Label>New Password</Label>
							<Input type="password" onChange={e => setPassword(e.target.value)} />
						</FormGroup>
						<Button block color="info" onClick={() => {
							const token = getQueryStringValue("token");
							setNewPassword({variables:{newPassword:password,token}})
						}}>
							Update Password
							{loading && <Spinner />}
						</Button>
					</Form>

				</Col>
			</Row>

	);
}