import gql from 'graphql-tag';

export default gql`
mutation RemoveEmailNotification($id: ID!, $notification: String!) {
  removeEmailNotification(id:$id,notification:$notification) {
    id
    firstName
    lastName
    emailNotifications
  }
}


`;