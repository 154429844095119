import gql from 'graphql-tag';

  

export default gql`
mutation ReceivePOItem(
    $poId: Int!,
    $poItemId: Int!,
    $quantityReceived: Float!
) {
  receivePOItem(
    poId: $poId,
    poItemId: $poItemId,
    quantityReceived: $quantityReceived  
    ) {

    poDate
    poNumber
    supplier {
      id
      supplierName
    }
    items {
      id
      partName
      partNumber
      vendor
      quantityOrdered
      quantityReceived
      dateReceived
    }
      
    


  }
}

`;


