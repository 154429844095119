import gql from 'graphql-tag';

import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
mutation CorrectOdometer($id:ID!,$newMileage:Float!,$notes: String!) {
	correctOdometer(id:$id,newMileage:$newMileage,notes:$notes) {
		...TruckAllFields
	}
}
${TRUCK_FRAGMENT}
`;


