import React,{useState, useEffect} from 'react';
import {Modal, ModalHeader, ModalFooter, ModalBody,Spinner, Form, FormGroup, Label, Input, Button} from 'reactstrap';

import {useMutation} from '@apollo/client';
import AddSupplier from '../../graphql/mutations/AddSupplier';
import Suppliers from '../../graphql/queries/Suppliers';

// need to build an extendable modal
export default function AddSupplierModal({supplierName,modalVisible,setModalVisible,onSupplierAdded}) {
  // form values
  const [formFieldValues,setFormFieldValues] = useState();

    useEffect(() => {
      if (!supplierName) return;

      setFormFieldValues({supplierName});
    },[supplierName])


  const [addSupplier,{loading}] = useMutation(AddSupplier,{
    refetchQueries: [{query:Suppliers}],
    onCompleted: (result) => {
    	
    	if (onSupplierAdded) onSupplierAdded(result.addSupplier)
    	setModalVisible(false)
    },
    onError: (error) => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding supplier . Please try again.`)
  })

  //if (!user) return null;
    // simplify setting formFieldValues
    const setValue = ({name,value}) => {
      setFormFieldValues({...formFieldValues,[name]:value})
    }


  return (
      <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add Supplier {loading && <Spinner />}</ModalHeader>
        <ModalBody>
            <Form  style={{marginBottom: 20,background: "none"}}>
                <FormGroup >
                  <Label ><strong>Supplier Name (required)</strong></Label>
                  
                    <Input  
                         placeholder="Supplier Name" 
                         value={formFieldValues?.supplierName || ""}
                         onChange={e => setValue({name:"supplierName",value:e.target.value})}
                         />
                  
                </FormGroup>
            </Form>

        </ModalBody>
        <ModalFooter>
            <Button onClick={() => addSupplier({variables:formFieldValues})}>
              Add Supplier
            </Button>
        </ModalFooter>
      </Modal>

  );





}




