import gql from 'graphql-tag';
import { INSPECTION_FRAGMENT } from '../fragments/InspectionFragments';



export default gql`
query InspectionTemplate($id: ID!) {
  inspectionTemplate(id:$id) {
    ...InspectionAllFields
  }
}
${INSPECTION_FRAGMENT}
`;