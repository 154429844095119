import React, {useState,useEffect, useContext} from "react";

// reactstrap components
import {

  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  CustomInput
}
 from "reactstrap";

import { throttle } from 'throttle-debounce';

import EquipmentTable from "../components/Assets/EquipmentTable.js";
import TypeFilterButton from "../components/Assets/TypeFilterButton.js";
import LocationFilterButton from "../components/Assets/LocationFilterButton.js";
import SearchInput from "../components/WorkOrders/SearchInput.js";
import AuthSection from '../components/AuthSection';

import AuthContext from "../contexts/AuthContext";

import {Link} from 'react-router-dom';



export default function Equipment() {
	const limit = 20;

  const {features} = useContext(AuthContext);

  const [filterFields,setFilterFields] = useState({});
  const [keyword,setKeyword] = useState({});
	const [offset] = useState(0);

    // set card height for bottom cards to window minus 60 (padding at top) minus 140 (for rows above table) minus 6 just for spacing
    //const bottomCardHeight = window.innerHeight - 80-6-140;
  const [bottomCardHeight,setBottomCardHeight] = useState(window.innerHeight - 80-6-140);
  

  useEffect(() => {
    function handleResize() {
      setBottomCardHeight(window.innerHeight - 80-6-140)
    }
    window.addEventListener('resize', handleResize)
  })



  const filterAssets = throttle(1000,false,(keyword) => {
    setKeyword(keyword)
  })



	return (

        <Container fluid>
          <h2>Assets
              <AuthSection>
                      <Link to="/Asset/New" className="ml-4">
                        <Button color="primary">
                          Add Asset
                        </Button>
                      </Link>
              </AuthSection>
          </h2>

          <Row className="mb-2">
            <Col>
              <SearchInput searchKeyword={filterAssets} placeholder="Keyword..." />
            </Col>
          </Row> 
          <Row className="mb-2">
            <Col>
              <Form inline className="float-left">
                <TypeFilterButton onChange={(types) => setFilterFields({...filterFields,types})} />
                {(features?.indexOf("locationTracking") >= 0) &&
                  <LocationFilterButton onChange={(location) => setFilterFields({...filterFields,location})} />
                }
                <FormGroup className="p-2">
                  <CustomInput type="switch" 
                       id="ExpiringToggle"
                       label="Expiring Items"
                       checked={filterFields.expiringFedAnnual === true}
                       onChange={e => setFilterFields({...filterFields,expiringFedAnnual:e.target.checked})}
                       />
                </FormGroup> 
              </Form>

            </Col>

          </Row>


          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">

              		<EquipmentTable offset={offset} 
              						 limit={limit}
                           keyword={keyword}
              						 filterFields={filterFields}
                           cardHeight={bottomCardHeight}
              				 />

            </Col>
 
          </Row>
        </Container>
  
      );

}
