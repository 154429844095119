import gql from "graphql-tag";



export default gql`
{	
	  pendingMorningRushInventory {
	    id
	    partName
	    partNumber
	    vendor
	    quantityReceived
	    for_user
	    for_user_name


	  }
	
}
`;
