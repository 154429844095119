import gql from 'graphql-tag';

export default gql`
mutation AddEmailNotification($id: ID!, $notification: String!) {
  addEmailNotification(id:$id,notification:$notification) {
    id
    firstName
    lastName
    emailNotifications
  }
}
`;