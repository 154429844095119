import gql from 'graphql-tag';
import { SERVICE_PLAN_FRAGMENT } from '../fragments/ServicePlanFragments';


export default gql`
mutation DeleteServicePlanService($id: ID!,$serviceId: ID!) {

  deleteServicePlanService(id:$id,serviceId:$serviceId) {
    ...ServicePlanAllFields
  }
}
${SERVICE_PLAN_FRAGMENT}
`;