import gql from 'graphql-tag';

export default gql`
query ServicePlanHistory($id: ID!) {
  servicePlanHistory(id:$id) {
    id
    lastServiceDate
    units
    description
    trackingMeasurement,
    measurementUnits
    planActive
  }
}
`;