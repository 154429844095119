
import  React, {useState, useEffect} from 'react';

import {Container, Card, CardHeader, CardBody, CardTitle} from 'reactstrap';
import PlanCard from '../../components/Payments/PlanCard';
import moment from 'moment';

import { useQuery, useMutation } from '@apollo/client';
import PaymentPlans from '../../graphql/queries/PaymentPlans';
import ActiveEquipmentCount from '../../graphql/queries/ActiveEquipmentCount';
import CurrentPaymentPlan from '../../graphql/queries/CurrentPaymentPlan';
import PaymentPortalSession from '../../graphql/mutations/GeneratePaymentPortalSession';
import GenerateUpgradeURL from '../../graphql/mutations/GenerateUpgradeURL';
import GenerateSignUpURL from '../../graphql/mutations/GenerateSignUpURL';
import SignupComplete from '../../graphql/mutations/SignupComplete';

export default function ChargeBeePortal() {
	const [salesResult,setSalesResult] = useState();
	

	const {data:dataPlans} = useQuery(PaymentPlans);
	const {data:dataEquipment} = useQuery(ActiveEquipmentCount);
	const {data:dataCurrentPlan} = useQuery(CurrentPaymentPlan);
	const [generateUpgradeURL] = useMutation(GenerateUpgradeURL);
	const [generateSignupURL] = useMutation(GenerateSignUpURL);
	const [signupComplete] = useMutation(SignupComplete);

	const [getPaymentPortalSession] = useMutation(PaymentPortalSession);



	

	useEffect(() => {
		/*Chargebee.init({
			site:'truckmanager-test'
		});*/

		window.Chargebee.registerAgain();


	},[])



	const openPortal = () => {
		let chargebeeInstance = window.Chargebee.getInstance();
		chargebeeInstance.setPortalSession(function() {
			return new Promise((resolve,reject) => {
				getPaymentPortalSession()
					.then(result => resolve(JSON.parse(result.data.PaymentPortalSession)))
			})
		})
		let cbPortal = chargebeeInstance.createChargebeePortal();
		cbPortal.open({
		  close() {
		    //close callbacks
		    chargebeeInstance.logout();
		  }
		});





	}

	const openCheckout_signup = (plan_id) => {
			//const setState = this.updateState;
			//const getUserInfo = this.props.getUserInfo;

			let chargebeeInstance = window.Chargebee.getInstance();
			chargebeeInstance.openCheckout({          
			  // This function returns a promise that resolves a hosted page object.
			  // If the library that you use for making ajax calls, can return a promise, you can directly return that

			  hostedPage: function() {
			    // We will discuss on how to implement this end point in the next step.
				    return new Promise((resolve,reject) => {
				    	generateSignupURL({variables:{plan_id}})
				    		.then(result => {

				    			console.log("result",result.data.generateSignUpURL)
				    			resolve(JSON.parse(result.data.generateSignUpURL))
				    		})
				    })
			  },

			  success: function(hostedPageId) {
			    // success callback
				    return new Promise((resolve,reject) => {
				    	signupComplete({variables:{hostedPageId}})
				    		.then(result => resolve())
				    })

			    //return axios.post('/payments/signUpComplete',{hostedPageId})
			    	//.then(response => setState({salesResult:response.data}))
			    	//.then(() => getUserInfo())
			  },

			  error: (e) => console.log("error",e)
			})

	}



	const openCheckout = (plan_id) => {
		
		let cbInstance = window.Chargebee.getInstance();

		cbInstance.openCheckout({
		  hostedPage: () => {
		    // Hit your end point that returns hosted page object as response
		    // This sample end point will call checkout new api
		    // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
		    // If you want to use paypal, go cardless and plaid, pass embed parameter as false
		    return new Promise((resolve,reject) => {
		    	generateUpgradeURL({variables:{plan_id}})
		    		.then(result => resolve(result.generateUpgradeURL))
		    })
		   // return axios.post("/payments/generate_upgrade_url", {plan_id}).then((response) => response.data)
		  },
		  success(hostedPageId) {
			    // success callback
			    signupComplete({variables:{hostedPageId}})
			    
			    	.then(response => setSalesResult(response.signupComplete))
		  },
		  close:() => {
		    //this.setState({loading: false});
		    console.log("checkout new closed");
		  },
		  step(step) {
		    console.log("checkout", step);
		  }
		})		
	}



		
		return (
			<Container>
			

				<Card>
					<CardBody>
						<button className="btn btn-primary mr-4" onClick={() => openPortal()}>Update Billing Information</button>
						Update Credit Card, View Billing History, Cancel Subscription
					</CardBody>
				</Card>

				<Card>
					<CardHeader><CardTitle>Change Subscription Plan</CardTitle></CardHeader>
					<CardBody>
						{salesResult && 
							<div className="alert alert-info">
								Your subscription has been updated. You will be billed ${salesResult.amount} every {salesResult.interval} starting {moment(salesResult.start_date).format('M/D/YYYY')}
							</div>
						}
						{dataPlans?.paymentPlans.map(plan => <PlanCard key={plan.planId}
																	   plan={plan}
																	   openCheckout={dataCurrentPlan?.currentPaymentPlan ? openCheckout : openCheckout_signup} 
																	   activeEquipmentCount={dataEquipment?.activeEquipmentCount} 
																	   isCurrentPlan={dataCurrentPlan?.currentPaymentPlan === plan.planId}
																	   />
														)}


						
					</CardBody>
				</Card>


				
			</Container>
		);

	
}




