import gql from 'graphql-tag';

export default gql`
{
  suppliers {
    id
    supplierName
    phones {
      type
      number
    }

  }
}
`;