import React, { useState } from 'react';
import EmployeeForm from '../components/People/EmployeeForm';

import {Spinner} from 'reactstrap';

import { useMutation } from '@apollo/client';
import AddEmployee from '../graphql/mutations/AddEmployee';
import SendPasswordModal from '../components/People/SendPasswordModal';
import { useHistory } from "react-router-dom";


function NewEmployeeScreen() {
	
	const history = useHistory();

	const [user,setUser] = useState({});
	const [modalVisible_SendPassword,setModalVisible_SendPassword] = useState(false);
	const [addEmployee,{loading}] = useMutation(AddEmployee,{
		//refetchQueries:[{query:Employees}],
		onCompleted: (result) => {
			
			setUser(result.addDriver)
			setModalVisible_SendPassword(true);
			//history.goBack()
		},
		onError: error => {
				alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the employee . Please try again.`);

			}

	})


  


  	// save function. putting it here so view can take advantage of loading var of mutation
	const saveEmployee = async (variables) => {
		addEmployee({variables})
	} 



	return (
			<>
				{loading && <div className="h-100 w-100 text-center" style={{padding: "50%",position:"absolute",zIndex: 100,background:"rgba(0,0,0,.5)"}}><Spinner /></div>}
				<EmployeeForm  title="New" saveEmployee={saveEmployee} />
				<SendPasswordModal
								  user={user} 
								  modalVisible={modalVisible_SendPassword} 
								  setModalVisible={() => {setModalVisible_SendPassword(false);history.goBack()}}
								  />
			</>
		   )


}



export default NewEmployeeScreen;
