import gql from 'graphql-tag';

export default gql`
mutation DecreaseInventory(
    $id: Int!,
    $quantity: Float!,
    $note: String,
    $ticketNumber: Int
) {
  decreaseInventory(
    id: $id,
    quantity: $quantity,
    note: $note ,
    ticketNumber: $ticketNumber 
    ) {

        id
        partName
        numberInStock
        warningThreshold
        inventoryItems {
          id
          partNumber
          vendor
          numberInStock
          barcodeType
          barcodeData
          partDescription
          
    }


  }
}

`;


