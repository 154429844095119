// gqlFragments.js
import gql from 'graphql-tag';

export const SERVICE_PLAN_FRAGMENT = gql`
    fragment ServicePlanAllFields on ServicePlan {
      id
      planName
      measurementType
      serviceSchedule {
        id
        service
        units
        trackingMeasurement
      }

    }
`;