import React, {useState} from 'react';

import {Form, FormGroup, Input, Button ,Alert, Spinner} from 'reactstrap';

import {useMutation} from '@apollo/client';
import AddTruckType from '../../graphql/mutations/AddTruckType';
import TruckTypes from '../../graphql/queries/TruckTypes';

export default function AssetTypeForm() {
	const [type,setType] = useState();
	const [measure,setMeasure] = useState("miles");
	const [errorMessage,setErrorMessage] = useState();

	const [addTruckType,{loading}] = useMutation(AddTruckType,{refetchQueries:[{query:TruckTypes}]});

	const save = () => {
		setErrorMessage(null);
		if (!type || type.trim().length === 0) {
			setErrorMessage("Please enter an asset type")
			return;
		}

		addTruckType({variables:{type,measure}})
			.then(result => {
				setType("")
				setMeasure("miles")
			})
			.catch(error => setErrorMessage(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the asset type. Please try again.`))
	}

	return (
		<Form onSubmit={e => e.preventDefault()} >
			{errorMessage && <Alert>{errorMessage}</Alert>}

					<FormGroup className="mb-0" >                          
						<Input placeholder="Asset Type" value={type || ""} type="text" onChange={(e) => setType(e.target.value)} />
					</FormGroup>
					<FormGroup className="mb-0" >                          
						<Input type="select" value={measure || ""}  onChange={(e) => setMeasure(e.target.value)}>
							<option value="miles">miles</option>
							<option value="hours">hours</option>
						</Input>
					</FormGroup>
                        
						<Button block color="primary" onClick={() => save()} >
							Add Asset Type
							{loading && <Spinner size="sm" className="ml-2" />}
						</Button>


		</Form>
	);
}