import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';


export default gql`
mutation CheckIn($id:ID!, $mileageIn: Float!, $assignedTo: ID) {
  checkIn(id: $id,mileageIn:$mileageIn, assignedTo:$assignedTo) {
    ...WorkOrderAllFields
  }
}
${WORK_ORDER_FRAGMENT}
`;