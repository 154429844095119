import React, {useEffect} from 'react';
import { Table, Alert} from 'reactstrap';
import { Link } from "react-router-dom";


import { useQuery } from '@apollo/client';
import Inventory from '../../graphql/queries/Inventory'

export default function InventoryTable({offset=0,limit=20,filterFields}) {
	const {data,loading,error,refetch} = useQuery(Inventory,{
      variables: {
        ...filterFields,
        offset,
        limit
      }
    });

  useEffect(() => {
    refetch()
  },[filterFields,refetch])

	if (loading) return null;
	if (error) return <Alert danger>Error loading inventory</Alert>

	return (
        <div className="table-responsive h-100">
                <Table className="align-items-center table-flush"  >

                
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Part Name</th>
                      <th scope="col">Location</th>
                      <th scope="col"># in Stock</th>
                      <th scope="col">Value ($)</th>
                      <th scope="col">Alert Threshold</th>
                      <th scope="col">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                  	{data.inventory.map(({
                  		id,
                  		partName,
                      numberInStock,
                      value,
                      location,
                      warningThreshold,
                      category
                  	}) => {
                        const theStyle = (numberInStock && warningThreshold && !isNaN(numberInStock) && !isNaN(warningThreshold) && numberInStock <= warningThreshold) ? {backgroundColor:'red',color:"#ffffff"} : {}

                        return (
                          <tr key={id} style={theStyle}>
                            <th scope="row">

                              <Link style={theStyle} to={`/Inventory/${id}`}>{partName}</Link>
                              
                            </th>
                            <td>{location}</td>
                            <td>{numberInStock}</td>
                            <td>${value || 'N/A'}</td>
                            <td>{warningThreshold}</td>
                            <td>{category?.category || ""}</td>
                          </tr>
                         )

                    }

                  	)}
                  </tbody>
                </Table>
                {data?.inventory?.length === 0 && <div className="ml-2 text-muted">No inventory found</div>}
               </div>

	);
}