import gql from 'graphql-tag';

export default gql`
query Trucks($location: ID,$types:[String],$expiringFedAnnual: Boolean) {
  trucks(location:$location,types:$types,expiringFedAnnual:$expiringFedAnnual) {
    id
    identifier
    type
    lastReportedMileage
    year
    make
    model
    OpenWorkOrders
	  OutOfService,
    servicePlan {
      id
    }

    federalAnnualInspectionDueDate,
    registrationDueDate

  }
}
`;