import gql from 'graphql-tag';

export default gql`
mutation AddPartFromVendor($inventoryId:Int!,$partNumber:String,$partDescription:String,$vendor:String,$cost:Float,$numberInStock:Float$barcodeData:String,$barcodeType:String){
  addSubPart(inventoryId: $inventoryId,
    partNumber:$partNumber,
    partDescription:$partDescription,
    vendor:$vendor,
    cost:$cost,
    numberInStock:$numberInStock,
    barcodeType:$barcodeType,
    barcodeData:$barcodeData
  ) {
    id
    partName
    numberInStock
    warningThreshold
    inventoryItems {
      id
      partNumber
      partDescription
      vendor
      numberInStock
      barcodeType
      barcodeData
      
    }

  }
}
`;
