import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert, Form, FormGroup, Label, Col, Input,ListGroup,ListGroupItem} from 'reactstrap';

import { useMutation } from '@apollo/client';
import DecreaseInventory from '../../graphql/mutations/DecreaseInventory';

export default function RemoveInventoryModal({item,partName,modalVisible,setModalVisible}) {

	  const {
	    id,
	    vendor,
	    partNumber,
	    partDescription
	  } = item;

	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});

	const [decreaseInventory,{loading}] = useMutation(DecreaseInventory, {
		//refetchQueries:[{query:InventoryItem,variables: {}}]
	});



	// clear form on modal visibility change
	useEffect(() => {
		setFormVals({})
	},[modalVisible])


	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}



	const save = ():void => {
		// reset error messages
		setErrors([]);
		// validate

			if (!formVals.quantity || isNaN(formVals.quantity)) {
				errors.push("Please enter a number for quantity");
			}
			if (errors.length > 0) {
				return setErrors(errors)
			} 

			decreaseInventory({variables:{
							...formVals,
							quantity: parseFloat(formVals.quantity),
							id:parseInt(id),
							ticketNumber: formVals.ticketNumber ? parseInt(formVals.ticketNumber) : null
						}})
			.then(result => {
				setModalVisible(false);
			})
			.catch(error => {
				
				setErrors([error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error updating inventory. Please try again.`]);
			})
		}


  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Pull from Inventory</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
		    <ListGroup>
		      <ListGroupItem><strong>Part:</strong> {partName}</ListGroupItem>
		      <ListGroupItem><strong>Vendor:</strong> {vendor}</ListGroupItem>
		      <ListGroupItem><strong>Part Description:</strong> {partDescription}</ListGroupItem>
		      <ListGroupItem><strong>Part Number:</strong> {partNumber ? partNumber : "n/a"}</ListGroupItem>

		    </ListGroup>

	        <Form>
	            <FormGroup >
	          		<Label>Quantity</Label>
	          		<Col>
				       <Input type="number" value={formVals.quantity || ""} onChange={e => setFormValue({field:"quantity",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Remove Inventory Notes</Label>
	          		<Col>
				       <Input type="textarea" value={formVals.note || ""} onChange={e => setFormValue({field:"note",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Ticket Number (optional)</Label>
	          		<Col>
				       <Input type="number" value={formVals.ticketNumber || ""} onChange={e => setFormValue({field:"ticketNumber",value:e.target.value})} />
	              	</Col>
	            </FormGroup>


	        </Form>




        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Remove
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

  );

}
