import gql from 'graphql-tag';

export default gql`
query Truck($id:ID!){
  employee(id:$id)
	{
	    id
	    firstName
	    lastName
	    phones {
	      type
	      number
	    }
		email
		roles
		isActive
		driversLicenseExpiration
		medicalCardExpiration	  
	}
}
`;