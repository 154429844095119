import * as React from 'react';

import {Table, Button, Spinner} from 'reactstrap';

export default function InspectionFieldsTable({sorting,removing,fields,remove,handleDrop,handleStart,handleDragOver}) {
  // PROBLEM: unless table is rewritter to DOM, it doesn't show the rows properly sorted, even if fields is in the correct order
  // SOLUTION (1): returning null during the save of the sort forces a proper rewrite of the able.
  // SOLUTION (2): try forcing some sort of update to fields
  // SOLUTION (3): add changing table id to table
  // SOLUTION (4): only change display fields when order has changed (fields length check has fixed issue)


 /*const [currentState,setCurrentState] = React.useState(0)
   React.useEffect(() => {
    const newState = currentState+1;

    setCurrentState(newState)
  },[sorting])*/

 /* const [displayFields,setDisplayFields] = React.useState([])
  React.useEffect(() => {
    console.log("updating fields")
    const newFields = [...fields];

    setDisplayFields(newFields)
  },[sorting])*/

  const [displayFields,setDisplayFields] = React.useState([])
  React.useEffect(() => {
    const newFields = [...fields];

    
    // if length differ, then update
    if (displayFields.length !== fields.length) {
      setDisplayFields(newFields);
    }
    //console.log("updating fields")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[fields])

  //if (sorting) return null;
  return (
     <Table >
                  <tbody>
                    
                    {displayFields?.map(({id:fieldId,fieldName,fieldType,required},index) => 
                      <tr key={index}
                           draggable='true'
                          fieldid={fieldId}
                          data-fieldid={fieldId}
                          onDropCapture={handleDrop}
                          onDragStart={handleStart}
                          onDragOver={handleDragOver}

                         >
                        <td>{required ? "*":""}{fieldName}</td>
                        <td>{fieldType}</td>
                        <td>
                          {removing && <Spinner size="sm" />}
                          <Button color="danger" onClick={() => remove(fieldId)}>
                            Remove
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
  );
}

