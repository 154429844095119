import React from 'react';

import Pagination from '../components/Inventory/InventoryPagination';

import {Button, Spinner, Container, Row,Col,Input,Card, Table, FormGroup,Label,Form, CardBody} from 'reactstrap';

import { useQuery,useMutation } from '@apollo/client';
import Inventory from '../graphql/queries/Inventory';
import BarcodeSheet from '../graphql/mutations/BarcodeSheet';

import { throttle } from 'throttle-debounce';


export default function InventoryCodeSheet() {

	const [noBarcodeOnly,setNoBarcodeOnly] = React.useState(true)
	const [keyword,setKeyword] = React.useState()
	const [offset,setOffset] = React.useState(0);
	const limit = 20;
	const {data,loading} = useQuery(Inventory,{variables:{
		noBarcodeOnly,

	        keyword,
	        offset,
	        limit
	      
	}});

  const [dimensions, setDimensions] = React.useState({ 
    height: window.innerHeight,
    width: window.innerWidth
  })

  const [selectedItems,setSelectedItems] = React.useState([]);
  const [outputType,setOutputType] = React.useState();

	const [barcodeSheet,{loading:loadingBarcodeSheet}] = useMutation(BarcodeSheet,{
      
      variables:{outputType,inventoryItems:selectedItems.map(({inventoryItemId}) => parseInt(inventoryItemId))},
      onCompleted: (result) => window.open(result.barcodeSheet.url),
      onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the service. Please try again.`)

	});


  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    
}

    window.addEventListener('resize', handleResize)
  })






	const addItem = (item) => {
		// only add if not in list
		if (selectedItems.find(el => el.inventoryItemId === item.inventoryItemId)) {
			return;
		}
		setSelectedItems([...selectedItems,item]);
	}

	const removeItem = (item) => {
		setSelectedItems(selectedItems.filter(el => el.inventoryItemId !== item.inventoryItemId))
		// find item


	}

	const moveItem = (fromIndex,toIndex) => {


		  // get copy of array
		  let newArray = [...selectedItems];

		  if (toIndex > -1 && toIndex < newArray.length) {
		  	// remove element from array
		  	const removedElement = newArray.splice(fromIndex, 1)[0];

		  	// at toIndex, remove 0 elements and insert the removed
		  	newArray.splice(toIndex,0,removedElement)

		  	setSelectedItems(newArray);
		  }

	}

	const getBarcodeSheet = () => {
		if (selectedItems.length === 0) {
			alert("Please add at least one item to download")
			return
		}
		barcodeSheet();
	}

  const searchKeyword = throttle(1000,false,(newKeyword) => {
    setKeyword(newKeyword);
  })



	return (
	        <Container  fluid>
	        	<h2>Inventory QR Codes</h2>
	        	<Row>
	        		<Col md={6}>
	        			<Card className="shadow">
	        				<CardBody>
								<Form  onSubmit={e => e.preventDefault()}>
									<FormGroup className="mb-0">
										<Input placeholder="Search" onChange={(e) => searchKeyword(e.target.value)} />
									</FormGroup>
									<FormGroup check className="mb-1">
										<Label check>
											<Input type="checkbox" checked={noBarcodeOnly} onChange={() => setNoBarcodeOnly(!noBarcodeOnly)} /> Parts w/out barcode
										</Label>
									</FormGroup>
								</Form>
								<div style={{height: dimensions.height-260,overflowY:"auto",borderRadius: 6,border: "1px solid #cccccc"}}>
									<Table striped>
										<tbody>
										{loading && <Spinner />}
										{
											data && data.inventory.map(({id,partName,inventoryItems}) => 
												<tr>
													<td>
														<strong>{partName}</strong>
														<Table borderless>
															<tbody>
																{inventoryItems.map(({partDescription,vendor,id:inventoryItemId,barcodeData},index) => 
																	!(noBarcodeOnly && barcodeData) &&
																	<tr key={inventoryItemId}>
																		<td>{partName}<br /><strong>{vendor}</strong></td>
																		<td>
																					<Button onClick={() => addItem({id,inventoryItemId,partName,vendor,partDescription})} color="link" className="float-right p-0">
																						Add
																					</Button>													
																		</td>
																	</tr>


																)}
															</tbody>
														</Table>
													</td>
												</tr>
		)
										}
										</tbody>
									</Table>

								</div>
			                   <Pagination 
			                   		prefixLabel="Parts"
			                   		alignLeft={true}
		                           	offset={offset} 
		                           	limit={limit}
				                    filterFields={{keyword,noBarcodeOnly}}
				                    onPrev={() => offset > 0 ? setOffset(offset-limit) : null}
				                    onNext={() => setOffset(offset+limit)}
				                  />



	        				</CardBody>
	        			</Card>
	        		</Col>
	        		<Col md={6}>
	        			<Card className="shadow">
	        				<CardBody>
						<Form >
							<FormGroup  className="mb-1">

									<Input type="select" onChange={e => setOutputType(e.target.value)}>
										<option value="">- Default PDF -</option>
										<option value="ULINE-S-10439">ULINE S-10439 Labels</option>
										<option value="ULINE-S-10439-multicode">ULINE S-10439 Labels (12 Codes Per Label)</option>
										<option value="OL996WX">OL996WX</option>
									</Input>

							</FormGroup>
						</Form>						
						<h6 className="mt-1">Items to Print (Use arrows to adjust print order)</h6>
						<div style={{height: dimensions.height-276,overflowY:"auto",borderRadius: 6, border: "1px solid #cccccc"}}>

							<Table>
								<tbody>
								{
									selectedItems.map(({id,inventoryItemId,partName,vendor,partDescription},index) => 
										<tr key={inventoryItemId} >
												<td > 
															<i style={{fontSize:20}} className="ni ni-bold-up" onClick={() => moveItem(index,index-1)}></i>
															<i style={{fontSize:20}} className="ni ni-bold-down" onClick={() => moveItem(index,index+1)}></i>
															</td><td>{partName}<br /><strong>{vendor}</strong> </td>
										<td>
															<Button onClick={() => removeItem({id,inventoryItemId,partName,vendor,partDescription})} color="link" className="float-right p-0">
																Remove
															</Button>
											</td>

										</tr>
									)
								}
								</tbody>
							</Table>
						</div>


					<Button disabled={loadingBarcodeSheet} block className=" mt-1" color="primary" onClick={() => getBarcodeSheet()}>
						Get PDF {loadingBarcodeSheet && <Spinner size="sm" />}
					</Button>
	        				</CardBody>
	        			</Card>
	        		</Col>
	        	</Row>

			</Container>

	);
}