import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col, Form, FormGroup, CustomInput, Button} from 'reactstrap';

import { throttle } from 'throttle-debounce';

import WorkOrdersTable from "../components/WorkOrders/WorkOrdersTable.js";
import Pagination from "../components/WorkOrders/Pagination.js";
import StatusFilterButton from "../components/WorkOrders/StatusFilterButton.js";
import EmployeeFilterButton from "../components/WorkOrders/EmployeeFilterButton.js";
import TruckFilterButton from "../components/WorkOrders/TruckFilterButton.js";
import SearchInput from '../components/WorkOrders/SearchInput';



export default function WorkOrdersScreen() {

	const limit = 20;
	const [filterFields,setFilterFields] = useState({});
	const [offset,setOffset] = useState(0);

	  // set card height for bottom cards to window minus 70 (padding at top) minus 140 (for rows above table) minus 6 just for spacing
	  //const bottomCardHeight = window.innerHeight - 70-6-140;

  const [bottomCardHeight,setBottomCardHeight] = useState(window.innerHeight - 70-6-140);
  

  useEffect(() => {
    function handleResize() {
      setBottomCardHeight(window.innerHeight - 70-6-140)
    }
    window.addEventListener('resize', handleResize)
  })



	// limit calls to database when typing in filter field
	const searchKeyword = throttle(1000,false,(keyword) => {
	    setFilterFields({...filterFields,keyword});
	})


	return (
		<Container fluid>
			<h2 >
				Work Orders
				<Link to="/WorkOrder/New" className="ml-4">
					<Button color="primary" size="sm">
						New Work Order
					</Button>
				</Link>
			</h2>

			<Row className="mb-2">
				<Col>
					<SearchInput searchKeyword={searchKeyword} />
				</Col>
			</Row>
			<Row>
				<Col md={11}>
					<Form inline className="float-left">
					<StatusFilterButton onChange={(statuses) => setFilterFields({...filterFields,statuses})} />
					{'   '}
					<EmployeeFilterButton onChange={(assignedTo) => setFilterFields({...filterFields,assignedTo})} />
					{'   '}
					<TruckFilterButton truck={filterFields.truck} onChange={(truck) => setFilterFields({...filterFields,truck})} />
					{'   '}
              					<FormGroup className="p-2">
								<CustomInput type="switch" 
										 id="OutOfServiceToggle"
										 label="Out of Service"
										 checked={filterFields.outOfService === true}
										 onChange={e => setFilterFields({...filterFields,outOfService:e.target.checked})}
										 />
								</FormGroup>
					</Form>

				</Col>
				<Col md={1}>
					<Pagination 
					onPrev={() => {
					if (offset > 0)
					setOffset(offset-limit)
					}}
					onNext={() => setOffset(offset+limit)}
					/>
				</Col>
			</Row>
			<WorkOrdersTable offset={offset} 
			limit={limit}
			cardHeight={bottomCardHeight}
			filterFields={filterFields}
			/>
		</Container>
		
	)
}