import gql from 'graphql-tag';

export default gql`
mutation AddSupplier(
    $supplierName: String!,
    $phones: [PhoneInput]
) {
  addSupplier(
    supplierName: $supplierName,
    phones: $phones
  ) {
       id
        supplierName
        phones {
          type
          number
        }

  }
}

`;