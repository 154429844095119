import React,{useEffect, useState} from 'react';

import {Container, Row, Col} from 'reactstrap';

import WorkOrderStatsTable from '../components/Dashboard/WorkOrderStats';
import ServiceDue from '../components/Dashboard/ServiceDue';
import ExpiringItems from '../components/Dashboard/ExpiringItems';
import EquipmentTable from '../components/Dashboard/EquipmentTable';
import WorkOrdersTable from '../components/Dashboard/WorkOrdersTable';

import WelcomeModal from "../components/CustomerService/WelcomeModal";
import AddWorkOrderModal from "../components/CustomerService/AddWorkOrderModal";

import { useQuery } from '@apollo/client';
import Trucks from '../graphql/queries/Trucks'
import WorkOrders from '../graphql/queries/WorkOrders'
import WorkOrderStats from "../graphql/queries/WorkOrderStats";

export default function Dashboard() {
	// set card height for bottom cards to window minus 60 (padding at top) minus 250 (height of cards above) minus 24 (gab between rows) minus 6 just for spacing
	const [bottomCardHeight,setBottomCardHeight] = useState(window.innerHeight - 80-250-24 -6);
	
	const {data:data_trucks,loading:loading_trucks,error:error_trucks} = useQuery(Trucks);
	const {data:data_workorders,loading:loading_workorders,error:error_workorders} = useQuery(WorkOrders,{variables: {limit: 20}});
	const {data:data_stats,loading:loading_stats,error:error_stats} = useQuery(WorkOrderStats,{
		variables: {limit: 20},
		pollInterval: 30000
	});


	useEffect(() => {
		function handleResize() {
			setBottomCardHeight(window.innerHeight - 80-250-24 -6)
		}
		window.addEventListener('resize', handleResize)
	})




	return (
		<Container fluid>
			{data_trucks?.trucks?.length === 0 && <WelcomeModal />}
			{data_trucks?.trucks?.length > 0 
					&& data_workorders?.workOrders?.length === 0 
					&& !data_stats?.workOrderStats?.find(el => el.label === 'Open')
					&& <AddWorkOrderModal />}
			<Row>
				<Col md={4}>
					<WorkOrderStatsTable data={data_stats} loading={loading_stats} error={error_stats} />
				</Col>
				<Col md={4}>
					<ExpiringItems />
				</Col>
				<Col md={4}>
					<EquipmentTable data={data_trucks} loading={loading_trucks} error={error_trucks} />
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<ServiceDue cardHeight={bottomCardHeight} />
				</Col>
				<Col md={6}>
					<WorkOrdersTable cardHeight={bottomCardHeight} data={data_workorders} loading={loading_workorders} error={error_workorders} />
				</Col>
			</Row>
		</Container>
		
	)
}