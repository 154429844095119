import React, {useState, useContext} from "react";
import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
  Spinner,

  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Badge,
  Container,
  Row,
  Col,
  Alert,
  Table,

  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,

  FormGroup,
  Input
} from "reactstrap";

import AsyncSelect from 'react-select/async';
import moment from 'moment';

// core components

import SetStatusModal from '../components/WorkOrders/SetStatusModal';
import AssignModal from '../components/WorkOrders/AssignModal';
import AddNoteModal from '../components/WorkOrders/AddNoteModal';
import CheckInModal from '../components/WorkOrders/CheckInModal';
import DeleteModal from '../components/WorkOrders/DeleteModal';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import WorkOrderPDF from '../graphql/queries/WorkOrderPDF';
import WorkOrder from '../graphql/queries/WorkOrder';
//import SetAvoidableExpenseStatus from '../graphql/mutations/SetAvoidableExpenseStatus';
import SetOutOfService from '../graphql/mutations/SetOutOfService';
import Inventory from '../graphql/queries/Inventory';
import AddWorkOrderPart from '../graphql/mutations/AddWorkOrderPart';
import RemoveWorkOrderPart from '../graphql/mutations/RemoveWorkOrderPart';

import PhotoDisplay from '../components/WorkOrders/PhotoDisplay';

import AuthContext from '../contexts/AuthContext';

export default function WorkOrderView({match:{params:{id}}}) {

    // used for navigation after delete
    const history = useHistory();

    const {userInRole} = useContext(AuthContext);
    const [actionDropdownOpen,setActionDropdownOpen] = useState(false)

  const [fetchVars,setFetchVars] = useState({offset:0,limit:0});
  const [selectedPart,setSelectedPart] = useState({})

    const [addWorkOrderPart,{loading:addingPart}] = useMutation(AddWorkOrderPart, {
      onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : "Error adding part. please try again")
    });
    const [removeWorkOrderPart,{loading:loadingRemove,error:errorRemove}] = useMutation(RemoveWorkOrderPart);

  // get inventory (uselazyquery doesn't return a promise which is needed by AsynSelect)
  const {refetch}  = useQuery(Inventory,{
    variables: fetchVars,
    fetchPolicy:'network-only'
  })

    const {data,loading,error} = useQuery(WorkOrder,{variables:{id}});
    const [getWorkOrderPDF,{loading:loadingPDF}] = useLazyQuery(WorkOrderPDF,{
      fetchPolicy:"network-only",
      variables:{id},
      onCompleted: (result) => window.open(result.workOrderPDF.url),
      onError: err => console.log("pdf error",err)
    });
    //const [setAvoidableExpenseStatus] = useMutation(SetAvoidableExpenseStatus);
    const [setOutOfService] = useMutation(SetOutOfService);

    // control action modals
    const [statusModalVisible,setStatusModalVisible] = useState(false);
    const [assignModalVisible,setAssignModalVisible] = useState(false);
    const [addNoteModalVisible,setAddNoteModalVisible] = useState(false);
    const [checkInModalVisible,setCheckInModalVisible] = useState(false);
    const [deleteModalVisible,setDeleteModalVisible] = useState(false);



    if (loading) return <Spinner />
    if (error) return <Container>There was an error loading the work order. Please try refreshing this page.</Container>

    const {
      ticketNumber,
      description,
      mileageIn,
      truck: {
        id:truckId,
        identifier,
        type
      },
      reportedBy,
      enteredBy,
      status,
      //priority,
      outOfService,
      //createdAt,
      assignedTo,
      partsUsed,
      photos,
      log,
      //avoidableExpense

    } = data.workOrder

  // need to make this more efficient. too many api hits
  const promiseOptions = inputValue =>
    new Promise(resolve => {

      setTimeout(() => {
        if (inputValue.trim().length === 0) {
           setFetchVars({...fetchVars,offset:0,limit:0})
        }
        else {
           setFetchVars({...fetchVars,offset:0,limit:20,keyword:inputValue})
        }
        refetch({variables:fetchVars})
          .then(result => {
            // go through parts and inventory items to build array
            let returnArray = [];
            result.data.inventory.forEach(inventory => 
              inventory.inventoryItems.forEach(inventoryItem => {
                returnArray.push({
                  partName:inventory.partName,
                  partNumber:inventoryItem.partNumber,
                  vendor:inventoryItem.vendor,
                  label:`${inventory.partName} - ${inventoryItem.vendor} ${inventoryItem.partNumber ? `- ${inventoryItem.partNumber}` : ""}`,
                  value:inventoryItem.id
                });
              })
            )
            
            resolve(returnArray);
          })
              
      }, 1000);
      
      
        

    });

    const addInventoryItem = () => {
      if (!selectedPart.inventoryItem) {
        return;
      }

      if (!selectedPart.quantity || isNaN(selectedPart.quantity)) {
        alert("Please enter a number for quantity");
        return;
      }

                              addWorkOrderPart({variables:{
                                workOrderId:id,
                                inventoryItemId:parseInt(selectedPart.inventoryItem.value),
                                quantity: parseFloat(selectedPart.quantity)
                              }})
                                .then(() => setSelectedPart({}))

    }

    return (
      <Container fluid>
        <Row>
            <Col md={6}>
              <h2>Ticket #{ticketNumber}</h2>
            </Col>
            <Col md={6} style={{textAlign:'right'}}>
                      <ButtonDropdown direction="left" isOpen={actionDropdownOpen} toggle={() => setActionDropdownOpen(!actionDropdownOpen)}>
                        <DropdownToggle caret size="sm"  color="primary">
                          Actions
                          {loadingPDF && <Spinner size="sm" />}
                        </DropdownToggle>
                        <DropdownMenu>
                          {status === "Open" && status !== "Work Complete" ?
                            <DropdownItem onClick={() => setCheckInModalVisible(true)}>Checkin</DropdownItem>
                            :
                            status !== "Work Complete" ?
                              <DropdownItem onClick={() => setStatusModalVisible(true)}>Update Status</DropdownItem>
                              :
                              <div />
                          }

                          {status !== "Work Complete" && <DropdownItem onClick={() => setAddNoteModalVisible(true)}>Add Note</DropdownItem>}
                          {status !== "Work Complete" && <DropdownItem onClick={() => setAssignModalVisible(true)}>Assign</DropdownItem>}
                          <DropdownItem onClick={() => getWorkOrderPDF()}>Export PDF</DropdownItem>
                          {userInRole() && !outOfService && <DropdownItem onClick={() => setOutOfService({variables:{id,outOfService:true}})}>Take out of Service</DropdownItem>}
                          {/*userInRole() && !avoidableExpense && <DropdownItem onClick={() => setAvoidableExpenseStatus({variables:{id,status:true}})}>Set Avoidable Expense</DropdownItem>*/}
                          {/*userInRole() && avoidableExpense && <DropdownItem onClick={() =>  setAvoidableExpenseStatus({variables:{id,status:false}})}>Undo Avoidable Expense</DropdownItem>*/}
                          {userInRole({authRoles:["Owner","Shop Manager","Office Manager"]}) && <DropdownItem onClick={() => setDeleteModalVisible(true)}>Delete</DropdownItem>}
                        </DropdownMenu>
                      </ButtonDropdown>              
            </Col>
        </Row>
                {outOfService &&
                  <Alert color="danger" style={{background:'red',color:'white',fontWeight:'bold'}}>
                    Out Of Service
                  </Alert>
                }
        <Row className="mb-2">
          <Col md={6}>
            <Card className="shadow">
              <Table className="detail-table">
                <tbody>
                  <tr>
                    <td>Asset</td>
                    <td><Link to={`/asset/${truckId}`}>{type} {identifier}</Link></td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td><Badge  color={status === "Open" ? "primary" : status === "Work Complete" ? "success" : "info"}>{status}</Badge></td>
                  </tr>
                  <tr>
                    <td>Odometer In</td>
                    <td>{(mileageIn && !isNaN(mileageIn)) ? mileageIn.toLocaleString()  :"n/a"}</td>
                  </tr>

                  <tr>
                    <td>Description</td>
                    <td>{description}</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="shadow">
              <Table className="detail-table">
                <tbody>
                  <tr>
                    <td>Assigned To</td>
                    <td>{assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : "UNASSIGNED"}</td>
                  </tr>
                  <tr>
                    <td>Reported By</td>
                    <td>{reportedBy?.firstName} {reportedBy?.lastName}</td>
                  </tr>
                  <tr>
                    <td>Entered By</td>
                    <td>{enteredBy?.firstName} {enteredBy?.lastName}</td>
                  </tr>
                   <tr>
                    <td>&nbsp;</td>
                    <td></td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
             {photos && photos.length > 0 && <PhotoDisplay photos={photos} />}
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
              <Card className="shadow">
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Parts Used</CardTitle></CardHeader>
                <CardBody className="pt-0">
                  <Row>
                    {status !== 'Work Complete' &&

                    <Col md={3}>
                      <strong>Find Parts</strong>
                      <FormGroup>
                        <AsyncSelect placeholder="Find inventory item"
                               loadOptions={promiseOptions}
                               value={selectedPart.inventoryItem || null}
                               onChange={value => setSelectedPart({...selectedPart,inventoryItem:value})}
                               />  
                      </FormGroup>
                      <FormGroup>
                      <Input type="number" value={selectedPart.quantity || ""} onChange={e => setSelectedPart({...selectedPart,quantity:e.target.value})} placeholder="Quantity" />
                      </FormGroup>
                      <Button color="primary" block onClick={() => addInventoryItem()}>Add</Button>
                 
                    </Col>}
                    <Col md={9}>
                      {(!partsUsed || partsUsed.length === 0) && <span>No parts used</span>}
                      {partsUsed && partsUsed.length > 0 &&
                        <Table responsive className="table-flush">
                            <thead>
                              <tr>
                                <th><strong>Part</strong></th>
                                <th><strong>Qty</strong></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {partsUsed.map(({partName,partNumber,vendor,partDescription,quantity,id:inventoryItemId}) => 
                                <tr>
                                  <td>{partName}<br /><span className="text-muted">{vendor} - {partNumber}</span></td>
                                  <td>{quantity}</td>
                                  <td>
                <Button color="danger" onClick={() => removeWorkOrderPart({variables:{workOrderId:id,inventoryItemId:parseInt(inventoryItemId)}})}>
                  Remove
                </Button>                                    
                                  </td>
                                </tr>
                              )}
                            </tbody>
                        </Table>
                      }
                    </Col>
                  </Row>

                </CardBody>
              </Card>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
              <Card className="shadow">
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
                  <Row>
                    <Col md={6}>
                      <CardTitle className="m-0">Notes</CardTitle>
                    </Col>
                    <Col md={6} style={{textAlign:'right'}}>
                      <Button color="primary" size="sm" onClick={() => setAddNoteModalVisible(true)}>Add Note</Button>
                    </Col>
                  </Row>
                
                
              </CardHeader>

                <CardBody className="pt-0 pt-md-0">
                  {(!log || log.length === 0) && <div>No notes yet</div>}
                  {log && log.map(({
                    createdAt,
                    enteredBy: {
                      firstName,
                      lastName,
                    },
                    note,
                    noteType,
                    photos

                  },index) => 
                    <Row key={index} className="mb-2 pb-4" style={{borderBottom: "1px solid rgb(216, 226, 239)"}}>
                      <Col md={4} style={{lineHeight:1}}>
                        <h6 class="mb-0 text-nowrap">{moment(createdAt).format("M/D/YYYY h:mm a")}</h6>
                        <p class="mb-0 text-muted">{firstName} {lastName}</p>
                        <p class="mb-0 text-muted">{noteType}</p>
                      </Col>
                      <Col md={8}>
                        <p className="card-text">{note}</p>
                        {photos && photos.length > 0 && <PhotoDisplay photos={photos} />}
                      </Col>
                    </Row>
                  )}



           
                </CardBody>
              </Card>
          </Col>
        </Row>
          {/* Action Modals*/}
          <SetStatusModal id={id} modalVisible={statusModalVisible} setModalVisible={setStatusModalVisible} />
          <AssignModal id={id} modalVisible={assignModalVisible} setModalVisible={setAssignModalVisible} />
          <AddNoteModal id={id} modalVisible={addNoteModalVisible} setModalVisible={setAddNoteModalVisible} />
          <CheckInModal id={id} modalVisible={checkInModalVisible} setModalVisible={setCheckInModalVisible} />
          <DeleteModal id={id} modalVisible={deleteModalVisible} setModalVisible={setDeleteModalVisible} onSuccess={() => history.goBack()} />

      </Container>
    );


  
}


