import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner} from 'reactstrap';

import {useMutation} from '@apollo/client';
import GeneratePassword from '../../graphql/mutations/GeneratePassword';


// need to build an extendable modal
export default function SendPasswordModal({user,modalVisible,setModalVisible}) {
  const {firstName,lastName,id} = user || {}; 

  const [sendPassword,{loading}] = useMutation(GeneratePassword,{
    variables: {id},
    onCompleted: () => {setModalVisible()},
    onError: (error) => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error sending the password . Please try again.`)
  })

  if (!user) return null;


  return (
      <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Send Password?</ModalHeader>
        <ModalBody>
          <p>Would you like to send a username and password to {firstName} {lastName}?</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => sendPassword()}>
            Send Password
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>No, I'll send it later</Button>
        </ModalFooter>
      </Modal>

  );





}




