import React, { useState, useContext } from 'react';
import {Link, /*useLocation,*/ useHistory} from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavbarText,
  //NavLink,
  NavItem,
  //Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  //NavbarText
} from 'reactstrap';

import AuthContext from '../contexts/AuthContext';
import AuthSection from './AuthSection';

import Logo from '../assets/images/FleetCommandLogoHorizontal40.png';

import {useMutation} from '@apollo/client';
import Logout from '../graphql/mutations/LogoutMutation';
import AddDispatchTrial from '../graphql/mutations/auth/AddDispatchTrial';

import Icon from '@mdi/react'
import { mdiReorderHorizontal} from '@mdi/js'

import moment from 'moment';

const NavbarScreen = () => {
  const history = useHistory();
  const hostDomain = window.location.host;

  // need path name to prevent reloading new load screen. might come in useful for other things later
  //const location = useLocation()
  
  const [isOpen, setIsOpen] = useState(false);

  const {fullName,setIsLoggedIn,trialInfo,services,features} = useContext(AuthContext);

  const toggle = () => setIsOpen(!isOpen);

  const [logout, {client}] = useMutation(Logout, {

    onCompleted: () => {
      localStorage.removeItem("Authorization");
      client.clearStore();
      setIsLoggedIn(false);
      //history.push("/");
    },
    onError: (error) => history.push("/")
  });
  const [addDispatchTrial, {loading:trialLoading}] = useMutation(AddDispatchTrial, {

    onCompleted: () => {
      sessionStorage.setItem("NewBusiness",true);
      alert("You are all set. To switch between dispatch and shop, click on your name in the menu")
      window.location = "/dispatch/";
    },
    onError: (error) => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding dispatch to your account`)


  });

  const runLogout = () => {
    logout();
    //client.resetStore();




  }

  const renderAppSwitcher = () => {
    // only show on apps.fleetcommand.io. otherwise, user is on dedicate site
    if (hostDomain !== 'apps.fleetcommand.io' && hostDomain !=="localhost:3002") return;

    // we only have 2 services now, but let's be ready for more
    const serviceList = services.filter(s => s !== 'Shop');


    if (serviceList.length === 0) {
      return 
    }

    return (
        <div>
          <DropdownItem header>Switch App</DropdownItem>
          {serviceList.map(s => 
            <DropdownItem key={s} href={`/${s.toLowerCase()}`} onClick={() => false}>
              {s}
            </DropdownItem>

          )}
          <DropdownItem divider />
        </div>

     )
  }

  return (
    <div>
      <Navbar  expand="md" fixed="top" className="shadow" style={{backgroundColor:"#ffffff"}}>
        <NavbarBrand href="/">
             <img alt="Fleet Command" src={Logo} style={{width: 205, height: 20}} />
        </NavbarBrand>
        <NavbarToggler onClick={toggle}>
          <Icon path={mdiReorderHorizontal} size={1}  />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/WorkOrders">Work Orders</Link>
            </NavItem>
            <AuthSection authRoles={['Owner','Office Manager','Shop Manager','Inventory Manager']}>
              <NavItem>
                <Link className="nav-link" to="/Inventory">Inventory</Link>
              </NavItem>
            </AuthSection>
            <AuthSection authRoles={['Owner','Office Manager','Shop Manager','Mechanic']}>
              <NavItem>
                <Link className="nav-link" to="/Assets">Assets</Link>
              </NavItem>
            </AuthSection>
            {features.includes('Inspections') &&
              <AuthSection authRoles={['Owner','Shop Manager']}>
                <NavItem>
                  <Link className="nav-link" to="/InspectionTemplates">Inspections</Link>
                </NavItem>
              </AuthSection>
            }
            <AuthSection>
              <NavItem>
                <Link className="nav-link" to="/People">People</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/ServicePlans">Service Plans</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/Permits">Permits</Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/Reports">Reports</Link>
              </NavItem>
            </AuthSection>

          </Nav>

              {
                trialInfo && trialInfo.accountType === "trial" && trialInfo.planExpiration && 
                
                    <NavbarText className="ml-2" style={{color:"#ff0000", fontSize: 14,border: "1px solid black",borderRadius: 10,padding: 4,background: "#e2e2e2"}}>Free trial expires in {moment(trialInfo.planExpiration).diff(new Date(), 'days')} days</NavbarText>
      
              }
          <UncontrolledDropdown>
            <DropdownToggle color="link" caret>{fullName.firstName} {fullName.lastName}</DropdownToggle>
                  <DropdownMenu>
                      {!services.find(s => s === 'Dispatch' ) &&  
                          <div>
                            <DropdownItem header>TRY DISPATCH</DropdownItem>
                            
                              <DropdownItem  onClick={() => addDispatchTrial()}>
                                Add Dispatch Trial
                              </DropdownItem>

                            
                            <DropdownItem divider />
                          </div>      
                      }

                    {renderAppSwitcher()}
                    <DropdownItem className="noti-title" header tag="div">
                      <Link to="/Support" className="btn btn-danger">Contact Support</Link>
                    </DropdownItem>
                    <DropdownItem to="/AccountSettings/ChangePassword" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>Change Password</span>
                    </DropdownItem>
                    <AuthSection>
                      <DropdownItem to="/AccountSettings" tag={Link}>
                        <i className="ni ni-single-02" />
                        <span>Account Settings</span>
                      </DropdownItem>
                      <DropdownItem to="/MyAccount" tag={Link}>
                        <i className="ni ni-single-02" />
                        <span>My Account</span>
                      </DropdownItem>
                    </AuthSection>



                    <DropdownItem divider />

                    <DropdownItem onClick={() => runLogout()}>Logout</DropdownItem>
                  </DropdownMenu>
          </UncontrolledDropdown>

        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavbarScreen;