import React, {useState} from "react";
import Select from 'react-select'

import {useQuery, useLazyQuery} from '@apollo/client';
import Trucks from '../../graphql/queries/Trucks'; 
import Inspections from '../../graphql/queries/Inspections'; 
import InspectionReportPDF from '../../graphql/queries/InspectionReportPDF'

// reactstrap components
import {
  Container,
  Label,
  Form,
  FormGroup,
  Button,
  Card,
  CardBody,
  Table,
  Spinner,
  Input
  
}
 from "reactstrap";

import moment from 'moment';
import DateTime from 'react-datetime';



export default function PreTripInpsectionReports() {
	const [reportType,setReportType] = useState("yesterday");
	const [startDate,setStartDate] = useState(moment().add(-1,'days')); 
	const [endDate,setEndDate] = useState(moment().add(-1,'days'));
	const [trucks,setTrucks] = useState([]);


const customStyles = {
  container: (_, { selectProps: { width }}) => ({
    width: "100%"
  }),

	/*
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    borderBottom: '1px dotted pink',
    color: state.selectProps.menuColor,
    padding: 20,
  }),

  control: (_, { selectProps: { width }}) => ({
    width: width
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }*/
}


	const {data,loading,refetch} = useQuery(Inspections,{
		variables: {
			dateStart:startDate,
			dateEnd:endDate,
			trucks
		}
	});
	const {data:dataTrucks,loading:loadingTrucks} = useQuery(Trucks);
	const [inspectionReportPDF,{loading:reportLoading}] = useLazyQuery(InspectionReportPDF,{
		fetchPolicy:'network-only',
		onCompleted: (result) => {
			window.open(result.inspectionReportPDF.url)
		},
		onError: () => alert("There was an error loading the PDF. Please try again.")

	});

	const runReport = (id) => {
		inspectionReportPDF({variables:{id}});
	}

	const handleReportType = (e) => {
		const rType = e.target.value;
		setReportType(rType);
		if (rType === 'today') {
			setStartDate(moment().startOf('day'))
			setEndDate(moment().startOf('day'))
		}
		else if (rType === 'yesterday') {
			setStartDate(moment().add(-1,'days').startOf('day'))
			setEndDate(moment().add(-1,'days').startOf('day'))
		}
		else if (rType === 'thisMonth') {
			setStartDate(moment().startOf('month'))
			setEndDate(moment().endOf('month'))
		}
	}

	return (
	      <Container  fluid>
          <h2>
            Pre Trip Inspection Reports
          </h2>
          <Card>
          	<CardBody>
          <Form inline>
          	<FormGroup className="mr-2">
          		
					<Input type="select" value={reportType} onChange={handleReportType}>
						<option value="today">Today</option>
						<option value="yesterday">Yesterday</option>
						<option value="thisMonth">This Month</option>
						<option value="custom">Custom</option>
					</Input>

				</FormGroup>
				{reportType === "custom" &&
			          	<FormGroup className="mr-2">
			          		<Label className="mr-1">Start Date:</Label>
							<DateTime inputProps={{style:{maxWidth: 200}}}  placeholder="Start Date" timeFormat={false} value={startDate} onChange={date => setStartDate(date)} />
						</FormGroup>
				}
				{reportType === "custom" &&
			          	<FormGroup className="mr-2">
			          		<Label className="mr-1">End Date: </Label>
							<DateTime style={{maxWidth: 200}} placeholder="End Date" timeFormat={false} value={endDate} onChange={date => setEndDate(date)} />
						</FormGroup>
					
				}
					<FormGroup style={{minWidth:200}}>

         <Select
         		styles={customStyles}
		        defaultValue={trucks}
		        isMulti
		        placeholder="Select assets...."
		        onChange={value => {

		        	// update appliesTo (value is an array of this field's values)
		        		// loop over trucks from value field. if in appliesTo then ignore. If not, then add
		        		const newTrucks = [...trucks];
		        		value.forEach(v => {
		        			// if not found, add it
		        			if (!trucks.find(el => el === v.value)) {
		        				newTrucks.push(v.value)
		        			}
		        		})
		        		// remove anything that shouldn't be in trucks
		        		const trucksToClean = newTrucks.filter(a => value.find(v => v.value === a))
		        														// replace id:value with value
		        														.map(truck => truck.id)
		        		setTrucks(trucksToClean)
		        }}
		        
		        options={dataTrucks?.trucks.map(({id,identifier,type}) => ({label:`${type} ${identifier}`,value:{id}}))}
      	/>
      	</FormGroup>
		  </Form>
		  	</CardBody>
		  </Card>

		  {data?.inspections &&
			  <Card>
			  	<CardBody>
			  		<Table>
			  			<thead>
			  				<tr>
			  					<th>Asset</th>
			  					<th>Date</th>
			  					<th className="text-center">Odometer</th>
			  					<th className="text-center"># Defects</th>
			  					<th className="text-center">Out of Service?</th>
			  					<th>Reported By</th>
			  					<th>Get PDF</th>
			  				</tr>
			  			</thead>
			  			<tbody>
			  				{data.inspections.map(({id,truck:{type,identifier},odometer,defects,outOfService,reportedBy,reportDateTime}) => 
				  				<tr>
				  					<td>{type} {identifier}</td>
				  					<td>{moment(reportDateTime).format('LLL')}</td>
				  					<td className="text-center">{odometer}</td>
				  					<td className="text-center">{defects.length}</td>
				  					<td className="text-center" style={outOfService ? {backgroundColor: 'red',color:'#fff'} : {}}>{outOfService ? 'Yes' : 'No'}</td>
				  					<td>{reportedBy?.firstName} {reportedBy?.lastName}</td>
				  					<td>{reportLoading ? <Spinner /> : <Button color="primary" onClick={() => runReport(id)}>Get PDF</Button>}</td>
				  				</tr>

			  				)}
			  				{(!data.inspections || data.inspections.length === 0) && <tr><td colSpan="100%">No reports found</td></tr>}
			  			</tbody>
			  		</Table>
			  	</CardBody>
			  </Card>
		  }
          
        </Container>
    

      );

}
