import React from 'react';


import {
	Card,
	CardBody,
	CardTitle,
	CardHeader,
	Button,
	Row,
	Col
} from 'reactstrap';

import Icon from '@mdi/react'
import { mdiPlus,mdiPencil,mdiMinus} from '@mdi/js'


export default function InventoryItemCard({item,partName,add,remove,edit}) {
	const {
			vendor,
			partNumber,
			numberInStock,
			barcodeData,
			partDescription,
		cost
		} = item;

	return (
		<Card className="shadow mb-4">
        	<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
        		<Row>
        			<Col md={6}>
        				<CardTitle className="m-0">{vendor}</CardTitle>
        			</Col>
        			<Col md={6}>
						<Button className="ml-2 float-right" color="primary" size="sm" onClick={() => edit()}>
							<Icon path={mdiPencil} size={.5} className="mr-1" />
							Edit
						</Button>
						<Button className="ml-2 float-right" color="danger" size="sm" onClick={() => remove()}>
							<Icon path={mdiMinus} size={.5} className="mr-1" />
							Remove Inventory
						</Button>
						<Button className="ml-2 float-right" color="info" size="sm" onClick={() => add()}>
							<Icon path={mdiPlus} size={.5} className="mr-1" />
							Add Inventory
						</Button>

        			</Col>
        		</Row>
        		
        	</CardHeader>

			<CardBody>

				<div className="mb-1" style={{fontWeight: 500}}>
					Part Description
					<div className="text-muted">{partDescription}</div>
				</div>
				<div className="mb-1" style={{fontWeight: 500}}>
					Part Number
					<div className="text-muted">{partNumber ? partNumber : "n/a"}</div>
				</div>
				<div className="mb-1" style={{fontWeight: 500}}>
					# in stock
					<div className="text-muted">{numberInStock}</div>
				</div>
				<div className="mb-1" style={{fontWeight: 500}}>
					Cost
					<div className="text-muted">{cost ? `$${cost.toLocaleString()}` : "n/a"}</div>
				</div>
				<div className="mb-1" style={{fontWeight: 500}}>
					Barcode
					<div className="text-muted">{barcodeData || "n/a"}</div>
				</div>
			</CardBody>
		</Card>
	);
}