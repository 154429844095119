import React, { useState, useEffect } from 'react';
import {Modal,ModalHeader,ModalBody,ModalFooter, Form, FormGroup, Label, Input, Col, Button,Spinner} from 'reactstrap';
import moment from 'moment';
import Select from 'react-select'

import {useQuery,useMutation} from '@apollo/client';
import AddAssetToPermit from '../../graphql/mutations/AddAssetToPermit';
import Permit from '../../graphql/queries/Permit';
import Trucks from '../../graphql/queries/Trucks';

export default function AddAssetToPermitModal({permit,assignedTo,startDate,renewalDate,onComplete,modalVisible,setModalVisible}) {

	const [formFieldValues,setFormFieldValues] = useState({
		truck:null,
		startDate,
		renewalDate
	});

	useEffect(() => {
		
		setFormFieldValues({startDate,renewalDate})
	},[startDate,renewalDate,assignedTo])

	const {data,loading} = useQuery(Trucks);
	const [addAssetToPermit,{loading:updating}] = useMutation(AddAssetToPermit, {
		refetchQueries: [{query: Permit,variables: {id:permit}}]
	});

  	// simplify setting formFieldValues
  	const setValue =  ({name,value}) => {
  			
  		 setFormFieldValues({...formFieldValues,[name]:value})
  	}

  	const save = () => {
  
  		if (!formFieldValues.truck || !formFieldValues.startDate || !formFieldValues.renewalDate) {
  			alert("All fields are required")
  			return;
  		}

  		addAssetToPermit({variables:{
  			permit,
  			truck:formFieldValues.truck,
   			startDate:moment(formFieldValues.startDate),
  			renewalDate:moment(formFieldValues.renewalDate),
  		}})
  		.then(() => setModalVisible(false))



   	}

   	if (loading || modalVisible === false) return null

   	// remove existing assets from options
   	const assetOptions = !assignedTo  ? [] : data.trucks
   		.filter(truck => !assignedTo.find(el => truck.id === el.truck.id))
   		.map(truck => ({label:truck.identifier,value:truck.id}))

	return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add Asset to Permit</ModalHeader>
        <ModalBody>
	          <Form  style={{marginBottom: 20}}>
		            <FormGroup row>
		          		<Label sm={4}>*Asset</Label>
		          		<Col sm={8}>
			                 <Select
							        defaultValue={formFieldValues.truck || ""}
							        
							        placeholder="Select Asset...."
							        onChange={({value}) => setValue({name:"truck",value})}
							        options={assetOptions}
							      />


		              	</Col>
		            </FormGroup>
		            <FormGroup row>
		          		<Label sm={4}>*Start Date</Label>
		          		<Col sm={8}>
							<Input type="date" value={formFieldValues.startDate || ""} onChange={e => setValue({name:"startDate",value:e.target.value})} />
		              	</Col>
		            </FormGroup>
		            <FormGroup row>
		          		<Label sm={4}>*Renewal Date</Label>
		          		<Col sm={8}>
							<Input type="date" value={formFieldValues.renewalDate || ""} onChange={e => setValue({name:"renewalDate",value:e.target.value})} />
		              	</Col>
		            </FormGroup>
		      </Form>

        </ModalBody>
        <ModalFooter>
        	<Button color="primary" onClick={() => save()}>Update</Button>
        	<Button color="warning" onClick={() => setModalVisible(false)}>Cancel</Button>
        	{updating && <Spinner />}
        </ModalFooter>
      </Modal>


	);

	

}

