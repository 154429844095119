import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import {useQuery} from '@apollo/client';
import TruckWorkOrdersReport from "../../graphql/queries/reports/truckWorkOrdersReport";
import {Spinner, Card, CardHeader,CardTitle,CardBody} from 'reactstrap';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export const options = {
   //maintainAspectRatio: false ,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data2 = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [10,20,30,40,50,60,70,80,90,100,110,120],
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      data: [18,28,38,48,58,68,78,88,98,188,118,128],
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function AssetHisotryChart({truck}) {
  
  const [chartData,setChartData] = React.useState({
  labels,
  datasets: [],
});
  const [chartOptions,setChartOptions] = React.useState({
  
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: false,
      text: 'Work Order History',
    },
  },
});

  

  const {data,loading} = useQuery(TruckWorkOrdersReport,{variables:{truck}})
React.useEffect(() => {
      if (!data) return;

      // set chart labels
      const labels = data.truckWorkOrdersReport[0]?.WorkOrders.map(wo => `${wo.month}/${wo.year}`);

      // set repair data
      const dataset1 = {
        label: "Repairs",
        data:data.truckWorkOrdersReport[0]?.WorkOrders.map(wo => wo.countStandard),
        backgroundColor: 'rgba(255, 99, 132, 0.5)'
       }
      const dataset2 = {
        label: "Preventative",
        data:data.truckWorkOrdersReport[0]?.WorkOrders.map(wo => wo.countPreventative),
         backgroundColor: 'rgba(53, 162, 235, 0.5)'
       }

       setChartData({
        
         labels,
         datasets:[
           {...dataset1},
           {...dataset2},
         ]
       })

  },[data])
 if (loading || chartData.datasets.length === 0) return <div className="text-center"><Spinner></Spinner><br />Loading history...</div>

console.log("chartData",chartData)

  return (
          <Card className="shadow mb-4">
            <CardHeader className="pb-0" style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
              <CardTitle className="m-0 float-left">History</CardTitle>
            </CardHeader>
            <CardBody>
              <Bar options={chartOptions} data={chartData}
              //width={600}
              height={100}

              />;

            </CardBody>
          </Card>


  ); 

}
