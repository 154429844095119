import gql from 'graphql-tag';

export default gql`
{
  inspectionTemplates {
    id
    templateName
    appliesTo
    isPretrip
  }
}
`;