import React, { useState, useEffect } from 'react';

import {
	Container,
	Card,
	Form,
	FormGroup,
	Label,
	Input,
	ButtonGroup,
	Button,
	Col,
	Row
} from 'reactstrap';
// core components

import DateTime from 'react-datetime';

import moment from 'moment';
import PhoneModal from './PhoneModal';

export default function EmployeeForm({id,title,saveEmployee,initialVariables}) {
	//const id = navigation.getParam("id");


	// display control
	//const [showLicenseDatePicker,setShowLicenseDatePicker] = useState(false)
	//const [showMedCardDatePicker,setShowMedCardDatePicker] = useState(false)
	const [showPhoneForm,setShowPhoneForm] = useState(false)

  	// form values
  	const [formFieldValues,setFormFieldValues] = useState({isActive:true,phones:[],roles:[]});
  	/*const [formFirstName,setFormFirstName] = useState();
  	const [formLastName,setFormLastName] = useState();

  	const [formEmail,setFormEmail] = useState();
  	const [formPhones,setFormPhones] = useState([]);

  	const [formDriversLicense,setFormDriversLicense] = useState();
  	const [formMedicalCard,setFormMedicalCard] = useState();

  	const [formActive,setFormActive] = useState(true);
  	const [formRoles,setFormRoles] = useState([]);*/

  	// get timezone offset for date picker
  	//const timeZoneOffsetInMinutes = -((new Date()).getTimezoneOffset());

  	// roles (will move to api)
  	const roleOptions = [
		"Owner",
		"Office Manager",
		"Driver",
		"Mechanic",
		"Restricted Mechanic",
		"Inventory Manager",
		"Shop Manager",
		"Crew Member"
  	]


  	// if initialization variables are sent in, then set state
  	useEffect(() => {
 
  		if (!id) return;

  		setFormFieldValues({...formFieldValues,...initialVariables});
  		return () => null;

  	},[initialVariables])


  	const save = () => {

	    // has customer and pickupdate and pickup location and description
	    let validationMessages = [];
	    if (!formFieldValues.firstName || formFieldValues.firstName.length === 0) validationMessages.push("First Name");
	    if (!formFieldValues.lastName || formFieldValues.lastName.length === 0) validationMessages.push("Last Name")
	    if (!formFieldValues.email || formFieldValues.email.length === 0) validationMessages.push("Email")

	    if (validationMessages.length > 0) {
	    	alert(`The following fields are required:\n-${validationMessages.join("\n-")}`)

	        return;
	    }



		const variables =  {
			id,
  			firstName:formFieldValues.firstName,
  			lastName:formFieldValues.lastName,
  			driversLicenseExpiration: formFieldValues.driversLicenseExpiration ? new Date(formFieldValues.driversLicenseExpiration) : null,
  			medicalCardExpiration:formFieldValues.medicalCardExpiration ? new Date(formFieldValues.medicalCardExpiration) : null,
  			// phones need to be only type and number (typename is include from api)
  			phones:formFieldValues.phones.map(({type,number}) => ({type,number})),
  			email:formFieldValues.email,
  			roles:formFieldValues.roles,
  			isActive:formFieldValues.isActive


		}

  		saveEmployee(variables);
  	}

  	// simplify setting formFieldValues
  	const setValue = ({name,value}) => {
  		setFormFieldValues({...formFieldValues,[name]:value})
  	}

  	// add to phone array or update
  	const setPhone = (phone) => {
  		let newPhones = [...formFieldValues.phones,phone]; // can't update phone field directly

  		setFormFieldValues({...formFieldValues,phones:newPhones})
  	}

  	// remove from phone array 
  	const removePhone = (phone) => {
  		const newPhones = formFieldValues.phones.filter(({number,type}) => !(number===phone.number && type===phone.type)  )
  		setFormFieldValues({...formFieldValues,phones:newPhones});
  	}

  	const updateRoles = (role) => {
  		let newRoles;
  		// if roles already set, remove it. else add it
  		if (formFieldValues.roles.includes(role)) {
  			newRoles = formFieldValues.roles.filter(formRole => formRole !== role)
  		}
  		else {
  			newRoles = [...formFieldValues.roles,role];
  		}
  		setFormFieldValues({...formFieldValues,roles:newRoles});
  	}

	// NOTE: Made some style accomodations for android. Should create a override header component to handle
	return (


        <Container className="mt--8" fluid>
 		 <Row>
		 <Col className="mb-5 mb-xl-0" xl="12">

			<Card className="p-2" style={{ backgroundColor: "#e2e2e2"}}>



			
	          <Form  style={{marginBottom: 20}}>

	          	<Card className="p-2 mb-2">
	          		<FormGroup row>
	          			<Label sm={2}>Active?</Label>
	          			<Col sm={2}>
						    <ButtonGroup >
						      <Button color="primary" outline={!formFieldValues.isActive} onClick={() => setFormFieldValues({...formFieldValues,isActive:true})}>Yes</Button>
						      <Button color="danger" outline={formFieldValues.isActive} onClick={() => setFormFieldValues({...formFieldValues,isActive:false})}>No</Button>
						    </ButtonGroup>
	          			</Col>

	          		</FormGroup>
		            <FormGroup row>
		              <Label sm={2}>First Name</Label>
		              <Col sm={10}>
			              <Input  
			              		 placeholder="" 
			              		 value={formFieldValues.firstName || ""}
			              		 onChange={e => setValue({name:"firstName",value:e.target.value})}
			              		 />
		              </Col>
		            </FormGroup>

		            <FormGroup row>
		              <Label sm={2}>Last Name</Label>
		              <Col sm={10}>
			              <Input  
			              		 placeholder="" 
			              		 value={formFieldValues.lastName || ""}
			              		 onChange={e => setValue({name:"lastName",value:e.target.value})}
			              		 />
		              </Col>
		            </FormGroup>

		            <FormGroup row>
		              <Label sm={2}>Email</Label>
		              <Col sm={10}>
			              <Input  
			              		 placeholder="" 
			              		 value={formFieldValues.email || ""}
			              		 onChange={e => setValue({name:"email",value:e.target.value})}
			              		 type="email"
			              		 />
		              
		              </Col>
		            </FormGroup>

		        </Card>

		        <Card className="p-2 mb-2">
		        	<FormGroup row>
		        		<Label sm={2}>
		        			Phones
         		        		</Label>
		        		<Col sm={10}>
		        			<Button onClick={() => setShowPhoneForm(true)} color="primary" className="p-1"><i className=" ni ni-fat-add" /> Add Phone</Button>

		        			{(!formFieldValues.phones || formFieldValues.phones.length === 0) && <div>No phone numbers added</div> }
				        	{formFieldValues.phones && 
				        		formFieldValues.phones.map(({number,type},index) =>
					        		<div key={index}>
					        			{type}: {number} 
				        				<Button color="link" onClick={() => removePhone({number,type})}>
				        					<i className=" ni ni-fat-add" />
				        					remove
				        				</Button>					        			
					        		</div>

				        		)
				        	}
		        		
		        		</Col>
		        	</FormGroup>



		        </Card>

		        <Card className="p-2 mb-2">

					<FormGroup row>
						<Label sm={4}>Drivers License Expiration</Label>
						<Col sm={8}>
							{/*<Input
								type="date"
								value={formFieldValues.driversLicenseExpiration ? moment(formFieldValues.driversLicenseExpiration).format("YYYY-MM-DD") : ""}
								onChange={e => setValue({name:"driversLicenseExpiration",value:e.target.value})}
							/>*/}
							<DateTime timeFormat={false} value={formFieldValues.driversLicenseExpiration ?moment(formFieldValues.driversLicenseExpiration) : ""} onChange={date => setValue({name:"driversLicenseExpiration",value:date})} />
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm={4}>Medical Card Expiration</Label>
						<Col sm={8}>
							{/*<Input
								type="date"
								value={formFieldValues.medicalCardExpiration ? moment(formFieldValues.medicalCardExpiration).format("YYYY-MM-DD") : ""}
								onChange={e => setValue({name:"medicalCardExpiration",value:e.target.value})}
							/>*/}
							<DateTime timeFormat={false} value={formFieldValues.medicalCardExpiration ? moment(formFieldValues.medicalCardExpiration) : ""} onChange={date => setValue({name:"medicalCardExpiration",value:date})} />
						</Col>
					</FormGroup>


		        </Card>

		        <Card className="p-2 mb-2">
		        	<FormGroup row>
		        		<Label sm={2}>Roles</Label>
		        		<Col sm={10}>
		        			{roleOptions.map(role => 
						      <FormGroup key={role} check>
						        <Label check>
						          <Input type="checkbox" checked={formFieldValues.roles.find(el => el === role)} onClick={() => updateRoles(role)} />{' '}
						          {role}
						        </Label>
						      </FormGroup>
						    )}
		        		</Col>
		        	</FormGroup>

		        </Card>
				    	<div className="p-4 text-center">
				    		<Button onClick={() => save()} color="primary">Save Person</Button>
				    	</div>
	          </Form>



				<PhoneModal
					modalVisible={showPhoneForm}
					setModalVisible={setShowPhoneForm}
					setPhone={setPhone}
				/>



			</Card>
			</Col>
			</Row>
		</Container>
	
	);

}