import React, {useEffect} from 'react';

import { Spinner, Input} from 'reactstrap';

import { useQuery } from '@apollo/client';
import ServicePlan from '../../graphql/queries/ServicePlan';

export default function ServicePicker({selectedValue,onValueChange,servicePlanId}) {
	console.log("servicePlanId",servicePlanId)
	const {data,loading,error,refetch} = useQuery(ServicePlan,{variables:{id:servicePlanId}});

	// if plan id changed, refresh services
	useEffect(() => {
		refetch()
	},[servicePlanId,refetch])

	// if no data, then truck doesn't have a plan
	if (!servicePlanId) return <div style={{marginTop: 10}}>This vehicle doesn't have a service plan assigned</div>



	if (loading) return <Spinner />

	if (error) return <div>Error loading services</div>




	return (
          <Input type="select" name="truckId" onChange={e => onValueChange(e.target.value)}>
          	<option>Select Service...</option>
			{data.servicePlan.serviceSchedule
							.map(({service,id}) => <option key={id} value={id}>{service}</option>)}
          </Input>
        )

	
}