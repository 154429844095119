import React  from 'react';
import {Container, Row, Col} from 'reactstrap';
import BackgroundImage from "../../assets/images/DriverWithPhone.jpg";

import {
  Switch,
  Route,
} from "react-router-dom";

import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ResetPassword from "./ResetPassword";
import SetNewPassword from "./SetNewPassword";

import {Helmet} from 'react-helmet';

export default function Home() {



	return (
		<Container fluid className="h-100" style={{position:"absolute",top:0}}>
			<Helmet>
				<title>Login | Fleet Command</title>
			</Helmet>
			<Row className="h-100">
				<Col md={6} style={{background:'#ffffff'}} >			    
			            <Switch>
			              
			                <Route path="/setNewPassword" component={SetNewPassword} />;
			                <Route path="/resetPassword" component={ResetPassword} />;
			                <Route path="/signup" component={SignupForm} />;
			                <Route path="/" component={LoginForm} />;

			            </Switch>
				</Col>
				<Col md={6} style={{backgroundColor:'white',background:`url(${BackgroundImage}) no-repeat`,backgroundPosition:'center',backgroundSize:"cover"}}>

					<div className="pt-5" style={{color: "#36454f",margin: 'auto',zIndex:3,position:"relative"}}>
						
						<p style={{fontSize:24,fontWeight:500}}>
							Fleet Command simplifies fleet maintenance tracking. Never lose a truck or miss a load because a repair was forgotten.
						</p>
						<p>
							Email <a href="mailto:contact@fleetcommand.io" >contact@fleetcommand.io</a> to find out more.
						</p>
						<p>
							<a className="btn btn-primary" href="https://www.fleetcommand.io/" >Learn More about Fleet Command</a>
						</p>
					</div>
				</Col>
			</Row>
		</Container>
	);



}



