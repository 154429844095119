import React from 'react';
import moment from 'moment';
import {Spinner,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';

import {Link} from 'react-router-dom';

import { useQuery } from '@apollo/client';
import ServicePlanStatuses from '../../graphql/queries/ServicePlanStatuses';

export default function ServiceDue({cardHeight}) {

	const {data,loading} = useQuery(ServicePlanStatuses,{variables:{criticalOnly:true}})

	


	const getLastServiceMileage = (service) => {
		const {serviceSchedule:{trackingMeasurement},lastServiceMileage,lastServiceDate} = service;

		// if this hours/miles or days/weeks
		if (trackingMeasurement === 'miles' || trackingMeasurement === 'hours') {
			return lastServiceMileage ? lastServiceMileage.toLocaleString() + " " + trackingMeasurement : 'n/a'
		}
		else {
			return moment(lastServiceDate).format("M/D/YYYY")
		}
	}

	const getNextServiceMileage = (service) => {
		const {lastServiceMileage,lastServiceDate,serviceSchedule:{trackingMeasurement,units}} = service;

		// if this hours/miles or days/weeks
		if (trackingMeasurement === 'miles' || trackingMeasurement === 'hours') {
			return (lastServiceMileage+units).toLocaleString() + " " + trackingMeasurement
		}
		else {
			return moment(lastServiceDate).add(trackingMeasurement === "days" ? units : units*7, "days").format("M/D/YYYY")
		}
	}

	const renderProgressToService = (lastReportedMileage,service) => {
		
		// get percentage to service (diff between then and now divided by units)
		const {serviceSchedule:{trackingMeasurement,units},lastServiceMileage,lastServiceDate} = service;

		let percentage = 0;
			// if this hours/miles or days/weeks
			if (trackingMeasurement === 'miles' || trackingMeasurement === 'hours') {
				percentage =  (lastReportedMileage-lastServiceMileage)/units
				
			}
			else {
				percentage = moment().diff(lastServiceDate,'days')/(trackingMeasurement === 'days' ? units : units*7)
			}

			percentage = Math.ceil(percentage*100);


		let progressBarClass = "progress-bar"
		if (percentage >= 100) {
			progressBarClass += " bg-danger progress-bar-striped"
		}
		else if (percentage >= 80) {
			progressBarClass += " bg-warning"
		}

		return (
			<div className="progress" style={{height: 20}}>
			  	<div className={progressBarClass}
					role="progressbar" 
					style={{width: `${percentage}%`}} 
					aria-valuenow={percentage}
					aria-valuemin="0" 
					aria-valuemax="100">
					{percentage}%
				</div>
			</div>

		);

	}
	return (
         <Card className="shadow mb-4" style={cardHeight ? {height: cardHeight,minHeight: 150} : {}}>
        	<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Service Due</CardTitle></CardHeader>
          <CardBody className="pt-0" style={{overflowY:"auto"}}>
          	{loading && <Spinner />}
			{(!data?.servicePlanStatuses || data?.servicePlanStatuses.length === 0) &&
				 <p className="pt-3">No services due</p> 
			}

	

			{data?.servicePlanStatuses &&
					<table className="table">
						<tbody>
							{data.servicePlanStatuses.map(({truck,items}) => items.map((service,index) => 
									<tr key={index}>
										<td><Link to={`/Asset/${truck.id}`}>{truck.identifier}</Link></td>
										<td>{service?.service?.toLocaleString()}</td>
										<td className="d-none d-md-block">
											{(service.lastServiceDate || service.lastServiceMileage) ? <span>Last: {getLastServiceMileage(service)}<br /></span> : 'Service Not Completed' }
											{service.lastServiceDate ? <span>Next: {getNextServiceMileage(service)}<br /></span> : ''}
											{(service.lastServiceDate && (service.serviceSchedule.trackingMeasurement === 'miles' || service.serviceSchedule.trackingMeasurement === 'hours')) && <span>Current:{truck.lastReportedMileage.toLocaleString()} {service.serviceSchedule.trackingMeasurement}</span>}
										</td>
										<td>
											
											{service.lastServiceDate ? renderProgressToService(truck.lastReportedMileage,service) : ''}
											
											{!service.lastServiceDate && <em className="d-md-none ">Service Not Completed</em>}
										</td>

									</tr>))}
						</tbody>
					</table>


			}
			</CardBody>
		</Card>
	);
}