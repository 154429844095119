import gql from 'graphql-tag';

export default gql`
mutation AddTruckType ($type: String!,$measure: String!) {
  addTruckType(type: $type,measure: $measure) {
    type
    measure
  }

}

`;