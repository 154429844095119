import React from 'react';
import EquipmentForm from '../components/Assets/AssetForm';

import { Spinner} from 'reactstrap';

import { useMutation } from '@apollo/client';
import AddEquipment from '../graphql/mutations/AddEquipment';
import Trucks from '../graphql/queries/Trucks';

import { useHistory } from "react-router-dom";

export default function NewAssetsScreen() {
	const history = useHistory();

	const [addEquipment,{loading}] = useMutation(AddEquipment,{
		refetchQueries:[{query:Trucks}],
		onCompleted: () => history.goBack(),
		onError: error => {
				return alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding equipment . Please try again.`)
		}

	})


  


  	// save function. putting it here so view can take advantage of loading var of mutation
	const saveEquipment = async (variables) => {
		addEquipment({variables})
	} 

	/*if (loading) {
		return <Spinner />
	}*/

	return (
			<>
				{loading && <div className="h-100 w-100 text-center" style={{padding: "50%",position:"absolute",zIndex: 100,background:"rgba(0,0,0,.5)"}}><Spinner /></div>}
				<EquipmentForm title="New" saveEquipment={saveEquipment} />
			</>
		   )


}



