import gql from 'graphql-tag';

export default gql`
mutation UpdatePurchaseOrder(
				$poId: Int!,
				$poDate: DateTime,
				$poNumber: String,
				$supplier: ID

	) {
		updatePurchaseOrder(
			poId:$poId,
			poDate:$poDate,
			poNumber:$poNumber,
			supplier:$supplier
		) {
		    id
		    cancelled
		    poDate
		    poNumber
		    supplier {
		      id
		      supplierName
		    }
		    items {
		      id
		      partName
		      partNumber
		      vendor
		      quantityOrdered
		      quantityReceived
		      dateReceived
		    }
		}
	}

`;


