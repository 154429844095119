import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';

export default gql`
mutation AddPermit(
	$trucks: [ID],
	$permitName: String!,
	$startDate: DateTime,
	$renewalDate: DateTime,
	$renewalSchedule: String,
	$assignedTo: [PermitTruckAssignedInput]
  )  {
  addPermit (
	trucks:$trucks,
	permitName:$permitName,
	startDate:$startDate,
	renewalDate:$renewalDate,
	renewalSchedule:$renewalSchedule,
	assignedTo:$assignedTo

  ) {
		...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;
