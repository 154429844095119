import React from 'react';

import {Spinner,Card, CardHeader, CardTitle, CardBody} from 'reactstrap';

import {Link} from 'react-router-dom';

import { useQuery } from '@apollo/client';
import DashboardAlerts from '../../graphql/queries/DashboardAlerts';


// convert alert labels to proper titles and nav
const labelsToTitles = {
	permits: {title: "Permits", to:"ExpiringPermits",icon:"fa fa-paper",color:"#FF9501"},
	federalAnnual: {title: "Federal Annuals", to:"/Assets",icon:"nc-icon nc-zoom-split",color:"#007AFF"},
	registration: {title: "Registration Renewal", to:"/Assets",icon:"nc-icon nc-zoom-split",color:"#007AFF"},
	driversLicense: {title: "Drivers License", to:"/People",icon:"nc-icon nc-badge",type:"FontAwesome",color:"#ff0000"},
	medicalCard: {title: "Medical Card", to:"/People",icon:"nc-icon nc-sound-wave",color:"#b7daf8"},
	lowInventory: {title: "Low Inventory", to:"/Inventory",icon:"fa fa-paper",color:"#fa213b"},
	serviceDue: {title: "Service Due", to:"ServiceDue",icon:"cog",type:"FontAwesome",color:"#cccccc"}
}



export default function ExpiringItems() {
	const {data, loading} = useQuery(DashboardAlerts);



	return (
		<Card className="shadow mb-4" style={{height:250}}>
		
        	<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Expiring Items</CardTitle></CardHeader>

			<CardBody className="pt-0" style={{overflowY:"auto"}}>
				{loading && <Spinner />}
				{data && data.dashboardAlerts && !data.dashboardAlerts.find(({count}) => count > 0) &&
					<div style={{marginTop:"20%",textAlign:"center"}}>No expiring items found</div>
				}
				{data && data.dashboardAlerts && data.dashboardAlerts.find(({count}) => count > 0) &&
					<table className="table">
						<tbody>
							{/* show everything by service due. that has its own section */}
							{data.dashboardAlerts.filter(el => el.label !== 'serviceDue').map(({label,count}) =>
								<tr key={label}>
									<td className="text-muted pt-2 pb-2">

										              	 {labelsToTitles[label].title}
										              	</td>
									<td className="pt-2 pb-2"><Link to={labelsToTitles[label].to}>{count}</Link></td>
								</tr>
							)}
						</tbody>
					</table>
				}
			</CardBody>
		</Card>

	);
}