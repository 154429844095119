import React, { useState } from 'react';

import { Container, Form, FormGroup, Label, Input, Button, Alert, Card } from 'reactstrap';

import { useMutation } from '@apollo/client';
import ChangePassword from '../../graphql/mutations/ChangePassword';

const INITIAL_VALUES = {currentPw:"",newPw:"",newPw2:""};

export default function ChangePasswordForm() {

	const [formVals,setFormVals] = useState(INITIAL_VALUES);
	const [errors,setErrors] = useState([]);

	const [changePassword] = useMutation(ChangePassword);

	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}


	const save = () => {
		const {currentPw,newPw,newPw2} = formVals;

		if (!currentPw.trim() || !newPw.trim() || !newPw2.trim()) {
			return setErrors(["All fields are required"])
		}

		if (newPw !== newPw2) {
			return setErrors(["Your passwords don't match"])
		}

		changePassword({variables:{
			newPw,newPw2,currentPw 
		}})
			.then(() => {alert("Password Updated");setFormVals(INITIAL_VALUES);setErrors([])})
			.catch(error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error changing your password . Please try again.`))
	}


	return (
	        <Container fluid>
	        	{errors && errors.length > 0 && errors.map((e,index) => <Alert key={index} color="danger">{e}</Alert>)}
	        	<Card className="p-4">
	        	<Form>
	        		<FormGroup row>
	        			<Label>Current Password</Label>
	        			<Input type="password" value={formVals.currentPw || ""} onChange={e => setFormValue({field:"currentPw",value:e.target.value})} />
	        		</FormGroup>
	        		<FormGroup row>
	        			<Label>New Password</Label>
	        			<Input type="password" value={formVals.newPw || ""} onChange={e => setFormValue({field:"newPw",value:e.target.value})} />
	        		</FormGroup>
	        		<FormGroup row>
	        			<Label>Re-Type New Password</Label>
	        			<Input type="password" value={formVals.newPw2 || ""} onChange={e => setFormValue({field:"newPw2",value:e.target.value})} />
	        		</FormGroup>
	        		<Button color="primary" onClick={() => save()}>
	        			Update Password
	        		</Button>
	        	</Form>
	        	</Card>
	        </Container>

	);
}