import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';
 

export default gql`
{
  permits {
    ...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;