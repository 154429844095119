import React, {useEffect} from 'react';
import { Table, Alert, Spinner, Button} from 'reactstrap';

import moment from 'moment';

import { useQuery, useLazyQuery } from '@apollo/client';
import Inspections from '../../graphql/queries/Inspections'
import InspectionReportPDF from '../../graphql/queries/InspectionReportPDF'

export default function InspectionsTable({offset=0,limit=5, filterFields, cardHeight}) {
	const {data,loading,error,refetch} = useQuery(Inspections,{
      variables: {
        ...filterFields,
        offset,
        limit
      }
    });
  const [inspectionReportPDF,{loading:reportLoading}] = useLazyQuery(InspectionReportPDF,{
    fetchPolicy:'network-only',
    onCompleted: (result) => {
      window.open(result.inspectionReportPDF.url)
    },
    onError: () => alert("There was an error loading the PDF. Please try again.")

  });
  useEffect(() => {
    refetch()
  },[filterFields,refetch])

  const runReport = (id) => {
    inspectionReportPDF({variables:{id}});
  }


	if (loading) return null;
	if (error) return <Alert danger>Error loading inspections</Alert>

	return (
        <div className="table-responsive" style={cardHeight ? {height:cardHeight} : {}}>
            <Table>
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Date</th>
                  <th className="text-center">Odometer</th>
                  <th className="text-center"># Defects</th>
                  <th className="text-center">Out of Service?</th>
                  <th>Reported By</th>
                  <th>Get PDF</th>
                </tr>
              </thead>
              <tbody>
                {data.inspections.map(({id,truck:{type,identifier},odometer,defects,outOfService,reportedBy,reportDateTime}) => 
                  <tr>
                    <td>{type} {identifier}</td>
                    <td>{moment(reportDateTime).format('LLL')}</td>
                    <td className="text-center">{odometer}</td>
                    <td className="text-center">{defects.length}</td>
                    <td className="text-center" style={outOfService ? {backgroundColor: 'red',color:'#fff'} : {}}>{outOfService ? 'Yes' : 'No'}</td>
                    <td>{reportedBy?.firstName} {reportedBy?.lastName}</td>
                    <td>{reportLoading ? <Spinner /> : <Button color="primary" onClick={() => runReport(id)}>Get PDF</Button>}</td>
                  </tr>

                )}
                {(!data.inspections || data.inspections.length === 0) && <tr><td colSpan="100%">No reports found</td></tr>}
              </tbody>
            </Table>


        </div>
	);
}