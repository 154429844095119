import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert, Form, FormGroup, Label, Row, Col, Input} from 'reactstrap';

import {useMutation,useQuery} from '@apollo/client';
import AddInventoryItem from '../../graphql/mutations/AddInventoryItem';
import Inventory from '../../graphql/queries/Inventory'

import InventoryCategories from '../../graphql/queries/InventoryCategories';


export default function InventoryAdd({id,vendor,partDescription,partNumber,cost,modalVisible,setModalVisible,refreshParams}) {
	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});


	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}

  const {data} = useQuery(InventoryCategories);
  const [addInventoryItem,{loading}] = useMutation(AddInventoryItem,{


    onError: error => {
      setErrors([error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error adding the part. Please try again.'])

    }

  });
	useEffect(() => {
			setFormVals({
				partNumber,
				vendor,
				partDescription,
				cost:cost ? cost.toString() : null
			})

	},[modalVisible]);


  const save = () => {
    const {partDescription,partName,partNumber,vendor,numberInStock,warningThreshold,barcodeData,barcodeType,cost,location,category} = formVals;

    // validate
      let errors = [];

      // are cost and quantity numbers
      if (cost && isNaN(cost)) {
        errors.push("Please enter a number for cost or leave empty")
      }

      if (numberInStock && numberInStock.trim().length > 0 && isNaN(numberInStock)) {
        errors.push("Please enter a number for quantity or leave empty")
      }
      // must have a vendor
      if (!vendor || vendor.trim().length === 0) {
        errors.push("Please enter a vendor")  
      }

      if (errors.length > 0) {
        setErrors(errors);
        return;
      }

    addInventoryItem({
      variables: {
        
        partName,
        location,
        partNumber,
        vendor,
        partDescription,
        barcodeData,
        barcodeType,
        cost: cost ? parseFloat(cost) : null,
        numberInStock: numberInStock ? parseFloat(numberInStock) : null,
        warningThreshold: warningThreshold ? parseFloat(warningThreshold) : null,
        category: category ? parseInt(category) : null
      },
      refetchQueries:() => [
        {query:Inventory,variables:refreshParams}
        
      ],


    })
      .then(result => {
        // only close if valid result returned
        if (result) {
          setModalVisible(false);
        }
        
       })
     .catch(err => alert("Add Part Error","There was an error adding the part. Please try again")) 
    
  }



  return (
      <Modal size="lg" isOpen={modalVisible} toggle={() => setModalVisible(false)}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add Part</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
	        <Form>
            <Row>
              <Col md={6}>
                <FormGroup >
                  <Label>Part Name</Label>
                  <Col>
                 <Input value={formVals.partName || ""} onChange={e => setFormValue({field:"partName",value:e.target.value})} />
                    </Col>
                </FormGroup>
                <FormGroup >
                  <Label>Vendor</Label>
                  <Col>
                 <Input value={formVals.vendor || ""} onChange={e => setFormValue({field:"vendor",value:e.target.value})} />
                    </Col>
                </FormGroup>
  	            <FormGroup >
  	          		<Label >Part Description</Label>
  	          		<Col>
  				       <Input value={formVals.partDescription || ""} onChange={e => setFormValue({field:"partDescription",value:e.target.value})} />
  	              	</Col>
  	            </FormGroup>
  	            <FormGroup >
  	          		<Label>Part Number</Label>
  	          		<Col>
  				       <Input value={formVals.partNumber || ""} onChange={e => setFormValue({field:"partNumber",value:e.target.value})} />
      	          </Col>
  	            </FormGroup>
               </Col>
               <Col md={6}>
                <FormGroup >
                  <Label>Category</Label>
                  <Col>
                     <Input type="select" value={formVals.category || ""} onChange={e => setFormValue({field:"category",value:e.target.value})}>
                       <option value="">- Select Category -</option>
                       {data?.inventoryCategories.map(({id,category}) => <option key={id} value={id}>{category}</option>)}
                     </Input>
                    </Col>
                </FormGroup>
                <FormGroup >
                  <Label>Quantity</Label>
                  <Col>
                 <Input type="number" value={formVals.numberInStock || ""} onChange={e => setFormValue({field:"numberInStock",value:e.target.value})} />
                    </Col>
                </FormGroup>
  	            <FormGroup >
  	          		<Label>Cost</Label>
  	          		<Col>
  				       <Input type="number" value={formVals.cost || ""} onChange={e => setFormValue({field:"cost",value:e.target.value})} />
  	              	</Col>
  	            </FormGroup>
  	            <FormGroup >
  	          		<Label >Alert Threshold (when to reorder)</Label>
  	          		<Col>
  				       <Input type="number" value={formVals.warningThreshold || ""} onChange={e => setFormValue({field:"warningThreshold",value:e.target.value})} />
  	              	</Col>
  	            </FormGroup>
  	            <FormGroup >
  	          		<Label>Part Location</Label>
  	          		<Col>
  				       <Input value={formVals.location || ""} onChange={e => setFormValue({field:"location",value:e.target.value})} />
  	              	</Col>
  	            </FormGroup>
              </Col>
            </Row>
	        </Form>




        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Save
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

  );

}
