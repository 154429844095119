import React, {useState} from 'react';

import {Container, Row, Col, Card, CardBody,CardHeader, CardTitle,Table,Label,Input,  FormGroup, Button} from 'reactstrap';
//import DateTimePicker from '@react-native-community/datetimepicker';
import moment from 'moment';

import DateTime from 'react-datetime';
//import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

import AsyncSelect from 'react-select/async';

import {useQuery} from '@apollo/client';
import Suppliers from '../../graphql/queries/Suppliers';

import Inventory from '../../graphql/queries/Inventory';

import AddSupplierModal from './AddSupplierModal';

export default function PurchaseOrderForm({navigation,save}) {
	const [formFieldValues,setFormFieldValues] = useState({poDate:moment()});
    const [supplierName,setSupplierName] = useState()

	const [poItems,setPoItems] = useState([])
	const [modalVisible_supplier,setModalVisible_supplier] = useState(false)

	const [selectedPart,setSelectedPart] = useState({})
	//const [inventoryItem,setInventoryItem] = useState();
	//const [quantity,setQuantity] = useState();

	const [fetchVars,setFetchVars] = useState({offset:0,limit:0});

	//const [selectedPart,setSelectedPart] = useState();

	const {data:dataSuppliers} = useQuery(Suppliers);

	// get inventory (uselazyquery doesn't return a promise which is needed by AsynSelect)
	const {refetch}  = useQuery(Inventory,{
		variables: fetchVars,
		fetchPolicy:'network-only'
	})
	//const [getInventory,{data:dataInventory,loading}]  = useLazyQuery(Inventory)


  	const setValue = ({name,value}) => {
  		setFormFieldValues({...formFieldValues,[name]:value})
  	}


	// need to make this more efficient. too many api hits
	const promiseOptions = inputValue =>
	  new Promise(resolve => {

	    setTimeout(() => {
	    	if (inputValue.trim().length === 0) {
		   		setFetchVars({...fetchVars,offset:0,limit:0})
	    	}
	    	else {
		   		setFetchVars({...fetchVars,offset:0,limit:20,keyword:inputValue})
	    	}
		    refetch({variables:fetchVars})
		    	.then(result => {
		    		// go through parts and inventory items to build array
		    		let returnArray = [];
		    		result.data.inventory.forEach(inventory => 
		    			inventory.inventoryItems.forEach(inventoryItem => {
		    				returnArray.push({
		    					partName:inventory.partName,
		    					partNumber:inventoryItem.partNumber,
		    					vendor:inventoryItem.vendor,
		    					label:`${inventory.partName} - ${inventoryItem.vendor} ${inventoryItem.partNumber ? `- ${inventoryItem.partNumber}` : ""}`,
		    					value:inventoryItem.id
		    				});
		    			})
		    		)
		    		
		    		resolve(returnArray);
		    	})
	    	      
	    }, 1000);
	    
	    
	    	

	  });

	  const addInventoryItem = () => {
	  	if (!selectedPart.inventoryItem) {
	  		return;
	  	}

	  	if (!selectedPart.quantity || isNaN(selectedPart.quantity)) {
	  		alert("Please enter a number for quantity");
	  		return;
	  	}

	  	 // add item to poItems.
	  	 setPoItems([...poItems,{item:{...selectedPart.inventoryItem,id:selectedPart.inventoryItem.value},quantity:selectedPart.quantity}]);
	  	
	  	 // clear inventory form
	  	 setSelectedPart({});
	  	 //setQuantity(null);
	  	 //setInventoryItem(null);
	  }

    const handleSupplierChange = (newValue, actionMeta) => {
    	

      // if actionMeta.action === create-option, then show add customer modal.
      if (actionMeta.action === 'create-option') {
      	//setValue({name:'supplier',value:null})
      	console.log("supplierName",newValue.label)
      	setSupplierName(newValue.label);
        setModalVisible_supplier(true);
      }
      if (actionMeta.action === 'clear') {
      	setValue({name:'supplier',value:null})
      }

      if (actionMeta.action === 'select-option') {
        setValue({name:'supplier',value:{label:newValue.label,value:newValue.value}})

      }

    };


	return (
      <Container  fluid>
        	<h2>New Purchase Order</h2>
            <Card className="shadow mb-4">
            	<CardBody>
					<FormGroup row>
						<Label sm={2}>PO Date</Label>
						<Col sm={10}>
							<DateTime timeFormat={false} value={formFieldValues.poDate} onChange={date => setValue({name:"poDate",value:date})} />
							{/*<Input type="date" value={formFieldValues.poDate} onChange={e => setValue({name:"poDate",value:e.target.value})} />*/}
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={2}>PO #</Label>
						<Col sm={10}>
							<Input type="text" value={formFieldValues.poNumber} onChange={e => setValue({name:"poNumber",value:e.target.value})} />
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={2}>Supplier</Label>
						<Col sm={10}>
			                 {/*<Select
							        defaultValue={formFieldValues.supplier}
							        
							        placeholder="Select Supplier...."
							        onChange={value => setValue({name:'supplier',value:{supplierName:value.label,id:value.value}})}
							        options={dataSuppliers?.suppliers?.map(({supplierName,id}) => ({label:supplierName,value:id}))}
							      />*/}
					        <CreatableSelect
					          isClearable
					          value={formFieldValues?.supplier}
					          defaultValue={formFieldValues.supplier}
					          onChange={handleSupplierChange}
					          //onInputChange={handleInputChange}
					          options={dataSuppliers?.suppliers?.map(({supplierName,id}) => ({label:supplierName,value:id}))}
					           
					           placeholder="Select Supplier...."
					           formatCreateLabel={value => `Add new supplier "${value}"`}
					        />

						</Col>
					</FormGroup>

				</CardBody>
			</Card>
			<Card>
				<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle>Items</CardTitle></CardHeader>
				<CardBody>
					<Row>
						<Col md={3}>
								<strong>Find Parts</strong>
								<FormGroup>
									<AsyncSelect placeholder="Find inventory item"
												 loadOptions={promiseOptions}
												 value={selectedPart.inventoryItem || null}
												 onChange={value => setSelectedPart({...selectedPart,inventoryItem:value})}
												 />  
								</FormGroup>
								<FormGroup>
								<Input type="number" value={selectedPart.quantity || ""} onChange={e => setSelectedPart({...selectedPart,quantity:e.target.value})} placeholder="Quantity" />
								</FormGroup>
								<Button color="primary" block onClick={() => addInventoryItem()}>Add</Button>
							   

						</Col>
						<Col md={9}>

							<Table>
								<thead>
									<tr>
										<th>Item</th>
										<th>Quantity</th>
										<th>Remove</th>
									</tr>
								</thead>
								<tbody>
									{poItems.map(({item:{partName,vendor,partNumber},quantity},index) => 
										<tr key={index}>
											<td>
												<span style={{fontWeight:500}}>{partName}</span><br />
												<span>{vendor}</span><br />
												<span className="text-muted">{partNumber}</span>
											</td>
											<td>
												{parseFloat(quantity).toLocaleString()}
											</td>
											<td>
												<Button color="link" onClick={() => {
													// copy existing array
													let newArray = [...poItems];
													newArray.splice(index, 1) ;
													setPoItems(newArray);

												}}>Remove</Button>
											</td>
										</tr>
								       
									)}
								</tbody>
							</Table>
							{poItems.length === 0 &&
								<p>No items added</p>
							}
						</Col>
					</Row>					
				</CardBody>
			</Card>
			<div className="p-4 text-center">
			  <Button color="primary" onClick={() => save({...formFieldValues,poDate:moment(formFieldValues.poDate),poItems})} >
	          	Save Purchase Order
	          </Button>
	        </div>

	    	<AddSupplierModal 
	    		supplierName={supplierName}
	    		modalVisible={modalVisible_supplier}
	    		setModalVisible={setModalVisible_supplier}
	    		onSupplierAdded={({supplierName,id}) =>  setValue({name:'supplier',value:{label:supplierName,value:id}})}
	    	/>
	</Container>


	);
}