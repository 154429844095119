import React, {useContext} from 'react';

import './App.css';



import publicRoutes from "./routes_public";
import indexRoutes from "./routes";
import driverRoutes from "./routes_driver";


import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";

import AuthContext from './contexts/AuthContext';
import Navbar from "./components/Navbar";




function App() {

  // limit driver options
  const {roles,isLoggedIn} = useContext(AuthContext);

  // prevent redirecting to login or admin when refreshing browser. 
  if (typeof isLoggedIn === 'undefined') return null;


  // drivers (and anyone without roles) can only submit work orders. Could reverse the if statements but this is easier to read for now/logic is clearer
  let displayRoutes;
  if (roles && roles.length === 1 && roles[0].toLowerCase() === 'driver') {
    displayRoutes = driverRoutes;
  }
  else if (roles && roles.length === 0) {
    displayRoutes = driverRoutes;
  }
  else if (roles && roles.length > 0) {
    displayRoutes = indexRoutes
  }


  // if not logged in, only show login, signup and forget password
  if (!isLoggedIn) {
    return (
         <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
              {publicRoutes.map((prop, key) => {
                return <Route path={prop.path} key={key} component={prop.component} />;
              })}
            
          </Switch>
        </BrowserRouter>
    )
  }




  return (
       <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div className="h-100" >
          <Navbar />
          
            <Switch>
              {displayRoutes.map((prop, key) => {
                return <Route path={prop.path} key={key} component={prop.component} />;
              })}
            </Switch>
          
        </div>
      </BrowserRouter>
  );
}

export default App;
