import React from 'react';
import {Card, CardHeader,CardBody, Button, Spinner} from 'reactstrap';

export default function PhotoDisplay({photos,removePhoto,removing}) {
	return (
		<Card className="mb-2">
            <CardHeader>
              <h3>Photos {removing && <Spinner />}</h3>
            </CardHeader>
            <CardBody className="pt-0">	
            	{photos.map((photo,index) => 
            		<div key={index} className="mr-2" style={{display:"inline-flex",flexDirection:'column'}}>
	            		<a key={index}  href={photo.url} target="_blank" rel="noreferrer">
	            			{/* If there is not image,  try loading again*/}
	            			{photo.url ?
		            			<img alt="" src={photo.url} className="ml-2 mt-2" height="100" width="auto" onError={err => console.log("image load error",err)} />
		            			:
		            			<Spinner />
	            			}
	            		</a>
	            		{removePhoto &&
		            		<Button onClick={() => removePhoto(photo.id)} className="mt-1" color="link">remove</Button>
	            		}
	            	</div>
            		)}
            </CardBody>		
		</Card>
	)
}