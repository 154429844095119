import React, {useState} from 'react';


import {
	Container,
	Row,
	Col,
	Card,
    CardHeader,
	Spinner,

	ListGroup,
	ListGroupItem,

	Button
} from 'reactstrap';

import AssetTypeForm from '../../components/AccountSettings/AssetTypeForm';

import { useQuery, useMutation } from "@apollo/client";
import TruckTypes from "../../graphql/queries/TruckTypes";
import RemoveTruckType from '../../graphql/mutations/RemoveTruckType';


export default function AssetTypes() {

	const {data,loading,error} = useQuery(TruckTypes);
    const [removeTruckType,{loading:loadingRemove}] = useMutation(RemoveTruckType,{refetchQueries:[{query:TruckTypes}]});
    const [currentTruckType,setCurrentTruckType] = useState();

	if (loading) return <Spinner />

    const remove = (type) => {

        
        setCurrentTruckType(type);
        removeTruckType({variables:{type}});
    }


	return (
        <Container  fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
          		
            	<Card>
                    <CardHeader >
                    <Row>
                        <Col sm={12}>
                            <AssetTypeForm />
                        </Col>
                        
                    </Row>
                    </CardHeader>

            		{error && <span>There was a problem loading truck types. Please try again.</span>}
            		<ListGroup>
	            		{data && data.truckTypes && data.truckTypes.map(({type,measure}) => 
	            			<ListGroupItem key={type}>
                                {type} ({measure})
                                {loadingRemove && currentTruckType === type ?
                                   <Spinner className="ml-1" />
                                   :
                                    <Button color="link" className="ml-1" onClick={() => remove(type)}>
                                        remove
                                    </Button>

                                }
                             </ListGroupItem>
	            		)}
            		</ListGroup>
            	</Card>
            </Col>
          </Row>
        </Container>

	);
}