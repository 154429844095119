import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';

export default gql`
mutation AddWorkOrderPart(
  $workOrderId:ID!
  $inventoryItemId:Int!
  $quantity: Float!
  ) {
    addWorkOrderPart(
      workOrderId:$workOrderId,
      inventoryItemId:$inventoryItemId,
      quantity: $quantity
    ) {
      ...WorkOrderAllFields
    }
}
${WORK_ORDER_FRAGMENT}
`;