import React from 'react';

import {
	Container,

	Card,
	CardBody,
	Spinner,
	Button,
    Row,
    Col
} from 'reactstrap';

import InventoryTable from '../components/Inventory/MorningRushInventoryTable';

export default function InventoryMorningRush() {
	return (
		<Container fluid className="h-100">
			<h2>Morning Rush</h2>
			<InventoryTable offset={0} limit={100} filterFields={{}} />
		</Container>
	);
}