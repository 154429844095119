// gqlFragments.js
import gql from 'graphql-tag';

export const WORK_ORDER_FRAGMENT = gql`
    fragment WorkOrderAllFields on WorkOrder {
      id
      avoidableExpense
      mileageIn
      truck {
        id
        identifier
        type
      }
      title
      description
      reportedBy {
        firstName
        lastName
      }
      status
      priority
      ticketNumber
      outOfService
      createdAt
      assignedTo {
        id
        firstName
        lastName
      }
       enteredBy {
          firstName
          lastName
        }
  
      photos {
        url
        height
        width
      }
      log {
        id
        createdAt
        enteredBy {
          firstName
          lastName
        }
        note,
        noteType,
        status,
        photos {
          url
          height
          width
        }

        file {
          width
          height
        }

      }
      partsUsed {
        id
        partName
        partNumber
        partDescription
        vendor
        quantity
      }      


    }
`;