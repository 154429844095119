import React, {useState,useContext} from 'react';
import { Link,useHistory } from 'react-router-dom';

// reactstrap components
import {
  Spinner,
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Button,

  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import Icon from '@mdi/react'
import { mdiDownload, mdiCamera, mdiDelete} from '@mdi/js'

// core components

import EquipmentDetailCard from '../components/Assets/EquipmentDetailCard';
import UdfsCard from '../components/Assets/UdfsCard';
import PartsUsedCard from '../components/Assets/PartsUsedCard';
import ServiceHistoryCard from '../components/Assets/ServiceHistoryCard';
import WorkOrdersTable from '../components/WorkOrders/WorkOrdersTable';
import InspectionsTable from '../components/Inspections/InspectionsTable';
import Pagination from '../components/WorkOrders/Pagination';
import PhotoDisplay from "../components/WorkOrders/PhotoDisplay";

import AddPhotosModal from '../components/Assets/AddPhotosModal';
import CorrectOdometerModal from '../components/Assets/CorrectOdometerModal';
import OdometerModal from '../components/Assets/OdometerModal';
import TruckOutOfServiceModal from '../components/Assets/TruckOutOfServiceModal';

import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import Truck from '../graphql/queries/Truck';

import AuthSection from '../components/AuthSection';
import TruckPDF from '../graphql/queries/TruckPDF';
import RemoveTruckPhoto from '../graphql/mutations/RemoveTruckPhoto';

import AuthContext from '../contexts/AuthContext'; 

import AssetWorkOrderHistoryChart from "../components/Assets/AssetWorkOrderHistoryChart.js";


export default function AssetDetails({match:{params:{id}}}) {
    const history = useHistory();
    const limit = 20;
    const [offset,setOffset] = useState(0);
    const [offset_inspections,setOffset_inspections] = useState(0);
    const [modalVisibleAddPhotos,setModalVisibleAddPhotos] = useState(false);
    const [modalVisibleOdometer,setModalVisibleOdometer] = useState(false);
    const [modalVisibleSetOdometer,setModalVisibleSetOdometer] = useState(false);
    const [modalVisibleOutOfService,setModalVisibleOutOfService] = useState(false);
    const [actionDropdownOpen,setActionDropdownOpen] = useState(false)

    const {features} = useContext(AuthContext);

    const {data,loading,error,refetch} = useQuery(Truck,{variables:{id}});



    const [getTruckPDF,{loading:pdfLoading}] = useLazyQuery(TruckPDF,{
      fetchPolicy:"network-only",
      onCompleted: (result) => window.open(result.truckPDF.url),
      onError: err => console.log("pdf error",err)
    });

    const [removeTruckPhoto,{loading:removingPhoto}] = useMutation(RemoveTruckPhoto,{
      refetchQueries:[{query: Truck,variables:{id}}]
    });



    if (loading) return <Spinner />
    if (error) return <Container>There was an error loading the asset. Please try refreshing this page.</Container>



    return (


        <Container className="mt--8" fluid>
                      <ButtonDropdown  className="float-right" direction="left" isOpen={actionDropdownOpen} toggle={() => setActionDropdownOpen(!actionDropdownOpen)}>
                        <DropdownToggle caret size="md"  color="primary">
                          Actions
                          {pdfLoading && <Spinner size="sm" />}
                        </DropdownToggle>
                        <DropdownMenu>
                          <AuthSection>
                            <DropdownItem onClick={() => getTruckPDF({variables:{id}})}>
                              <Icon path={mdiDownload} size={1} style={{display:"inline"}} className="mr-1" />
                              Download Report
                            </DropdownItem>
                            
                          </AuthSection>        
                          <DropdownItem color="primary" onClick={() => setModalVisibleAddPhotos(true)}>
                            <Icon path={mdiCamera} size={1} style={{display:"inline"}} className="mr-1" />
                            Add Asset Photos
                          </DropdownItem>  
                          

                          <DropdownItem onClick={() => setModalVisibleSetOdometer(true)}>
                            
                            Set Odometer Reading
                          </DropdownItem>
                              
       
                          
                          {features?.find(f => f === "odometerCorrection") && 
                            <AuthSection>
                              <DropdownItem onClick={() => setModalVisibleOdometer(true)}>
                                
                                Correct Odometer Reading
                              </DropdownItem>
                              
                            </AuthSection>        
                          }
                          <AuthSection>
                            <DropdownItem onClick={() => setModalVisibleOutOfService(true)}>
                              <Icon path={mdiDelete} size={1} style={{display:"inline"}} className="mr-1" />
                              Remove from Service
                            </DropdownItem>

                          </AuthSection>

                         </DropdownMenu>
                      </ButtonDropdown> 

          {/*<AuthSection>
            <Button onClick={() => getTruckPDF({variables:{id}})} className="float-right">
              <Icon path={mdiDownload} size={1} style={{display:"inline"}} className="mr-1" />
              Download Report
              {pdfLoading && <Spinner size="sm" className="ml-2" />}
            </Button>  
          </AuthSection>        
            <Button color="primary" onClick={() => setModalVisibleAddPhotos(true)} className="float-right mr-2">
              <Icon path={mdiCamera} size={1} style={{display:"inline"}} className="mr-1" />
              Add Asset Photos
          
            </Button> */} 

          <h2>
            {data?.truck?.type} {data?.truck?.identifier}
            <AuthSection>
              <Link to={`/Asset/edit/${id}`} className="ml-2 btn btn-link" style={{fontSize: 14}}>
                Edit Asset
              </Link>
            </AuthSection>
          </h2>



          <EquipmentDetailCard asset={data?.truck} />
          <ServiceHistoryCard id={id} lastReportedMileage={data?.truck?.lastReportedMileage} />
          <AssetWorkOrderHistoryChart truck={id} />
         {data?.truck?.photos?.length > 0 && <PhotoDisplay photos={data.truck.photos} removing={removingPhoto} removePhoto={photoId => removeTruckPhoto({variables:{id,photoId}})} />}
          {data?.truck?.udfs && <UdfsCard udfs={data.truck.udfs} />}
          <PartsUsedCard id={id} />

          <Card className="shadow mb-4">
            <CardHeader className="pb-0" style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
              <CardTitle className="m-0 float-left">
                Work Orders
              </CardTitle>
                  <Pagination 
                    onPrev={() => {
                      if (offset > 0)
                        setOffset(offset-limit)
                    }}
                    onNext={() => setOffset(offset+limit)}
                  />

            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <Col>
                  <WorkOrdersTable offset={offset} limit={limit} filterFields={{truck:id}} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card className="shadow mb-4">
            <CardHeader className="pb-0" style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
              <CardTitle className="m-0 float-left">
                Inspections
              </CardTitle>
                  <Pagination 
                    onPrev={() => {
                      if (offset_inspections > 0)
                        setOffset_inspections(offset_inspections-limit)
                    }}
                    onNext={() => setOffset_inspections(offset_inspections+limit)}
                  />

            </CardHeader>
            <CardBody className="pt-0">
              <Row>
                <Col>
                  <InspectionsTable offset={offset_inspections} limit={limit} filterFields={{trucks:[id]}} />
                </Col>
              </Row>
            </CardBody>
          </Card>

          <TruckOutOfServiceModal modalVisible={modalVisibleOutOfService} setModalVisible={setModalVisibleOutOfService} id={id} onSuccess={() => history.goBack()} />
          <AddPhotosModal modalVisible={modalVisibleAddPhotos} setModalVisible={setModalVisibleAddPhotos} assetId={id} refetch={refetch} />
          <CorrectOdometerModal modalVisible={modalVisibleOdometer} setModalVisible={setModalVisibleOdometer} id={id} />
          <OdometerModal modalVisible={modalVisibleSetOdometer} setModalVisible={setModalVisibleSetOdometer} id={id} />
        </Container>

  
    );
  
}


