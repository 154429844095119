import React from 'react';
import {Modal,ModalHeader,ModalBody,ModalFooter,  Button,Spinner} from 'reactstrap';


import {useMutation} from '@apollo/client';
import RemovePermit from '../../graphql/mutations/RemovePermit';
import Permits from '../../graphql/queries/Permits';


export default function RemovePermitModal({permit,permitName,assignedTo,onComplete,modalVisible,setModalVisible}) {



  const [removePermit,{loading:updating}] = useMutation(RemovePermit, {
    refetchQueries: [{query: Permits}]
  });

    const save = () => {

      removePermit({variables:{
        permit,
        
      }})
      .then(() => setModalVisible(false))



     }

     if (modalVisible === false) return null

  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Remove Permit</ModalHeader>
        <ModalBody>
          <p>Are you sure that you want to remove {permitName}?</p>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>Yes</Button>
          <Button color="warning" onClick={() => setModalVisible(false)}>No</Button>
          {updating && <Spinner />}
        </ModalFooter>
      </Modal>


  );

  

}

