import gql from 'graphql-tag';

export default gql`
query PurchaseOrder($id: Int!) {
  purchaseOrder(id:$id) {
    id
    cancelled
    poDate
    poNumber
    supplier {
      id
      supplierName
    }
    items {
      id
      partName
      partNumber
      vendor
      quantityOrdered
      quantityReceived
      dateReceived
    }
  }
}
`;