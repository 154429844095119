import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

export default function WelcomeModal() {
	const [modalVisible,setModalVisible] = useState(true);

	return (
      <Modal  isOpen={modalVisible} toggle={() => setModalVisible(false)}>
       	<ModalHeader toggle={() => setModalVisible(false)}><h3>Welcome to Fleet Command</h3></ModalHeader>
        <ModalBody>
			<div style={{position:"relative",height:0,width:"100%",paddingBottom:"56.19834710743802%"}}><iframe title="Welcome to Fleet Command" src="https://sendspark.com/embed/eebpnklm26ftcdsmr3emhcqebl9mec6b" frameBorder="0" style={{position:"absolute",width:"100%",height:"100%",borderRadius:6,left:0,top:0}} ></iframe></div>
        </ModalBody>
        <ModalFooter style={{justifyContent:'center'}}>
        	<Link to="/Asset/New"><Button color="primary">Add Asset</Button></Link>
        </ModalFooter>
      </Modal>
	);	
}

