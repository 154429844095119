import gql from 'graphql-tag';

import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
mutation AddTruckPhotos($id:ID!,$photos:[FileTypeInput!]) {
	addTruckPhotos(id:$id,photos:$photos) {
		...TruckAllFields
		photosToUpload {
			url
		}
	}
}
${TRUCK_FRAGMENT}
`;


