import React from 'react';

import { useQuery } from '@apollo/client';
import MyInfo from '../graphql/queries/MyInfo';
 
// takes  roles to be checked against and sees if user matches
function AuthSection({children,authRoles=['Owner','Office Manager','Shop Manager'],renderAlternateView=<div />}) {
	// pull from query for now. in the future, move roles to context. Though gql cache may be fine
	const {data,loading,error} = useQuery(MyInfo)


	if (loading || error) {
		return <div />;
	}

	// is user in valid role?
	const isAuth = data.me.roles.find(role => authRoles.includes(role));

	// if in valid role, return children. Else, display nothing
	if (isAuth) {
		//console.log("the children",children)
		return (
			<>{children}</ >
		);
	}
	else {
		return renderAlternateView
	}
}

export default AuthSection;
