import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,

} from 'reactstrap';

import {useQuery} from '@apollo/client';
import InventoryCategories from '../../graphql/queries/InventoryCategories';


export default function SelectCategory({filterFields,setFilterFields}) {

  const [actionDropdownOpen,setActionDropdownOpen] = useState(false)
  const [categoryLabel,setCategoryLabel] = useState("Category Filter ")

	const {data,loading} = useQuery(InventoryCategories);

  useEffect(() => {
    // if fields change, see if selected category changed
    const catLabel = data?.inventoryCategories.find(e => e.id === filterFields.category);
    setCategoryLabel(catLabel?.category || "Category Filter")
    
  },[filterFields])

	return (
		<>
          <ButtonDropdown className="ml-2"  isOpen={actionDropdownOpen} toggle={() => setActionDropdownOpen(!actionDropdownOpen)}>
            <DropdownToggle caret color="info">
              {categoryLabel}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right" >
              <DropdownItem  onClick={() => setFilterFields({...filterFields,category:null})}>All</DropdownItem>
              {data?.inventoryCategories.map(({category,id}) => <DropdownItem key={id} onClick={() => setFilterFields({...filterFields,category:parseInt(id)})}>{category}</DropdownItem>)}
            </DropdownMenu>
          </ButtonDropdown>              



		</>
	);
}