import React from 'react';
import {  Spinner, Alert, Card, CardHeader, CardBody,CardTitle, Table } from "reactstrap";

import Icon from '@mdi/react'
import { mdiPlus,mdiCheckCircleOutline,mdiRun,mdiAlertOctagonOutline} from '@mdi/js'



//import { useQuery } from '@apollo/client';
//import WorkOrderStats from "../../graphql/queries/WorkOrderStats";

export default function WorkOrderStatsView({data,loading,error}) {
  	//const {data,loading,error} = useQuery(WorkOrderStats);

  	if (loading) return <Spinner />

  	if (error) return <Alert variant="danger">Couldn't load work order stats</Alert>
  	// compile data for output
  	let OutOfService = 0;

  	const {workOrderStats} = data;
  	workOrderStats.forEach(({outOfServiceCount}) => OutOfService+=outOfServiceCount);

  	const OpenCount = workOrderStats.find(el => el.label === 'Open')
  	const ReOpenCount = workOrderStats.find(el => el.label === 'Re-Open')
  	const CheckedInCount = workOrderStats.find(el => el.label === 'Checked In')
  	const InProgressCount = workOrderStats.find(el => el.label === 'In Progress')
  	const OnHoldCount = workOrderStats.find(el => el.label === 'Work On Hold')

	const workOrdersByStatus = [
		{
			status:"Open",
			workOrders: (OpenCount ? OpenCount.count : 0) + (ReOpenCount ? ReOpenCount.count : 0),
			style:'bg-primary',
      color: "#5e72e4",
			icon:mdiPlus
		},
		{status:"Checked In",color: "#28a842",workOrders:CheckedInCount ? CheckedInCount.count : 0, style:'bg-info',icon:mdiCheckCircleOutline},
		{status:"In Progress",color: "#fb6340",workOrders:InProgressCount ? InProgressCount.count : 0, style:'bg-warning',icon:mdiRun,iconType:'MaterialCommunityIcons'},
		{status:"On Hold",color: "#cccccc",workOrders:OnHoldCount ? OnHoldCount.count : 0,style:'bg-success',icon:mdiAlertOctagonOutline,iconType:"Octicons"}
	];

	return (
       <Card className="shadow mb-4" style={{height: 250}}>
        <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Work Order Status</CardTitle></CardHeader>
        <CardBody className="p-0" style={{overflowY:"auto"}}>
          <Table className="mb-0">
            <tbody>
              {workOrdersByStatus.map(({status,workOrders,icon,color,iconType}) => 
                 <tr key={status}>
                   <td className="pl-4">
                      <Icon path={icon} size={1} style={{color}}  />
                      <span className="pl-2">{status}</span>
                   </td>
                   <td>{workOrders}</td>
                 </tr>

              )}

            </tbody>
          </Table>
        </CardBody>
      </Card>



	);
}



/*
      <>
        <div className="header bg-gradient-info pb-8 pt-6 pt-md-6">
          <Container fluid>
            <div className="header-body">
              
              <Row>
        {workOrdersByStatus.map(({status,workOrders,icon,style,iconType}) => 
                  <Col key={status} lg="6" xl="3">
                    <StatsCard title={status}
                           count={workOrders}
                           icon={icon}
                           bg={style}

                    />
                  </Col>
        )}
              </Row>
            </div>
          </Container>
        </div>
      </>
*/
