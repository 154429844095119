import gql from 'graphql-tag';

export default gql`
mutation IncreaseInventory(
    $id: Int!,
    $quantity: Float!,
    $note: String
) {
  increaseInventory(
    id: $id,
    quantity: $quantity,
    note: $note  
    ) {

        id
        partName
        numberInStock
        warningThreshold
        inventoryItems {
          id
          partNumber
          vendor
          numberInStock
          barcodeType
          barcodeData
          partDescription
          
    }


  }
}

`;


