import gql from 'graphql-tag';

export default gql`
query Inspections(
  $dateStart: DateTime,
  $dateEnd: DateTime,
  $trucks: [ID],
  $offset: Int, 
  $limit: Int
)  {
  inspections(
    dateStart:$dateStart,
    dateEnd:$dateEnd,
    trucks:$trucks,
    offset:$offset,
    limit:$limit
  ) {
    id
    truck {
      id
      identifier
    }
    reportDateTime,
    reportedBy {
      id
      firstName
      lastName
    }
    odometer
    noDefectsFound
    outOfService
    defects {
      id  
    }
    outOfService
  }
}
`;
