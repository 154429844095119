import React, {useState} from 'react';

const AppContext = React.createContext();



export const AppProvider = ({children}) => {
	
	const [dashboardDates,setDashboardDates] = useState()
	const [dashboardTimeFrame,setDashboardTimeFrame] = useState()


	return <AppContext.Provider value={{
			dashboardDates,
			setDashboardDates,
			dashboardTimeFrame,
			setDashboardTimeFrame
		}}>
		{children}
	</AppContext.Provider>

}


export default AppContext;