import gql from 'graphql-tag';

export default gql`
mutation AddInspectionTemplate(
    $templateName: String!,
    $odometerRequired: Boolean,
    $appliesTo: [String],
    $isPretrip: Boolean
   )  {
  addInspectionTemplate (
      templateName: $templateName,
      odometerRequired: $odometerRequired,
      appliesTo: $appliesTo,
      isPretrip: $isPretrip
    ) {
    id
    templateName
    appliesTo
    odometerRequired
    isPretrip
    fields {
      id
      fieldName
      fieldType
      createWorkOrderOnFail
    }
  }
}
`;

