import React, { useState } from 'react';
import {Modal, ModalHeader, ModalBody, Button,Spinner, Alert, Form, FormGroup, Label, Col, Input,ListGroup,ListGroupItem} from 'reactstrap';

import { useQuery, useMutation } from '@apollo/client';
import InventoryCategories from '../../graphql/queries/InventoryCategories';
import AddInventoryCategory from '../../graphql/mutations/AddInventoryCategory';

export default function InventoryCategoriesModal({modalVisible,setModalVisible}) {



	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});

	const {data} = useQuery(InventoryCategories);
	const [addInventoryCategory,{loading:adding}] = useMutation(AddInventoryCategory,{
		refetchQueries:[{query:InventoryCategories}]
	});

	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}

	const add = () => {
		setErrors([])
		addInventoryCategory({
			variables:{category:formVals.category,
			

		}})
			.then(() => setFormVals({}))
			.catch(error => setErrors(["There was a problem saving the category. Please try again"]))
	}




  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}   keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Inventory Categories</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}


	        <Form inline onSubmit={e => e.preventDefault()}>
	            <FormGroup >
	          		<Label>Category</Label>
	          		<Col>
				       <Input type="text" value={formVals.category || ""} onChange={e => setFormValue({field:"category",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Button onClick={() => add()}>Add {adding && <Spinner />}</Button>
	            </FormGroup>


	        </Form>

	        <ListGroup>
	        	{(!data || data.inventoryCategories.length === 0) && <div>No categories found</div>}
	        	{data?.inventoryCategories && data.inventoryCategories.map(({id,category}) =>
	        		<ListGroupItem key={id}>{category}</ListGroupItem>
	        	)}
	        </ListGroup>



        </ModalBody>

      </Modal>

  );

}
