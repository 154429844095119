// gqlFragments.js
import gql from 'graphql-tag';

export const TRUCK_FRAGMENT = gql`
    fragment TruckAllFields on Truck {
      id
      identifier
      type
      lastReportedMileage
      OutOfService
      PartsUsed
      OpenWorkOrders
      make
      model
      year
      vin
      licensePlate
      engineSerialNumber
      transmissionSerialNumber
      tireSize
      federalAnnualInspectionDueDate,
      registrationDueDate,
      location {
        id
        locationName
      }
      servicePlan {
        id
        planName
      }
      udfs {
          id
        fieldName
        fieldValue
        sortOrder
      }
      photos {
        id
        url
        height
        width
      } 
      lastReportedMileageHistory {
        id
        newMileage
        previousMileage,
        notes,
        createdAt
        changedBy {
          firstName
          lastName
        }
      }

    }
`;