import React, {useState} from 'react';

import {Alert, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button, Table, Form, FormGroup, Input, Label} from 'reactstrap';


import { useMutation } from '@apollo/client';
import ReceivePOItem from '../../graphql/mutations/ReceivePOItem';
import PurchaseOrder from '../../graphql/queries/PurchaseOrder';




export default function ReceiveInventoryModal({item,modalVisible,setModalVisible}) {
	  const {
	    id,
	    poId,
	    partName,
	    partNumber,
	    vendor,
	    quantityOrdered,
	    quantityReceived
	  } = item || {};


	const [formVars,setFormVars] = useState({});
	const [errorMessages,setErrorMessages] = useState([]);

	const [receivePOItem,{loading}] = useMutation(ReceivePOItem, {
		refetchQueries:[{query:PurchaseOrder,variables: {id:parseInt(poId)}}]
	});


	// set value for form variables
	const updateFormVars = (name,value) => {
		setFormVars({...formVars,[name]:value});
	}

	const save = () => {
		// reset error messages
		setErrorMessages([]);
		// validate
			let errors: any = [];

			if (!formVars.quantityReceived || isNaN(formVars.quantityReceived)) {
				errors.push("Please enter a number for quantity received");
			}

			if (formVars.quantityReceived && !isNaN(formVars.quantityReceived) && parseFloat(formVars.quantityReceived) > parseFloat(quantityOrdered)) {
				errors.push("You can't receive more items than were ordered");

			}

			if (errors.length > 0) {
				return setErrorMessages(errors)
			} 

			receivePOItem({variables:{
							
							quantityReceived: parseFloat(formVars.quantityReceived),
							poId:parseInt(poId),
							poItemId:parseInt(id)
						}})
			.then(result => {
				setFormVars({})
				setErrorMessages([])
				setModalVisible(false);
			})
			.catch(err => {
				setErrorMessages(["There was a problem saving purchase order. Please try again"]);
			})
		}

	return (

		<Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}   keyboard={true}>
        	<ModalHeader toggle={() => setModalVisible(false)}>Receive Inventory</ModalHeader>
        	<ModalBody>
       				{errorMessages?.map((message,index) => <Alert key={index} >- {message}</Alert>)}

       				<Table>
       					<tbody>
       						<tr>
       							<td className="text-muted pt-2 pb-2">Part</td>
       							<td>{partName}</td>
       						</tr>
       						<tr>
       							<td className="text-muted pt-2 pb-2">Vendor</td>
       							<td>{vendor}</td>
       						</tr>
       						<tr>
       							<td className="text-muted pt-2 pb-2">Part Number</td>
       							<td>{partNumber}</td>
       						</tr>
       						<tr>
       							<td className="text-muted pt-2 pb-2">Quantity Received</td>
       							<td>{quantityReceived || 0}</td>
       						</tr>
       						<tr>
       							<td className="text-muted pt-2 pb-2">Quantity Ordered</td>
       							<td>{quantityOrdered}</td>
       						</tr>
       					</tbody>
       				</Table>
					
					<Form>
						<FormGroup>
							<Label>Quantity</Label>
							<Input type="number" placeholder='# received' value={formVars.quantityReceived || ""} onChange={e => updateFormVars("quantityReceived",e.target.value)} />
						</FormGroup>



					</Form>


			</ModalBody>
	        <ModalFooter>
	          <Button color="primary" onClick={() => save()}>
	            Add
	            {loading && <Spinner />}
	          </Button>{' '}
	          <Button color="secondary" onClick={() => {
					setModalVisible(false);
					setFormVars({});
					setErrorMessages([]);
	          }}>Cancel</Button>
	        </ModalFooter>
		</Modal>
	);
}