import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert, Form, FormGroup, Label, Col, Input} from 'reactstrap';

import {useMutation} from '@apollo/client';
import UpdatePartVendorDetails from '../../graphql/mutations/UpdatePartVendorDetails';

export default function EditPartVendorDetails({id,vendor,partDescription,partNumber,cost,modalVisible,setModalVisible}) {
	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});

	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}

	const [updatePartVendorDetails,{loading}] = useMutation(UpdatePartVendorDetails);

	useEffect(() => {
			setFormVals({
				partNumber,
				vendor,
				partDescription,
				cost:cost ? cost.toString() : null
			})

	},[modalVisible]);


	const save = () => {
		const {partNumber,vendor,partDescription,cost} = formVals;

	    // validate
	      let errors = [];

	      if (!vendor || vendor.trim().length === 0) {
	        errors.push("Please enter a vendor")  
	      }
	      if (cost && cost.trim().length > 0 && isNaN(cost)) {
	        errors.push("Please enter a number for cost or leave empty")
	      }

	      if (errors.length > 0) {
	        setErrors(errors);
	        return;
	      }

	    updatePartVendorDetails({
	      variables: {
	        id: parseInt(id),
	        partNumber,
	        vendor,
	        partDescription,
	        cost: cost ? parseFloat(cost) : null,
	      }


	    })
	      .then(result => {
	        // only close if valid result returned
	        if (result) {
	          setModalVisible(false);
	        }
	        
	       })
	     .catch(err => alert("Add Part Error","There was an error updating the part. Please try again")) 

	}



  return (
      <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Update Part</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
	        <Form>

	            <FormGroup >
	          		<Label>Vendor</Label>
	          		<Col>
				       <Input value={formVals.vendor || ""} onChange={e => setFormValue({field:"vendor",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label >Part Description</Label>
	          		<Col>
				       <Input value={formVals.partDescription || ""} onChange={e => setFormValue({field:"partDescription",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Part Number</Label>
	          		<Col>
				       <Input value={formVals.partNumber || ""} onChange={e => setFormValue({field:"partNumber",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Cost</Label>
	          		<Col>
				       <Input type="number" value={formVals.cost || ""} onChange={e => setFormValue({field:"cost",value:e.target.value})} />
	              	</Col>
	            </FormGroup>

	        </Form>




        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Save
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

  );

}
