import React from 'react';

import {
	Container,
	Row,
	Col,
	Card,
    
	Spinner,
    FormGroup,
    Input,
	Table,
} from 'reactstrap';


import { useQuery, useMutation } from "@apollo/client";
import UserEmailNotifications from "../../graphql/queries/UserEmailNotifications";
import AddEmailNotification from '../../graphql/mutations/AddEmailNotification';
import RemoveEmailNotification from '../../graphql/mutations/RemoveEmailNotification';


export default function EmailNotifications() {

    const {data,loading,error} = useQuery(UserEmailNotifications);
    const [addEmailNotification] = useMutation(AddEmailNotification,{refetchQueries:[{query:UserEmailNotifications}]});
    const [removeEmailNotification] = useMutation(RemoveEmailNotification,{refetchQueries:[{query:UserEmailNotifications}]});


	if (loading) return <Spinner />

    const handleChange = (id,notification,checked) => {
        if (checked) {
            removeEmailNotification({variables:{id,notification}})
        }
        else {
            addEmailNotification({variables:{id,notification}})
        }
    }

	return (
        <Container fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
          		
            	<Card>


            		{error && <span>There was a problem loading email notifications. Please try again.</span>}
            		 <Table className="align-items-center table-flush" responsive>
                         <thead>
                             <tr>
                                 <th>Name</th>
                                 <th>RECEIVE EXPIRATION NOTICES</th>
                                 <th>DAILY ACTIVE WORK ORDERS REPORT</th>
                             </tr>
                         </thead>
                         <tbody>
    	            		{data && data.employees && data.employees.map(({id,firstName,lastName,emailNotifications}) => 
    	            			<tr key={id}>
                                    <td>{firstName} {lastName}</td>
                                    <td>
                                        <FormGroup check>
                                                <Input style={{marginTop: -4}} type="checkbox" checked={emailNotifications.find(el => el === "Staff Expiration Notices") || false} onChange={() => handleChange(id,"Staff Expiration Notices",emailNotifications.find(el => el === "Staff Expiration Notices"))} />{' '}                                           
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup check>
                                                <Input style={{marginTop: -4}} type="checkbox" checked={emailNotifications.find(el => el === "Daily Activity Report") || false} onChange={() => handleChange(id,"Daily Activity Report",emailNotifications.find(el => el === "Daily Activity Report"))}  />{' '}
                                        </FormGroup>
                                    </td>
 



                                   
                                    {/*loadingRemove && currentLocation === locationName ?
                                       <Spinner className="ml-1" />
                                       :
                                        <Button color="link" className="ml-1" onClick={() => remove({locationName,id})}>
                                            remove
                                        </Button>

                                    */}
                                 </tr>
    	            		)}
                         </tbody>
            		</Table>
            	</Card>
            </Col>
          </Row>
        </Container>

	);
}