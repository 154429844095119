import React, {useState} from 'react';


import {
	Container,
	Row,
	Col,
	Card,
    CardHeader,
	Spinner,

	ListGroup,
	ListGroupItem,

	Button
} from 'reactstrap';

import AssetCustomFieldForm from '../../components/AccountSettings/AssetCustomFieldForm';

import { useQuery, useMutation } from "@apollo/client";
import UdfFields from "../../graphql/queries/UdfFields";
import RemoveUdf from '../../graphql/mutations/RemoveUdf';


export default function AssetCustomFields() {

	const {data,loading,error} = useQuery(UdfFields);
    const [removeUdf,{loading:loadingRemove}] = useMutation(RemoveUdf,{refetchQueries:[{query:UdfFields}]});
    const [currentFieldName,setCurrentFieldName] = useState();

	if (loading) return <Spinner />

    const remove = (fieldName) => {
        setCurrentFieldName(fieldName);
        removeUdf({variables:{fieldName}});
    }


	return (

        <Container fluid>
          <Row>
            <Col className="mb-5 mb-xl-0" xl="12">
          		
            	<Card>
                    <CardHeader >
                    <Row>
                        <Col sm={12}>
                            <AssetCustomFieldForm />
                        </Col>
                        
                    </Row>
                    </CardHeader>

            		{error && <span>There was a problem loading custom fields. Please try again.</span>}
            		<ListGroup>
	            		{data && data.udfs && data.udfs.map(({fieldName}) => 
	            			<ListGroupItem>
                                {fieldName}
                                {loadingRemove && currentFieldName === fieldName ?
                                   <Spinner className="ml-1" />
                                   :
                                    <Button color="link" className="ml-1" onClick={() => remove(fieldName)}>
                                        remove
                                    </Button>

                                }
                             </ListGroupItem>
	            		)}
            		</ListGroup>
            	</Card>
            </Col>
          </Row>
        </Container>


	);
}