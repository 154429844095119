// TODO: this should be merged with InventoryPagintion
import React from 'react';
import {

  Pagination,
  PaginationItem,
  PaginationLink,

}
 from "reactstrap";

import { useQuery } from '@apollo/client';
import InventoryLogCount from '../../graphql/queries/InventoryLogCount'

import Icon from '@mdi/react'
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js'



export default function PaginationView({filterFields,onPrev,onNext,offset=0,limit=20,prefixLabel,alignLeft}) {
  const {data} = useQuery(InventoryLogCount,{variables:filterFields})


  

      let displayLimit = offset+limit;
      if (data && displayLimit > data.inventoryLogCount) {
        displayLimit = data.inventoryLogCount;
      }

      if (data && data.inventoryLogCount === 0) return <div />

	return (


                     <Pagination
                      className={`pagination justify-content-end mb-0  ${alignLeft ? "float-left":"float-right"}`}
                      listClassName="justify-content-end mb-0 ml-2"
                    >
                      {data && offset > 0 &&
                        <PaginationItem >
                          <PaginationLink
                            href="#pablo"
                            onClick={e => {e.preventDefault();onPrev()}}
                            tabIndex="-1"
                            style={{padding:4}}
                          >
                            <Icon path={mdiChevronLeft} size={1} />
                            <span className="sr-only">Previous</span>
                          </PaginationLink>
                        </PaginationItem>


                      }
                     {data && 
                      <div style={{padding: 6}}>
                        {prefixLabel ? prefixLabel+ " " : ""}{offset+1} to {displayLimit} of {data.inventoryLogCount}
                       </div>
                     }
                      {data && displayLimit < data.inventoryLogCount &&
                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => {e.preventDefault();onNext()}}
                          style={{padding:4}}
                        >
                          <Icon path={mdiChevronRight} size={1} />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                     }     
                    </Pagination>  
   
	);
}