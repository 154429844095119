import gql from 'graphql-tag';

export default gql`
query  {
  udfs {
  	id
  	fieldName,
  	sortOrder
  }
}
`;