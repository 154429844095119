import React, {useState, useEffect} from 'react';


// reactstrap components
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,

  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Collapse,
  ListGroup,
  ListGroupItem
} from "reactstrap";


import Icon from '@mdi/react'
import { mdiChevronRight,mdiChevronDown} from '@mdi/js'

import { useMutation } from '@apollo/client';
import AddInspectionTemplateCategory from '../../graphql/mutations/AddInspectionTemplateCategory';

import InspectionTemplateFields from './InspectionTemplateFields';

export default function InspectionTemplateCategories({templateId,categories}) {
  const defaultFormValues = {category:null}
  const [formFieldValues,setFormFieldValues] = useState(defaultFormValues);
  const [isOpen, setIsOpen] = useState([]);

  const [addInspectionTemplateCategory,{loading}] = useMutation(AddInspectionTemplateCategory,{
    onError: error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the category . Please try again.`)
  })

  // set isopen status of category accordian
  useEffect(() => {
    // if there are fewer isOpen array elements than categories, add to isOpen
    if (categories.length > isOpen.length) {
      const copyIsOpen = [...isOpen]; // get a copy of isOpen
      const diff = categories.length - isOpen.length;
      const newIsOpen = copyIsOpen.concat(Array(diff).fill(true))
      setIsOpen(newIsOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[categories])

  const updateIsOpen = (index) => {
      let copyIsOpen = [...isOpen]; // get a copy of isOpen
      copyIsOpen[index] = !isOpen[index];
      console.log("copyIsOpen",copyIsOpen)
      setIsOpen(copyIsOpen);

  }

  const save = () => {
    const {
      category
    } = formFieldValues;

    if (!category || category.trim().length === 0) {
      return alert("Please enter a category name")
    }

    addInspectionTemplateCategory({variables:{
      ...formFieldValues,
      templateId
    }})
    .then((result) => {
      // if there wasn't an error, clear form
      if (result)
        setFormFieldValues(defaultFormValues);
    })
  }
  return (
      <div style={{paddingBottom: 100}}>
        <Card>
          <CardHeader className="pb-0" style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>
            <CardTitle className="m-0 float-left">
              <h2>Template Categories/Fields</h2>
            </CardTitle>
          </CardHeader>
          <CardBody className="pt-0">
              <Form onSubmit={(e) => {e.preventDefault();save()}} inline style={{border: "1px solid #cccccc",padding: 10,borderRadius: 10,background: "#e2e2e2"}}>
                <FormGroup>
                  <Label className="mr-1" style={{fontWeight: 'bold'}}>Category:</Label>
                  <Input className="mr-2" placeholder="Add Category Name (i.e. Truck, Trailer, Engine Compartment)" value={formFieldValues.category} onChange={e => setFormFieldValues({...formFieldValues,"category":e.target.value})} />
                </FormGroup>
                <Button type="submit" color="primary" >
                  Add Category {loading && <Spinner size="sm" />}
                </Button>
              </Form>

              
          </CardBody>
          </Card>



          {/* Collapsable List of Categories*/}
          <ListGroup>
            {categories.map(({id,category,fields},index) =>
                <>
                  <ListGroupItem key={index} color="info">{category} <Icon onClick={() => updateIsOpen(index)} path={isOpen[index] ? mdiChevronDown : mdiChevronRight} size={1} /></ListGroupItem>
                  <Collapse isOpen={isOpen[index]}>
                    <Card>
                      <CardBody className="p-0">
                        <InspectionTemplateFields templateId={templateId} categoryId={id} fields={fields} />
                      </CardBody>
                    </Card>
                  </Collapse>
                </>
            )}
          </ListGroup>
    </div> 

  );
}
