import gql from 'graphql-tag';

export default gql`
mutation AddUdf($fieldName: String!)  {
  addUdf (fieldName: $fieldName) {
  	id,
  	fieldName,
  	sortOrder
  }
}
`;

