import React from 'react';

// reactstrap components
import {


  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,

  Table,


} from "reactstrap";

import moment from 'moment';
import {useQuery} from '@apollo/client';
import TruckPartsUsed from '../../graphql/queries/TruckParts';


export default function PartsUsedCard({id}) {

	const {data} = useQuery(TruckPartsUsed,{variables:{truck:id}})

	if (!data || data.truckParts.length === 0) return null;

	return (
          <Card className="shadow mb-4">
          <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Parts History</CardTitle></CardHeader>

            <CardBody>
              <Row>
                <Col>
                  <Table className="table-flush">
                  	<thead>
                  		<tr>
                  			<th>Date Added</th>
                  			<th>Part</th>
                  			<th>Quantity</th>
                  		</tr>
                  	</thead>
                    <tbody>
                    	{data && data.truckParts.map(({partName,vendor,partNumber,quantity,createdAt},index) =>
	                        <tr key={index}>
	                          <td>{moment(createdAt).format("M/D/YYYY")}</td>
	                          <td>
	                          	<strong>{partName}</strong><br />
	                          	<span>{vendor}{partNumber && ` - ${partNumber}`}</span>
	                          </td>
	                          <td>{quantity}</td>
	                        </tr>
                    	)}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>

	);
}