import gql from 'graphql-tag';

export default gql`
{
	servicePlans {
    id
    planName
    serviceSchedule {
      service
    }
  }
}
`;