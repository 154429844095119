import React, {useState} from 'react';
// reactstrap components
import {

  Input,
  FormGroup,
  Label,
  Button,
  ButtonDropdown,
  
  DropdownToggle,

  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import TruckTypes from '../../graphql/queries/TruckTypes';

export default function TruckTypeFilterButton({onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedTypes,setSelectedTypes] = useState([])
	const [typeCount,setTypeCount] = useState(0) // this is used to force a re-render when selected statuses change


	const {data,loading,error} = useQuery(TruckTypes);

	if (loading || error) return null;

	if (!data) return false;



	const typeChecked = ({type}) => {

	

		// if in array, remove. if not in there, add it
		const i = selectedTypes.indexOf(type);
		let newSelectedTypes = selectedTypes;
		if (i >= 0) {
			newSelectedTypes.splice(i,1);
		}
		else {
			newSelectedTypes.push(type);
		}

		setSelectedTypes(newSelectedTypes)
		setTypeCount(newSelectedTypes.length)

		// announce to parent that something changed
		if (onChange) onChange(newSelectedTypes);
	}



	return (
		<ButtonDropdown color="white" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret  color="white">
				{typeCount === 0 ? "All Types" : `${typeCount} Types` }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10}}>
				{data.truckTypes.map(({type}) =>
			      <FormGroup key={type} check>
			        <Label check>
			          <Input type="checkbox" 
			          		// defaultChecked={selectedTypes.indexOf(name) != -1}
			          		 checked={selectedTypes.indexOf(type) !== -1}
			          		 onChange={() => typeChecked({type})}
			          		 />{' '}
			          {type}
			        </Label>
			      </FormGroup>

				)}
				<Button color="link" onClick={() => {setSelectedTypes([]);setTypeCount(0);onChange([])}}>
					Clear All
				</Button>
			</DropdownMenu>
		</ButtonDropdown>
	);
}