import gql from 'graphql-tag';

export default gql`
query Parts($truck:ID!){
  truckParts(truck:$truck) {
    id
      partName
      partNumber
      vendor
    quantity
    createdAt
   added
   removed
  }
}
`;
