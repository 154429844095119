import React, {useState} from "react";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,

  Modal
} from "reactstrap"; 

import {useMutation} from '@apollo/client';
import RemoveWorkOrder from '../../graphql/mutations/RemoveWorkOrder';


import Icon from '@mdi/react'
import {mdiClose,mdiCheck} from '@mdi/js'


export default function DeleteModal({id,modalVisible,setModalVisible,onSuccess}) {
  const [errorMessage,setErrorMessage] = useState();

  const [removeWorkOrder] = useMutation(RemoveWorkOrder, {
    onCompleted: () => {
      setModalVisible(false);
      setErrorMessage();
      onSuccess();
    },
    onError: error => {
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error deleting this work order. Please try again.'
            setErrorMessage(message);
    }


  });



  return (
    <div>
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={modalVisible}
              toggle={() => setModalVisible(false)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                 <CardHeader>
                   <h3>Are you sure that you want to delete this work order?</h3>
                 </CardHeader>

                  
                  <CardBody >
                    {errorMessage && 
                      <Alert color="danger">
                        {errorMessage}
                      </Alert>
                    }

                      <div className="btn-wrapper text-center">
                        <Button
                          style={{width: 86,height: 86}}
                          className="btn-icon"
                          color="warning"
                          onClick={() => removeWorkOrder({variables:{id}})}
                          
                        >
                          <span className="btn-inner--icon">
                            
                            <Icon path={mdiCheck} />
                          </span><br />
                          <span className="text-center" style={{display:"block"}}>Yes</span>
                        </Button>
                        <Button
                          style={{width: 86,height: 86}}
                          className="btn-icon"
                          color="danger"
                          onClick={() => setModalVisible(false)}
                          
                        >
                          <span className="btn-inner--icon">
                            <Icon path={mdiClose} />
                          </span>
                          <span className="text-center" style={{display:"block"}}>No</span>
                        </Button>
                      </div>
                  </CardBody>
                </Card>
              </div>
            </Modal>

    </div>
  )
}
