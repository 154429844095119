import React, {useState} from 'react';
import { Table, Alert, Spinner,Button} from 'reactstrap';
import { Link } from "react-router-dom";
import moment from 'moment';

import RemovePermitModal from "./RemovePermitModal";

import { useQuery } from '@apollo/client';
import Permits from '../../graphql/queries/Permits'

export default function PermitsTable({offset=0,limit=5,filterFields,keyword}) {
	const {data,loading,error} = useQuery(Permits);

  const [permit,setPermit] = useState();
  const [permitName,setPermitName] = useState();
  const [modalVisible_remove,setModalVisible_remove] = useState(false);

  const cancelPermit = (id,permitName) => {
    setPermit(id);
    setPermitName(permitName);

    setModalVisible_remove(true);
  }

	if (loading) return <Spinner />;
	if (error) return <Alert danger>Error loading permits</Alert>

  return (
    <>
    <Table>
      <thead className="thead-light">
        <tr>
            <th>Permit</th>
            <th>Vehicles</th>
            <th>Remove</th>

        </tr>
      </thead>
      <tbody>
          {data.permits
             .map(({
                   id,
                    permitName,
                    assignedTo ,
                    trucks,
                    startDate,
                    renewalDate

          }) =>
            <tr key={id} >
              <td>
                <Link to={`/Permit/${id}`}>{permitName}</Link>
              </td>
              <td>
                    {assignedTo && assignedTo.length > 0 && assignedTo.map(({truck:{identifier},startDate,renewalDate},index) => 
                      <div key={index}>
                           <strong>{identifier}:</strong> {moment(startDate).format('L')} to {moment(renewalDate).format('L')}
                           {moment(renewalDate).diff(moment().add(30,'days'),'days') < 0 && <span className="badge badge-danger ml-1">Expiring</span>}
                      </div>
                    )}   
            
              </td>
              <td><Button color="danger" onClick={() => cancelPermit(id,permitName)}>Remove</Button></td>
            </tr>
         )}

      </tbody>
    </Table>
          <RemovePermitModal
            permit={permit}
            permitName={permitName}
            modalVisible={modalVisible_remove}
            setModalVisible={setModalVisible_remove}

           />

    </>
  );


}




            