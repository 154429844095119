import Dashboard from "./screens/Dashboard";

import WorkOrders from "./screens/WorkOrders";
import WorkOrder from "./screens/WorkOrder";
import WorkOrder_New from "./screens/WorkOrder_New";

import Assets from './screens/Assets';
import AssetDetail from './screens/AssetDetail';
import Asset_New from './screens/Asset_New';
import Asset_Update from './screens/Asset_Update';

import People from './screens/People';
import PeopleDetail from './screens/PeopleDetail';
import People_New from './screens/People_New';
import People_Update from './screens/People_Update';

import ServicePlans from './screens/ServicePlans';
import ServicePlan from './screens/ServicePlan'

import Inventory from './screens/Inventory';
import InventoryDetail from './screens/InventoryDetail';
import InventoryCodeSheet from './screens/InventoryCodeSheet';
import InventoryMorningRush from './screens/InventoryMorningRush';
import InventoryLog from './screens/InventoryLog';



import PurchaseOrderDetail from './screens/PurchaseOrderDetail';
import PurchaseOrder_New from './screens/PurchaseOrder_New';


import Permits from './screens/Permits';
import PermitNew from './screens/PermitNew';
import PermitDetail from './screens/Permit';

import InspectionTemplates from './screens/InspectionTemplates';
import InspectionTemplateDetail from './screens/InspectionTemplateDetail';
import InspectionTemplateNew from './screens/InspectionTemplateNew';

import Support from './screens/Support';
import ChangePassword from './screens/AccountSettings/ChangePassword';
import AccountSettings from './screens/AccountSettings';
import EmailNotifications from './screens/AccountSettings/EmailNotifications';
import AssetTypes from './screens/AccountSettings/AssetTypes';
import AssetCustomFields from './screens/AccountSettings/AssetCustomFields';
import Locations from './screens/AccountSettings/Locations';

import ChargeBeePortal from './screens/Payments/ChargeBeePortal';

import Reports from './screens/Reports/Reports';
import PartsUsageReport from './screens/Reports/PartsUsageReport';
import PreTripInspections from './screens/Reports/PreTripInspections';
import WorkOrderHistoryReport from './screens/Reports/WorkOrderHistoryReport'

const indexRoutes = [
		{ path: '/Reports/WorkOrderHistoryReport', component: WorkOrderHistoryReport},
		{ path: '/Reports/Inspections/Pretrip', component: PreTripInspections},
		{ path: '/Reports/Inventory/PartsUsage', component: PartsUsageReport},
		{ path: '/Reports', component: Reports},

		{ path: "/MyAccount", name: "", component: ChargeBeePortal },


		{ path: "/Support", name: "", component: Support },
		{ path: "/AccountSettings/ChangePassword", name: "", component: ChangePassword },
		{ path: "/AccountSettings/EmailNotifications", name: "", component: EmailNotifications },
		{ path: "/AccountSettings/AssetTypes", name: "", component: AssetTypes },
		{ path: "/AccountSettings/AssetCustomFields", name: "", component: AssetCustomFields },
		{ path: "/AccountSettings/Locations", name: "", component: Locations },
		{ path: "/AccountSettings", name: "", component: AccountSettings },
		
		{ path: "/InspectionTemplate/new", name: "Inspection Templates", component: InspectionTemplateNew },
		{ path: "/InspectionTemplate/:id", name: "Inspection Templates", component: InspectionTemplateDetail },
		{ path: "/InspectionTemplates", name: "Inspection Templates", component: InspectionTemplates },


		{ path: "/Permit/new", name: "New Permit", component: PermitNew },
		{ path: "/Permit/:id", name: "Permit", component: PermitDetail },
		{ path: "/Permits", name: "Permits", component: Permits },

		{ path: "/PurchaseOrder/new", component: PurchaseOrder_New},
		{ path: "/PurchaseOrder/:id", component: PurchaseOrderDetail},

		{ path: "/Inventory/Log", component: InventoryLog},
		{ path: "/Inventory/CodeSheet", component: InventoryCodeSheet},
		{ path: "/Inventory/MorningRush", component: InventoryMorningRush},
		{ path: "/Inventory/:id", component: InventoryDetail},
		{ path: "/Inventory", component: Inventory},

		{ path: "/ServicePlan/:id", name: "People", component: ServicePlan },
		{ path: "/ServicePlans", component: ServicePlans},

		{ path: "/People/edit/:id", name: "Edit People", component: People_Update },
		{ path: "/People/new", name: "New People", component: People_New },
		{ path: "/People/:id", name: "People", component: PeopleDetail },
		{ path: "/People", component: People},

		{ path: "/Asset/edit/:id", name: "Edit Asset", component: Asset_Update },
		{ path: "/Asset/new", name: "New Asset", component: Asset_New },
		{ path: "/Asset/:id", name: "Asset", component: AssetDetail },
		{ path: "/Assets", component: Assets},

		{ path: "/WorkOrder/new", name: "New Work Order", component: WorkOrder_New },
		{ path: "/WorkOrder/:id", name: "Work Order", component: WorkOrder },
		{ path: "/WorkOrders", name: "Work Orders", component: WorkOrders },

		{ path: "/", name: "Dashboard", component: Dashboard },
	];


export default indexRoutes;
