import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';


export default gql`
mutation UpdateStatus($id:ID!, $status: String!, $note: String) {
  updateStatus(id: $id,status:$status, note:$note) {
    ...WorkOrderAllFields


  }
}
${WORK_ORDER_FRAGMENT}
`;