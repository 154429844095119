import React, {useState} from "react";
// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Label,
  Input,
  Modal
} from "reactstrap"; 

import {useMutation} from '@apollo/client';
import UpdateStatus from '../../graphql/mutations/UpdateStatus';
import WorkOrder from '../../graphql/queries/WorkOrder';


export default function SetStatusModal({id,modalVisible,setModalVisible}) {
  const [status,setStatus] = useState();
  const [note,setNote] = useState();
  const [errorMessage,setErrorMessage] = useState();

  const [updateStatus] = useMutation(UpdateStatus, {
    refetchQueries:[
      {query:WorkOrder,variables:{id}}
    ],
    onCompleted: () => {
      setModalVisible(false);
      setNote();
      setStatus();
      setErrorMessage();
    },
    onError: error => {
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error updating status. Please try again.'
            setErrorMessage(message);
    }


  });

  const save = () => {
      if (!status) {
        return setErrorMessage("Please select a status");
      }
      // anything but work in progress requires a note
      if (status !== 'In Progress' && (!note || note.trim().length === 0)) {
        return setErrorMessage("Please enter details for " + status);
      }

      updateStatus({variables: {
                    id,
                    status,
                    note
                  }})
  }


  const renderStatusButton = ({icon,label,onClick}) => 
        <Button
          className="btn-neutral btn-icon ml-1 mr-1"
          color="default"
          onClick={onClick}
          style={label === status ? {color:"black",backgroundColor:"#cccccc"} : {backgroundColor:"#ffffff"}}
        >
          <span className="btn-inner--icon">
            <i className={icon} style={{fontSize:40}}></i>
          </span><br />
          <span className="btn-inner--text">{label}</span>
        </Button>

  return (
    <div>
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={modalVisible}
              toggle={() => setModalVisible(false)}
            >
              <div className="modal-body p-0">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-transparent pb-2">
                    {errorMessage && 
                      <Alert color="danger">
                        {errorMessage}
                      </Alert>
                    }

                    <div className="btn-wrapper text-center">
                      {renderStatusButton({icon:"far fa-clock",label:"In Progress",onClick:() => setStatus("In Progress")})}
                      {renderStatusButton({icon:"fas fa-pause",label:"Work On Hold",onClick:() => setStatus("Work On Hold")})}
                      {renderStatusButton({icon:"fas fa-check",label:"Work Complete",onClick:() => setStatus("Work Complete")})}

                    </div>
                  </CardHeader>
                  <CardBody >
                    <Form role="form">
                      {["Work Complete","Work On Hold"].find(el => el === status) && 
                        <FormGroup className="mb-3">
                          <Label for="exampleText">{status} Details</Label>
                          <Input type="textarea" value={note} onChange={e => setNote(e.currentTarget.value)} />

                        </FormGroup>

                      }

                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => save()}
                        >
                          Set Status
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

    </div>
  )
}
