import React from 'react'
import {Link} from 'react-router-dom';

import {Spinner,Table} from 'reactstrap';

import { useQuery } from '@apollo/client';
import InventoryStats from '../../graphql/queries/InventoryAlerts';

export default function InventoryStatsDisplay() {
	const {loading,data} = useQuery(InventoryStats);

	return (
        <div className="table-responsive">
           <Table className="align-items-center table-flush"  >
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Threshold</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.inventoryAlerts?.map(({id,partName,numberInStock,warningThreshold}) =>
                         <tr key={id}>
                            <td style={{fontWeight:500}}><Link to={`/Inventory/${id}`}>{partName}</Link></td>
                            <td className="text-center">{numberInStock}</td>
                            <td className="text-center">{warningThreshold}</td>
                        </tr>

                    )}
                    
                
                </tbody>
            </Table>
            {loading && <Spinner />}
            {data?.inventoryAlerts?.length === 0 && <div className="ml-2 text-muted">Everything is fully stocked</div>}
        </div>
	
	);
}

