import React, {useState} from 'react';
// reactstrap components
import {

  Input,
  FormGroup,
  Label,
  Button,
  ButtonDropdown,
  
  DropdownToggle,

  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import Statuses from '../../graphql/queries/Statuses';

export default function StatusFilterButton({onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedStatuses,setSelectedStatuses] = useState([])
	const [statusCount,setStatusCount] = useState(0) // this is used to force a re-render when selected statuses change


	const {data,loading,error} = useQuery(Statuses);

	if (loading || error) return null;

	if (!data) return false;



	const statusChecked = ({name}) => {

	

		// if in array, remove. if not in there, add it
		const i = selectedStatuses.indexOf(name);
		let newSelectedStatuses = selectedStatuses;
		if (i >= 0) {
			newSelectedStatuses.splice(i,1);
		}
		else {
			newSelectedStatuses.push(name);
		}

		setSelectedStatuses(newSelectedStatuses)
		setStatusCount(newSelectedStatuses.length)

		// announce to parent that something changed
		if (onChange) onChange(newSelectedStatuses);
	}



	return (
		<ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret color="white" >
				{statusCount === 0 ? "All Statuses" : `${statusCount} Statuses` }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10}}>
				{data.statuses.map(({name}) =>
			      <FormGroup key={name} check>
			        <Label check>
			          <Input type="checkbox" 
			          		// defaultChecked={selectedStatuses.indexOf(name) != -1}
			          		 checked={selectedStatuses.indexOf(name) !== -1}
			          		 onChange={() => statusChecked({name})}
			          		 />{' '}
			          {name}
			        </Label>
			      </FormGroup>

				)}
				<Button color="link" onClick={() => {setSelectedStatuses([]);setStatusCount(0);onChange([])}}>
					Clear All
				</Button>
			</DropdownMenu>
		</ButtonDropdown>
	);
}