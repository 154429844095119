import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import 'react-dropzone/examples/theme.css'

import {Card, Button} from 'reactstrap';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 120,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


export default function Previews({onChange}) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/jpeg,image/jpg',
    onDrop: acceptedFiles => {
      /*setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));*/
      const newFiles = acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))

      const newFileArray = [...files,...newFiles]
      // for display in component
      setFiles(newFileArray)


      if (onChange) onChange(newFileArray);
/*
	filename: {type: GraphQLString},
		contenttype: {type: GraphQLString},
		height: {type: GraphQLInt},
		width: {type: GraphQLInt},
		type: {type: GraphQLString},
		cancelled: {type: GraphQLBoolean}


path: "400dpiLogoCropped.jpg"
preview: "blob:http://localhost:3002/cdbfdd24-68b3-409a-bd5f-bd0d9ce035b7"
lastModified: 312782400000
lastModifiedDate: Thu Nov 29 1979 23:00:00 GMT-0500 (Eastern Standard Time) {}
name: "400dpiLogoCropped.jpg"
size: 208373
type: "image/jpeg"
webkitRelativePath: ""

*/
    }
  });
  
  const thumbs = files.map((file,index) => (
    <div style={thumb} key={file.name}>
    
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
 		<a onClick={() => removeThumb(index)}  style={{position:"absolute",color:"#0000ff",cursor:"pointer"}}>remove</a>
    </div>
  ));

  const removeThumb = (index) => {
  	const theFiles = [...files];

  	theFiles.splice(index,1);

  	setFiles(theFiles);
  }

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);


  return (
    <div>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p style={{color: "rgba(0,0,0,.7)"}}>Drag 'n' drop photos here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
    </div>
  );
}

