
import WorkOrder_New from "./screens/WorkOrder_New";
import ChangePassword from './screens/AccountSettings/ChangePassword';
import Support from './screens/Support';


const indexRoutes = [

		{ path: "/WorkOrder/new", name: "New Work Order", component: WorkOrder_New },
		{ path: "/AccountSettings/ChangePassword", name: "", component: ChangePassword },
		{ path: "/Support", name: "", component: Support },

		{ path: "/", name: "New Work Order", component: WorkOrder_New },
	];


export default indexRoutes;
