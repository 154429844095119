import gql from "graphql-tag";



export default gql`
query InventoryLog ($offset: Int, $limit: Int) {
  inventoryLog(offset:$offset,limit:$limit) {
    id
    log_date
    inventory_added
    inventory_removed
    user {
      firstName
      lastName
    }
    count
    work_order {
      ticketNumber
          truck {
      		type
      		identifier
    	}
    }
    log_note
    inventory_item {
      partName
      partNumber
      vendor
    }

  }
}

`;