import React from 'react'

import {Card,CardTitle,CardHeader,CardBody,Spinner} from 'reactstrap';

import { useQuery } from '@apollo/client';
import InventoryStats from '../../graphql/queries/reports/InventoryStats';

export default function InventoryStatsDisplay({addFilter,removeFilter}) {
	const {loading,data} = useQuery(InventoryStats);


	return (
		<Card className="shadow mb-4" style={{height:122}}>
    		<CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}>

    			<CardTitle className="m-0">Total Value</CardTitle>
    			<CardBody className="p-0 pt-2" style={{fontSize: 30,fontWeight: 300,lineHeight:"30px"}}>
                    {loading && <Spinner />}
    				<p className="m-0">${data?.totalInventoryValue?.totalPartValue?.toLocaleString() || "n/a"}</p>
    				{data?.totalInventoryValue?.noStockSet && data?.totalInventoryValue?.noCostCount && (data.totalInventoryValue.noStockSet > 0 || data.totalInventoryValue.noCostCount > 0) ?
	    				<span style={{fontSize:12}}>({data.totalInventoryValue.noCostCount} without cost/{data.totalInventoryValue.noStockSet} without qty)</span>
                        : ""
    				}
    			</CardBody>
    		</CardHeader>
		</Card>
	
	);
}