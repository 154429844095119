import gql from 'graphql-tag';

import { SERVICE_PLAN_FRAGMENT } from '../fragments/ServicePlanFragments';


export default gql`
mutation AddServicePlanService($id: ID!, $service: String!,$trackingMeasurement: String!,$units: Float!) {
	addServicePlanService(
	    id:$id,
	    service:$service,
	    trackingMeasurement:$trackingMeasurement,
	    units:$units
  )
	{
		...ServicePlanAllFields
  }
}
${SERVICE_PLAN_FRAGMENT}
`;
