import gql from 'graphql-tag';

export default gql`
{
  employees(managersOnly:true) {
    id
    firstName
    lastName
    emailNotifications
  }
}
`;