import React from 'react';
import "react-vis/dist/style.css";

import { Card, CardHeader, CardTitle, CardBody, Row, Col } from 'reactstrap';


import {XYPlot, XAxis, YAxis, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, Hint,DiscreteColorLegend} from 'react-vis';

import {useQuery} from '@apollo/client';
import truckWorkOrdersReport from "../../graphql/queries/reports/truckWorkOrdersReport";

const monthNames = ["","January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export default function TruckWorkOrdersReport() {

  const [hintVals,setHintVals] = React.useState({})

  const {data} = useQuery(truckWorkOrdersReport);

  const _forgetValue = (_id) => {
    const newVals = {...hintVals,[_id]: null}
    setHintVals(newVals)
  };

  const _rememberValue = (value,_id) => {
    const newVals = {...hintVals,[_id]: value}
    setHintVals(newVals)
   };


  const renderTruckRow = ({_id,type,identifier,WorkOrders}) => {

    

    return (
        <Card key={_id}>
          <CardHeader><CardTitle>{type} {identifier}</CardTitle></CardHeader>
          <CardBody>

            {WorkOrders.length === 0 && <p>No work orders found</p>}
            <XYPlot margin={{bottom: 70}} 
                xType="ordinal" 
                stackBy="y"
                width={600} 
                height={200}>
          <DiscreteColorLegend
            style={{position: 'absolute', left: '50px', top: '10px'}}
            orientation="vertical"
            
            items={[
              {
                title: 'Standard',
                color: '#12939A',
                
              },
              {
                title: 'Preventative',
                color: '#79C7E3'
              }
            ]}
          />
              <VerticalGridLines />
              <HorizontalGridLines />
              <XAxis tickLabelAngle={-45} />
              <YAxis />

              <VerticalBarSeries
                barWidth={.2}
                data={WorkOrders.map(({countPreventative,month,year}) => ({x:`${monthNames[month]} ${year}`,y:countPreventative,size: 10}))}
                onValueMouseOver={value => this._rememberValue(value,_id)}
                onValueMouseOut={value => this._forgetValue(_id)}
                color="#79C7E3"
              />
              <VerticalBarSeries
                barWidth={.2}
                data={WorkOrders.map(({countStandard,month,year}) => ({x:`${monthNames[month]} ${year}`,y:countStandard,size: 10}))}
                onValueMouseOver={value => this._rememberValue(value,_id)}
                onValueMouseOut={value => this._forgetValue(_id)}
                 color="#12939A"

              />
              {hintVals[_id] && 
                <Hint value={hintVals[_id]}>
                  <div style={{textAlign: 'center',paddingLeft: 4,paddingRight: 4, border:"2px double #000000",color: "#000000",borderRadius: 10,backgroundColor: '#cccccc'}}>
                  {hintVals[_id].y}
                  </div>
                </Hint>
              }
              {/*value && value[_id] ? <Hint value={value[_id]} /> : null*/}
            </XYPlot>
          </CardBody>
        </Card>
    );
  }

  return (
      <div className="content">
        {/* Loop over truck and display report of work orders by month  */}

        {data?.truckWorkOrdersReport?.map(truck => renderTruckRow(truck))}

      </div>

   )

}


