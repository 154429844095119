import gql from 'graphql-tag';

export default gql`
mutation AddWorkOrderNote(
		$id: ID!,
		$note: String!,
  	$photos: [FileTypeInput],
	) {
  addWorkOrderNote(
  	id:$id,
    note:$note,
    photos:$photos
  ) {
      id
      avoidableExpense
      mileageIn
      truck {
        id
        identifier
        type
      }
      title
      description
      reportedBy {
        firstName
        lastName
      }
      status
      priority
      ticketNumber
      outOfService
      createdAt
      assignedTo {
        id
        firstName
        lastName
      }
      photos {
        url
        height
        width
      }
      log {
        id
        createdAt
        enteredBy {
          firstName
          lastName
        }
        note,
        noteType,
        status,
        photos {
          url
          height
          width
        }

        file {
          width
          height
        }

      }


    
    photosToUpload {
      filename,
      height,
      width,
      contenttype,
      url
    }
  }
}
`;