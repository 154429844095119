import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert, Form, FormGroup, Label, Col, Input} from 'reactstrap';

import { useMutation } from '@apollo/client';
import AddPartFromVendor from '../../graphql/mutations/AddPartFromVendor';

export default function InventoryAdd({inventoryId,modalVisible,setModalVisible}) {

	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});

	// clear form on modal visibility change
	useEffect(() => {
		setFormVals({})
	},[modalVisible])


	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}

	const [addSubPart,{loading}] = useMutation(AddPartFromVendor);



 	const save = ():void => {
		// reset error messages
		setErrors([]);
		// validate
			let errors = [];

			// are cost and quantity numbers
			if (formVals.cost && isNaN(formVals.cost)) {
				errors.push("Please enter a number for cost or leave empty")
			}

			if (formVals.numberInStock && formVals.numberInStock.trim().length > 0 && isNaN(formVals.numberInStock)) {
				errors.push("Please enter a number for quantity or leave empty")
			}
			// must have a vendor
			if (!formVals.vendor || formVals.vendor.trim().length === 0) {
				errors.push("Please enter a vendor")	
			}

			if (errors.length > 0) {
				setErrors(errors);
				return;
			}

			addSubPart({variables:{
							...formVals,
							cost: formVals.cost ? parseFloat(formVals.cost) : null,
							numberInStock: formVals.numberInStock ? parseFloat(formVals.numberInStock) : null,
							inventoryId: parseInt(inventoryId)
						}})
			.then(result => {
				setModalVisible(false);
			})
			.catch(err => {
				setErrors(["There was a problem saving this part. Please try again"]);
			})
		}



  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Add to Part</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
	        <Form>
	            <FormGroup >
	          		<Label >Part Description/Label</Label>
	          		<Col>
				       <Input value={formVals.partDescription || ""} onChange={e => setFormValue({field:"partDescription",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Vendor</Label>
	          		<Col>
				       <Input value={formVals.vendor || ""} onChange={e => setFormValue({field:"vendor",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Part Number</Label>
	          		<Col>
				       <Input value={formVals.partNumber || ""} onChange={e => setFormValue({field:"partNumber",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Quantity</Label>
	          		<Col>
				       <Input type="number" value={formVals.numberInStock || ""} onChange={e => setFormValue({field:"numberInStock",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Cost</Label>
	          		<Col>
				       <Input type="number" value={formVals.cost || ""} onChange={e => setFormValue({field:"cost",value:e.target.value})} />
	              	</Col>
	            </FormGroup>


	        </Form>




        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Save
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

  );

}
