import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';
 

export default gql`
query Permit($id: ID!) {
  permit(id:$id) {
    ...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;