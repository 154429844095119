import React from 'react';
import {Modal,ModalHeader,ModalBody,ModalFooter, Button,Spinner} from 'reactstrap';


import {useMutation} from '@apollo/client';
import RemovePermitFromAsset from '../../graphql/mutations/RemovePermitFromAsset';
import Permit from '../../graphql/queries/Permit';


export default function RemovePermitFromAssetModal({permit,assignedTo,onComplete,modalVisible,setModalVisible}) {



  const [removePermitFromAsset,{loading:updating}] = useMutation(RemovePermitFromAsset, {
    refetchQueries: [{query: Permit,variables: {id:permit}}]
  });

    const save = () => {

      removePermitFromAsset({variables:{
        permit,
        assignedTo:assignedTo.id
      }})
      .then(() => setModalVisible(false))



     }

     if (modalVisible === false) return null

  return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Remove Asset from Permit</ModalHeader>
        <ModalBody>
          <p>Are you sure that you want to remove {assignedTo.truck.identifier} from the permit?</p>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>Yes</Button>
          <Button color="warning" onClick={() => setModalVisible(false)}>No</Button>
          {updating && <Spinner />}
        </ModalFooter>
      </Modal>


  );

  

}

