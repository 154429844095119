import gql from 'graphql-tag';

export default gql`
mutation UpdatePartVendorDetails(
  $id: Int!,
  $partNumber:String,
  $vendor:String!,
  $cost: Float,
  $partDescription: String
) {
  updateSubPartDetails(
    id:$id,
    partNumber:$partNumber,
    vendor:$vendor,
    cost: $cost,
    partDescription:$partDescription
  ) {


    id
    partName
    numberInStock
    warningThreshold
    inventoryItems {
      id
      partNumber
      vendor
      cost
      numberInStock
      barcodeType
      barcodeData
      partDescription
      
    }



  }
}
`;