import React, {useState} from 'react';
// reactstrap components
import {

  Input,
  FormGroup,
  Label,
  
  ButtonDropdown,
  
  DropdownToggle,

  DropdownMenu,

}
 from "reactstrap";

 import { useQuery } from '@apollo/client';
 import Locations from '../../graphql/queries/Locations';

export default function LocationFilterButton({onChange}) {
	const [isOpen,setIsOpen] = useState(false)
	const [selectedLocation,setSelectedLocation] = useState()


	const {data,loading,error} = useQuery(Locations);

	if (loading || error) return null;

	if (!data) return false;



	const locationChecked = ({location}) => {

		setSelectedLocation(location)
		if (onChange) onChange(location?.id);
		setIsOpen(false)

		/*const locationId = location?.id;

		// if in array, remove. if not in there, add it
		const i = selectedLocations.indexOf(locationId);
		let newSelectedLocations = selectedLocations;
		if (i >= 0) {
			newSelectedLocations.splice(i,1);
		}
		else {
			newSelectedLocations.push(locationId);
		}

		setSelectedLocations(newSelectedLocations)
		setLocationCount(newSelectedLocations.length)

		// announce to parent that something changed
		if (onChange) onChange(newSelectedLocations);*/
	}



	return (
		<ButtonDropdown color="white" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
			<DropdownToggle caret  color="white">
				{!selectedLocation ? "All Locations" : selectedLocation.locationName }
			</DropdownToggle>
			<DropdownMenu style={{padding: 10}}>
		      <FormGroup  check>
		        <Label check>
		          <Input type="radio" 

		          		 checked={!selectedLocation}
		          		 onChange={() => locationChecked({location:null})}
		          		 />{' '}
		          All
		        </Label>
		      </FormGroup>

				{data.locations.map((location,index) =>
			      <FormGroup key={index} check>
			        <Label check>
			          <Input type="radio" 
			          		// defaultChecked={selectedLocations.indexOf(name) != -1}
			          		 checked={selectedLocation?.id === location?.id }
			          		 onChange={() => locationChecked({location})}
			          		 />{' '}
			          {location?.locationName}
			        </Label>
			      </FormGroup>

				)}
				{/*<Button color="link" onClick={() => {setSelectedLocations([]);setLocationCount(0);onChange([])}}>
					Clear All
				</Button>*/}
			</DropdownMenu>
		</ButtonDropdown>
	);
}