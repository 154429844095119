import React, { useState, useEffect } from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button,Spinner, Alert, Form, FormGroup, Label, Col, Input} from 'reactstrap';

import {useMutation,useQuery} from '@apollo/client';
import UpdatePartDetails from '../../graphql/mutations/UpdatePartDetails';
import InventoryCategories from '../../graphql/queries/InventoryCategories';

export default function EditPartDetails({id,location,partName,category,warningThreshold,modalVisible,setModalVisible}) {
	const [errors,setErrors] = useState([]);
	const [formVals,setFormVals] = useState({});



	const setFormValue = ({field,value}) => {
		setFormVals({...formVals,[field]:value});
	}

  const {data} = useQuery(InventoryCategories);
	const [updatePartDetails,{loading}] = useMutation(UpdatePartDetails);

	useEffect(() => {
			setFormVals({
				partName,
				location,
				warningThreshold:warningThreshold ? warningThreshold.toString() : null,
				category: category?.id ? category.id : null
			})

	},[modalVisible]);

	const save = () => {

		const {partName,warningThreshold,location,category} = formVals;

	    // validate
	      let errors = [];

	      if (!partName || partName.trim().length === 0) {
	        errors.push("Please enter a part name")  
	      }
	      if (warningThreshold && warningThreshold.trim().length > 0 && isNaN(warningThreshold)) {
	        errors.push("Please enter a number warning threshold or leave empty")
	      }

	      if (errors.length > 0) {
	        setErrors(errors);
	        return;
	      }

	    updatePartDetails({
	      variables: {
	        id: parseInt(id),
	        partName,
	        warningThreshold: warningThreshold ? parseFloat(warningThreshold) : null,
	        location,
	        category: category ? parseInt(category) : null
	      }


	    })
	      .then(result => {
	        // only close if valid result returned
	        if (result) {
	          setModalVisible(false);
	        }
	        
	       })
	     .catch(err => console.log(err))//Alert.alert("Add Part Error","There was an error adding the part. Please try again")) 

	}


  return (
      <Modal isOpen={modalVisible} toggle={() => setModalVisible(false)}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Update Part Details</ModalHeader>
        <ModalBody>
        	{errors.map((message) => <Alert color="danger" key={message}>- {message}</Alert>)}
	        <Form>

	            <FormGroup >
	          		<Label>Part Name</Label>
	          		<Col>
				       <Input value={formVals.partName || ""} onChange={e => setFormValue({field:"partName",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label >Alert Threshold (when to reorder)</Label>
	          		<Col>
				       <Input type="number" value={formVals.warningThreshold || ""} onChange={e => setFormValue({field:"warningThreshold",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
	            <FormGroup >
	          		<Label>Part Location</Label>
	          		<Col>
				       <Input value={formVals.location || ""} onChange={e => setFormValue({field:"location",value:e.target.value})} />
	              	</Col>
	            </FormGroup>
                <FormGroup >
                  <Label>Category</Label>
                  <Col>
                     <Input type="select" value={formVals.category || ""} onChange={e => setFormValue({field:"category",value:e.target.value})}>
                       <option value="">- Select Category -</option>
                       {data?.inventoryCategories.map(({id,category}) => <option key={id} value={id}>{category}</option>)}
                     </Input>
                    </Col>
                </FormGroup>
	        </Form>




        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>
            Save
            {loading && <Spinner />}
          </Button>{' '}
          <Button color="secondary" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>

  );

}
