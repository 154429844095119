import React, {useState, useEffect,} from 'react';

import {  Alert, Spinner, Form, FormGroup, Input, Label, Row, Col, Button  } from 'reactstrap';

//import {Link} from 'react-router-dom';

import { useMutation } from '@apollo/client';
import ResetPassword from '../../graphql/mutations/auth/ResetPassword';

import { Helmet } from 'react-helmet';

import Logo from "../../assets/images/Logo.png";


export default function Home() {

	const [email,setEmail] = useState();

	const [errorMessage,setErrorMessage] = useState();
	const [resetSuccess,setResetSuccess] = useState(false);


	const [resetPassword,{loading}] = useMutation(ResetPassword, {
	onCompleted:  result => {
		setResetSuccess(true);
	},
	onError: error => {
	        //console.log("error",error)
	        // loading toast blocks header from being clicked
	        const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error resetting your password. Please try again.'
	        setErrorMessage(message);
	}
	})


	useEffect(() => {
		// when leaving page, reset variables
		return () => {
			setEmail(null);
			setResetSuccess(false);
		}
	},[])

	if (resetSuccess) {
		return (
			<Row>
				<Col md={{size: 7, offset:2}} className="text-center p-2 pt-4 mt-5" style={{background: "#ffffff"}}>
			      <div className="text-center mb-0 mt-5">
			        <img className="mb-2" src={Logo} alt="Fleet Command - Dispatch" style={{width:250,height: 145}}/>
			      </div>   
   					<Alert color="success">You should receive an email with password reset instructions momentarily.</Alert>


				</Col>
			</Row>

		)
	}

	return (
			<Row>
				<Helmet>
					<title>Reset Password | Fleet Command</title>
				</Helmet>

				<Col md={{size: 7, offset:2}} className="text-center p-2 pt-4 mt-5" style={{background: "#ffffff"}}>
			      <div className="text-center mb-0 mt-5">
			        <img className="mb-2" src={Logo} alt="Fleet Command - Dispatch" style={{width:250,height: 145}}/>
			      </div>   
   					<Form className="text-left" onSubmit={e => {e.preventDefault();e.stopPropagation()}}>
						{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
						<FormGroup>
							<Label>Email</Label>
							<Input type="text" onChange={e => setEmail(e.target.value)} />
						</FormGroup>
						<Button block color="info" onClick={() => resetPassword({variables:{email}})}>
							Reset Password
							{loading && <Spinner />}
						</Button>
					</Form>

				</Col>
			</Row>

	);
}