import gql from 'graphql-tag';

export default gql`
mutation RemoveWorkOrder(
		$id: ID!,
		$deletedReason: String
  	) {
  removeWorkOrder(
  	id:$id,
    deletedReason:$deletedReason
  ) {
    id
  }
}
`;