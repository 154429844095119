import React from 'react';
import {

  Pagination,
  PaginationItem,
  PaginationLink,

}
 from "reactstrap";

import Icon from '@mdi/react'
import { mdiChevronLeft,mdiChevronRight } from '@mdi/js'


export default function PaginationView({onPrev,onNext}) {
	return (
                      <Pagination
                      className="pagination justify-content-end mb-0  "
                      listClassName="justify-content-end mb-0 "
                    >
                      <PaginationItem >
                        <PaginationLink
                          href="#pablo"
                          onClick={e => {e.preventDefault();onPrev()}}
                          tabIndex="-1"
                        >
                          <Icon path={mdiChevronLeft} size={1} />
                          <span className="sr-only">Previous</span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem>
                        <PaginationLink
                          href="#pablo"
                          onClick={e => {e.preventDefault();onNext()}}
                        >
                          <Icon path={mdiChevronRight} size={1} />
                          <span className="sr-only">Next</span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>       

	);
}