import React, {useState, useContext} from 'react';

import {  Alert, Spinner, Form, FormGroup, Input, Label, Row, Col, Button  } from 'reactstrap';

import { useMutation } from '@apollo/client';
import SignupMutation from '../../graphql/mutations/SignupMutation';

import AuthContext from '../../contexts/AuthContext';
import Logo from "../../assets/images/Logo.png";

import { Helmet } from 'react-helmet';

export default function Home() {

	const [firstName,setFirstName] = useState();
	const [lastName,setLastName] = useState();
	const [company,setCompany] = useState();
	const [email,setEmail] = useState();
	const [password,setPassword] = useState();
	const [phone,setPhone] = useState();
	const [referredBy,setReferredBy] = useState();
	const [acceptTerms,setAcceptTerms] = useState();


	const [errorMessage,setErrorMessage] = useState();

	// after login, trigger loading my info which in turn will set loggedin to true. its an extra call, but want fields loaded in one place
	const { /*reloadMyInfo*/ setFullName,setRoles,setIsLoggedIn } = useContext(AuthContext);

	const [signup,{loading}] = useMutation(SignupMutation, {
	onCompleted:  result => {
	  localStorage.setItem("Authorization",result.signup.token);
	  // refetching in context won't work due to apollo oncomplete not firing, so set stuff from here
			setFullName(`${result.signup.firstName} ${result.signup.lastName}`)
			setRoles(result.signup.roles)
			setIsLoggedIn(true)

	},
	onError: error => {
	        console.log("error",error)
	        // loading toast blocks header from being clicked
	        const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error logging in. Please try again.'
	        setErrorMessage(message);
	}
	})

	const runSignup = () => {
		if (!firstName || !lastName || !company || !email || !password) {
			setErrorMessage("All * fields are required")
			return;
		}

		if (!acceptTerms) {
			alert("Please accept terms to continue.")
			return;
		}

		signup({
			variables: {
				firstName,
				lastName,
				company,
				email,
				password,
				phone,
				referredBy,
				acceptTerms
			}
		});
	}

	return (
			<Row>
				<Helmet>
					<title>Signup | Fleet Command</title>
				</Helmet>
				<Col md={{size: 6, offset:2}} className="text-center p-2 pt-4 mt-5" style={{background: "#ffffff"}}>
			      <div className="text-center mb-0 mt-5">
			        <img className="mb-2" src={Logo} alt="Fleet Command - Dispatch" style={{width:250,height: 145}}/>
			      </div>   
   					<Form className="text-left">
						{errorMessage && <Alert color="danger">{errorMessage}</Alert>}
						<FormGroup>
							<Label>*First Name</Label>
							<Input type="text" onChange={e => setFirstName(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>*Last Name</Label>
							<Input type="text" onChange={e => setLastName(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>*Company</Label>
							<Input type="text" onChange={e => setCompany(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>*Email</Label>
							<Input type="email" onChange={e => setEmail(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>*Password</Label>
							<Input type="password" onChange={e => setPassword(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>Phone</Label>
							<Input type="tel" onChange={e => setPhone(e.target.value)} />
						</FormGroup>
						<FormGroup>
							<Label>referredBy</Label>
							<Input type="text" onChange={e => setReferredBy(e.target.value)} />
						</FormGroup>
			          <FormGroup check>
			            <Label check>
			              <Input type="checkbox" id="checkbox2" checked={(acceptTerms || null) ? true : false} onChange={(e) => setAcceptTerms(e.target.checked ? new Date() : null) } />{' '}
			               I agree to the Fleet Command <a target="_blank" rel="noreferrer" href="https://app.termly.io/document/terms-of-use-for-saas/699c8c34-fe91-48f7-85f5-ced2408e25d6">terms and conditions</a>.
			            </Label>
			          </FormGroup>
								{/*<div className="text-left position-relative form-check"><label className="form-check-label">
											<input {...props} type="checkbox" 
												className="form-check-input"
												checked={props.input.value}
											 onChange={(event) => props.input.onChange(event.target.checked ? new Date() : null)} />
											 <span className="form-check-sign"></span>
						I agree to the <a href="https://app.termly.io/document/terms-of-use-for-saas/699c8c34-fe91-48f7-85f5-ced2408e25d6" target="tc">terms and conditions</a>
						.</label>
						</div>	*/}

						<Button block color="info" onClick={() => runSignup()}>
							Get Started
							{loading && <Spinner />}
						</Button>
					</Form>
				</Col>
			</Row>

	);
}