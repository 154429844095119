import React from 'react';
import { Table, Alert, Spinner} from 'reactstrap';
import { Link } from "react-router-dom";


import { useQuery } from '@apollo/client';
import PurchaseOrders from '../../graphql/queries/PurchaseOrders'

import moment from 'moment';

import Icon from '@mdi/react'
import {mdiCheckCircleOutline, mdiCancel} from '@mdi/js'


export default function PurchaseOrdersTable({offset=0,limit=20,filterFields={}}) {
	const {data,loading,error} = useQuery(PurchaseOrders,{
      variables: {
        ...filterFields,
        //offset,
        //limit
      }
    });

 /* useEffect(() => {
    refetch();
  },[filterFields])*/

	
  if (loading) return <Spinner />
	if (error) return <Alert danger>Error loading purchase orders</Alert>

	return (
        <div className="table-responsive h-100">
                <Table className="align-items-center table-flush"  >

                
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">PO #</th>
                      <th scope="col">PO Date</th>
                      <th scope="col">Supplied</th>
                      <th scope="col"># Items Ordered</th>
                      <th scope="col">Quantity<br />Ordered</th>
                      <th scope="col">Quantity<br />Received</th>
                    </tr>
                  </thead>
                  <tbody>
                  	{data.purchaseOrders.map(({
                  		id,
                      cancelled,
                  		poNumber,
                      poDate,
                      supplier: {
                        supplierName
                      },
                      itemsOrdered,
                      quantityOrdered,
                      quantityReceived,
                  	}) => {

                        return (
                          <tr key={id} >
                            <th scope="row">
                              {/* if completed, show checkmark */}
                              {cancelled && <Icon path={mdiCancel} size={1} style={{color:"#ff0000"}}  />}
                              {quantityOrdered === quantityReceived &&
                                <Icon path={mdiCheckCircleOutline} size={1} style={{color:"#0000ff"}}  />
                              }
                              <Link to={`/PurchaseOrder/${id}`}>{poNumber || "(No PO #)"}</Link>
                            </th>
                            <td>{moment(poDate).format("ll")}</td>
                            <td>{supplierName}</td>
                            <td className="text-center">{itemsOrdered}</td>
                            <td className="text-center">{quantityOrdered}</td>
                            <td className="text-center">{quantityReceived}</td>
                          </tr>
                         )

                    }

                  	)}
                  </tbody>
                </Table>
                {data?.purchaseOrders?.length === 0 && <div className="ml-2 text-muted">No purchaseOrders found</div>}
               </div>

	);
}