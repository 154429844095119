import gql from 'graphql-tag';

import { TRUCK_FRAGMENT } from '../fragments/TruckFragments';


export default gql`
mutation UpdateEquipment(
        $id: ID!,
        $identifier: String!,
        $type: String!,
        $vin: String,
        $make: String,
        $model: String,
        $year: String,
        $licensePlate: String,
        $engineSerialNumber: String,
        $transmissionSerialNumber: String,
        $tireSize: String,
        $federalAnnualInspectionDueDate: String,
        $registrationDueDate: String,
        $servicePlan: ID,
        $udfs: [UdfInput],
        $location: ID
  
) {
  updateTruck(
        id: $id,
        identifier:$identifier,
        type:$type,
        vin:$vin,
        make:$make,
        model:$model,
        year:$year,
        licensePlate:$licensePlate,
        engineSerialNumber:$engineSerialNumber,
        transmissionSerialNumber:$transmissionSerialNumber,
        tireSize:$tireSize,
        federalAnnualInspectionDueDate:$federalAnnualInspectionDueDate,
        registrationDueDate:$registrationDueDate,
        servicePlan:$servicePlan,
        udfs:$udfs,
        location:$location
  ) {
    ...TruckAllFields
  }
}
${TRUCK_FRAGMENT}
`;