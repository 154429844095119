import React, {useState} from "react";
// reactstrap components
import {
  Alert,
  Button,
  Card,

  CardBody,
  FormGroup,
  Form,

  Input,
  Modal
} from "reactstrap"; 

import {useMutation} from '@apollo/client';
import UpdateOdometer from '../../graphql/mutations/UpdateOdometer';


export default function UpdateOdometerModal({id,modalVisible,setModalVisible}) {
  const [mileageIn,setMileageIn] = useState();
  const [errorMessage,setErrorMessage] = useState();

  const [updateOdometer] = useMutation(UpdateOdometer, {
    onCompleted: () => {
      setModalVisible(false);
      setMileageIn();
      setErrorMessage();
    },
    onError: error => {
            // loading toast blocks header from being clicked
            const message = error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : 'There was an error updating the odometer. Please try again.'
            setErrorMessage(message);
    }


  });

  const save = () => {
      if (!mileageIn || isNaN(mileageIn.replace(",",""))) {
        return setErrorMessage("Please enter a valid odometer reading");
      }


      updateOdometer({variables: {
                    id,
                    odometer: parseFloat(mileageIn.replace(/\D/g,''))
                  }})
  }

 const cancel = () => {
      setModalVisible(false);
      setMileageIn();

      setErrorMessage();

 }

  return (
    <div>
            <Modal
              className="modal-dialog-centered"
              size="lg"
              isOpen={modalVisible}
              toggle={() => setModalVisible(false)}
            >
              <div className="modal-body p-0">
                <Card className="shadow border-0">
                 

                  
                  <CardBody >
                    {errorMessage && 
                      <Alert color="danger">
                        {errorMessage}
                      </Alert>
                    }

                    <Form role="form" onSubmit={e => {e.preventDefault();save()}}>
                        <h3>Set current odometer reading</h3>
                        <FormGroup className="mb-0">
                          <Input type="tel" value={mileageIn} placeholder="Odometer Reading" onChange={e => setMileageIn(e.currentTarget.value)} />
                        </FormGroup>

                      

                      <div className="text-center">
                        <Button
                          block
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => save()}
                        >
                          Update Odometer
                        </Button>
                        <Button
                          block
                          className="my-4"
                          color="danger"
                          type="button"
                          onClick={() => cancel()}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Modal>

    </div>
  )
}
