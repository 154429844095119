import gql from 'graphql-tag';

export default gql`
query {
  mechanics {
  	id
    firstName
    lastName
  }
}
`;