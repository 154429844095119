import gql from 'graphql-tag';

import { SERVICE_PLAN_FRAGMENT } from '../fragments/ServicePlanFragments';


export default gql`
query ServicePlan($id: ID!) {
	servicePlan(id:$id) {
    ...ServicePlanAllFields
  }
}
${SERVICE_PLAN_FRAGMENT}
`;