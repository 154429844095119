import React, { useState } from 'react';
import {Link} from 'react-router-dom';
import {Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap';

import iOSAppStore from '../../assets/images/FindUsIOS.png'
import GetItOnGooglePlay from '../../assets/images/GetItOnGooglePlay.png'

export default function AddWorkOrderModal() {
	const [modalVisible,setModalVisible] = useState(true);

	return (
      <Modal  isOpen={modalVisible} toggle={() => setModalVisible(false)}>
       	<ModalHeader toggle={() => setModalVisible(false)}><h3>Add your first work order</h3></ModalHeader>
        <ModalBody>
			<div style={{position:"relative",height:0,width:"100%",paddingBottom:"56.19834710743802%"}}><iframe title="Add a work order" src="https://sendspark.com/embed/3pwl3bo54ve4mht2it63xcgsdwmy7g00" frameBorder="0" style={{position:"absolute",width:"100%",height:"100%",borderRadius:6,left:0,top:0}} ></iframe></div>
        </ModalBody>
        <ModalFooter style={{justifyContent:'center'}}>
            <a target="AppStore" href="https://apps.apple.com/us/app/id1522154125"><img src={iOSAppStore} alt="Fleet Command Shop on iOS" width={195} height={60} /></a>
        	<a target="AppStore" href="https://play.google.com/store/apps/details?id=io.fleetcommand.shop"><img src={GetItOnGooglePlay} alt="Fleet Command Shop on Android" width={195} height={60} /></a>
        	<div>
        		<Link to="/WorkOrder/New"><Button color="primary">Add Work Order</Button></Link>
        	</div>
        </ModalFooter>
      </Modal>
	);	
}

