// gqlFragments.js
import gql from 'graphql-tag';

export const PERMIT_FRAGMENT = gql`
    fragment PermitAllFields on Permit {
      id
      permitName
      trucks {
        type
        identifier
      }
      assignedTo {
        id
        renewalDate
        startDate
        cancelled
        truck {
          id
          identifier
        }
      }
      


    }
`;