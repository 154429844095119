import gql from "graphql-tag";



export default gql`
query Inventory($offset: Int, $limit: Int,$keyword: String,$noBarcodeOnly: Boolean,$missingCost: Boolean,$missingQuantity:Boolean,$category:Int) {
	
	  inventory(offset:$offset,limit:$limit,keyword: $keyword,noBarcodeOnly:$noBarcodeOnly,missingCost:$missingCost,missingQuantity:$missingQuantity,category:$category) {
	    id
	    category {
	    	category
	    }
	    partName
	    location
	    numberInStock
	    value
	    warningThreshold
	    inventoryItems {
	    	id
	    	partDescription
	    	partNumber
	    	vendor
	    	barcodeData
	    }


	  }
	
}
`;