import gql from 'graphql-tag';

export default gql`
query TruckWorkOrdersReport ($truck: ID){
  truckWorkOrdersReport(truck:$truck) {
    identifier
    WorkOrders {
      month
      year
      count
      countStandard
      countPreventative
    }
  }
}`;