import React, { useState, useEffect, useContext } from 'react';

import {
	Container,
	Card,
	CardBody,
	FormGroup,
	Col,
	Label,
	Input,
	Button
} from 'reactstrap';

import UdfFormFields from './UdfFormFields';

import {useQuery} from '@apollo/client';
import Locations from '../../graphql/queries/Locations';
import TruckTypes from '../../graphql/queries/TruckTypes';
import ServicePlans from '../../graphql/queries/ServicePlans';

import AuthContext from '../../contexts/AuthContext';
import moment from 'moment';

import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";


export default function EquipmentForm({id,title,saveEquipment,initialVariables}) {
	//const id = navigation.getParam("id");

	// should propable create a FeatureSection component like exists for AuthSection
	const {features} = useContext(AuthContext);
	const {data:locationsData} = useQuery(Locations);
	const {data:truckTypesData} = useQuery(TruckTypes);
	const {data:servicePlansData} = useQuery(ServicePlans);

	// display control
	//const [showDetailFields,setShowDetailFields] = useState(false)
	//const [showDatePicker,setShowDatePicker] = useState(false)

  	// form values (should probably merge into a single state variable called formValues)
  	const [formFieldValues,setFormFieldValues] = useState({
   			identifier:"",
  			type:"",
  			federalAnnualInspectionDueDate:"",
  			registrationDueDate:"",

  			vin:"",
  			make:"",
  			model:"",
  			year:"",

  			licensePlate:"",
  			engineSerialNumber:"",
  			transmissionSerialNumber:"",
  			tireSize:"",

  			udfs:[],
  			location:""
  	})

  	const [udfs,setUdfs] = useState([]);

  	// get timezone offset for date picker
  	//const timeZoneOffsetInMinutes = -((new Date()).getTimezoneOffset());

  	// simplify setting formFieldValues
  	const setValue = ({name,value}) => {
  		setFormFieldValues({...formFieldValues,[name]:value})
  	}


  	// if initialization variables are sent in, then set state
  	useEffect(() => {
  		
  		if (!id) return;
  		if (initialVariables.udfs) setUdfs(initialVariables.udfs);
  		return setFormFieldValues({...formFieldValues,...initialVariables})

  		// eslint-disable-next-line react-hooks/exhaustive-deps
  	},[initialVariables])


  	const save = () => {

	    // has customer and pickupdate and pickup location and description
	    let validationMessages = [];
	    if (!formFieldValues.identifier || formFieldValues.identifier.length === 0) validationMessages.push("Identifier");
	    if (!formFieldValues.type || formFieldValues.length === 0) validationMessages.push("Type")

	    if (validationMessages.length > 0) {
	    	alert(`The following fields are required: -${validationMessages.join("\n-")}`)

	        return;
	    }


	    const {
	    	identifier,
	    	type,
	    	vin,
	    	make,
	    	model,
	    	year,
	    	licensePlate,
	    	engineSerialNumber,
	    	transmissionSerialNumber,
	    	tireSize,
	    	federalAnnualInspectionDueDate,
	    	registrationDueDate,
	    	location,
	    	servicePlan
	    } = formFieldValues;



		const variables =  {
			id,
	        identifier,
	        type,
	        vin,
	        make,
	        model,
	        year,
	        licensePlate,
	        engineSerialNumber,
	        transmissionSerialNumber,
	        tireSize,
	        federalAnnualInspectionDueDate,
	        registrationDueDate,
	        servicePlan: servicePlan?.id || servicePlan,
	        // only include values for input type (i.e. type name and ID are not needed)
	        udfs:udfs.map(({fieldName,fieldValue,sortOrder}) => ({fieldName,fieldValue,sortOrder})),
	        location: location ? (location?.id ? location.id : location) : null


		}


  		saveEquipment(variables);
  	}

	// NOTE: Made some style accomodations for android. Should create a override header component to handle
	return (
 
        <Container  fluid>
        	<h2>{title || "New Asset"}</h2>
            <Card className="shadow mb-4">
            	<CardBody>
					<FormGroup row>
						<Label sm={2}>*Identifier</Label>
						<Col sm={10}>
							<Input type="text" value={formFieldValues.identifier} onChange={e => setValue({name:"identifier",value:e.target.value})} />
						</Col>
					</FormGroup>
					<FormGroup row>
						<Label sm={2}>*Type</Label>
						<Col sm={10}>
							<Input type="select" value={formFieldValues.type}  onChange={e => {
								setValue({name:"type",value:e.target.value})
							}}>
								<option>Select Type...</option>
								{truckTypesData && truckTypesData.truckTypes.map(({type}) => 
									<option key={type} value={type}>{type} </option>
								)}
							</Input>
						</Col>
					</FormGroup>



					<FormGroup row>
						<Label sm={2}>Federal Annual Inspection Date</Label>
						<Col sm={10}>
							{/*<Input type="date" value={formFieldValues.federalAnnualInspectionDueDate ? moment(formFieldValues.federalAnnualInspectionDueDate).format("YYYY-MM-DD") : ""} onChange={e => setValue({name:"federalAnnualInspectionDueDate",value:e.target.value})} />*/}
						<Datetime input={true} value={formFieldValues.federalAnnualInspectionDueDate ? moment(formFieldValues.federalAnnualInspectionDueDate) : ""} timeFormat={false} onChange={d => setValue({name:"federalAnnualInspectionDueDate",value:d})} />
						</Col>
					</FormGroup>

					<FormGroup row>
						<Label sm={2}>Registration Renewal Date</Label>
						<Col sm={10}>
							{/*<Input type="date" value={formFieldValues.registrationDueDate ? moment(formFieldValues.registrationDueDate).format("YYYY-MM-DD") : ""} onChange={e => setValue({name:"registrationDueDate",value:e.target.value})} />*/}
						<Datetime input={true}  value={formFieldValues.registrationDueDate ? moment(formFieldValues.registrationDueDate) : ""} timeFormat={false} onChange={d => setValue({name:"registrationDueDate",value:d})} />
						</Col>
					</FormGroup>

            	</CardBody>
            </Card>
            <Card className="shadow mb-4">
            	<CardBody>
            		{features.find(l => l === "Locations") && locationsData?.locations &&
			            <FormGroup row>
			          		<Label sm={2}>Location</Label>
			          		<Col sm={10}>
						          <Input type="select" value={formFieldValues?.location?.id || null}  onChange={e => {
						          	console.log("location value",e.target.value)
						          	setValue({name:"location",value:e.target.value})
						          }}>
					              	<option value="">Select Location...</option>
					              	{locationsData.locations.map(({locationName,id}) => 
					              		<option key={id} value={id}>{locationName} </option>
					              	)}
						          </Input>
			              </Col>
			            </FormGroup>
            		}
		            <FormGroup row>
		          		<Label sm={2}>Service Plan</Label>
		          		<Col sm={10}>
					          <Input type="select" value={formFieldValues?.servicePlan?.id || formFieldValues?.servicePlan}  onChange={e => {
					          	setValue({name:"servicePlan",value:e.target.value})
					          }}>
				              	<option>Select Service Plan...</option>
				              	{servicePlansData?.servicePlans.map(({planName,id}) => 
				              		<option key={id} value={id}>{planName}</option>
				              	)}
					          </Input>
		              </Col>
		            </FormGroup>

            	</CardBody>
            </Card>
            <Card className="shadow mb-4">
            	<CardBody>
		            <FormGroup row>
		              <Label sm={2}>VIN</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="VIN" 
		              		 value={formFieldValues.vin || ""}
		              		 onChange={e => setValue({name:"vin",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Plate #</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="License Plate #" 
		              		 value={formFieldValues.licensePlate || ""}
		              		 onChange={e => setValue({name:"licensePlate",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Make</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Make" 
		              		 value={formFieldValues.make || ""}
		              		 onChange={e => setValue({name:"make",value:e.target.value})}

		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Model</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Model" 
		              		 value={formFieldValues.model || ""}
		              		 onChange={e => setValue({name:"model",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Year</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Year" 
		              		 value={formFieldValues.year || ""}
		              		 onChange={e => setValue({name:"year",value:e.target.value})}
		              		 type="tel"
		              		 />
		              </Col>
		            </FormGroup>

            	</CardBody>
            </Card>
            <Card className="shadow mb-4">
            	<CardBody>
		            <FormGroup row>
		              <Label sm={2}>Engine Serial #</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Engine Serial #" 
		              		 value={formFieldValues.engineSerialNumber || ""}
		              		 onChange={e => setValue({name:"engineSerialNumber",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Transmission Serial #</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Transmission Serial #" 
		              		 value={formFieldValues.transmissionSerialNumber || ""}
		              		 onChange={e => setValue({name:"transmissionSerialNumber",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>
		            <FormGroup row>
		              <Label sm={2}>Tire Size</Label>
		              <Col sm={10}>
		              <Input 
		              		 placeholder="Tire Size" 
		              		 value={formFieldValues.tireSize || ""}
		              		 onChange={e => setValue({name:"tireSize",value:e.target.value})}
		              		 />
		              </Col>
		            </FormGroup>

            	</CardBody>
            </Card>
 			{/* User Defined Fields */}
 			<UdfFormFields udfs={udfs} setUdfs={setUdfs} />

	    	<div className="p-4 text-center">
	    		<Button onClick={() => save()} color="primary">Save Asset</Button>
	    	</div>

		</Container>

	);

}