import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Spinner
} from 'reactstrap';

import InventoryAdd from "./InventoryAdd";
import CategoriesModal from "./InventoryCategoriesModal";

import {useLazyQuery} from '@apollo/client';
import InventoryExport from '../../graphql/queries/InventoryExport';


export default function InventoryActinos({offset,limit,filterFields}) {

	const [actionDropdownOpen,setActionDropdownOpen] = useState(false)

	const [modalVisible_InventoryAdd,setModalVisible_InventoryAdd] = useState(false);
	const [modalVisible_Categories,setModalVisible_Categories] = useState(false);

	const [exportInventory,{loading:exporting}] = useLazyQuery(InventoryExport, {
	fetchPolicy: 'network-only',
	variables: filterFields,
	onCompleted: result => window.open(result.inventoryExport)
	});

	return (
		<>
          <ButtonDropdown className="ml-2"  isOpen={actionDropdownOpen} toggle={() => setActionDropdownOpen(!actionDropdownOpen)}>
            <DropdownToggle caret color="primary">
              Actions {exporting && <Spinner size="sm" />}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-right" >
              <DropdownItem onClick={() => setModalVisible_InventoryAdd(true)}>Add Inventory</DropdownItem>
              <DropdownItem onClick={() => exportInventory({variables:filterFields})}>Export</DropdownItem>
              <DropdownItem><Link color="primary" to="/Inventory/CodeSheet">Print QR Codes</Link></DropdownItem>
              <DropdownItem onClick={() => setModalVisible_Categories(true)}>Add Inventory Category</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>              

          <InventoryAdd modalVisible={modalVisible_InventoryAdd} 
                        setModalVisible={setModalVisible_InventoryAdd}
                        refreshParams={{offset,limit,filterFields}}
                         />
                        
          <CategoriesModal modalVisible={modalVisible_Categories} 
                        setModalVisible={setModalVisible_Categories}
                        
                         />

		</>
	);
}