import gql from 'graphql-tag';

export default gql`
	mutation GeneratePassword($id: ID!) {
	  generatePassword(id:$id) {
	    id
	    firstName
	    lastName
	  }
	}
`;