import gql from 'graphql-tag';

export default gql`
mutation AddLocation ($locationName: String!) {
  addLocation(locationName: $locationName) {
  	  id
      locationName
  }

}

`;