import gql from 'graphql-tag';

export default gql`
query WorkOrders($myWorkOrders: Boolean,$offset: Int, $limit: Int,
    $statuses:[String],
    $priorities:[String],
    $assignedTo:[String],
    $reportedBy:[String],
    $outOfService:Boolean,
    $truck: String,
    $keyword: String

  ) {
  workOrders(myWorkOrders:$myWorkOrders,offset:$offset,limit:$limit,priorities:$priorities,statuses:$statuses,assignedTo:$assignedTo,reportedBy:$reportedBy,outOfService:$outOfService,truck:$truck,keyword:$keyword) {
      id
      truck {
        id
        identifier
        type
      }
      title
      description
      reportedBy {
        firstName
        lastName
      }
      status
      priority
      ticketNumber
      outOfService
      createdAt
      assignedTo {
        id
        firstName
        lastName
      }
    
  }
}
`;