import gql from 'graphql-tag';

export default gql`
mutation ChangePassword($currentPw: String!,$newPw: String!,$newPw2: String!) {
	changePassword(
    currentPw:$currentPw,
    newPw:$newPw,
    newPw2:$newPw2
  ) {
    firstName
    lastName
    id
  }
}
`;