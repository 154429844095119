import gql from 'graphql-tag';
import { PERMIT_FRAGMENT } from '../fragments/PermitFragments';
 

export default gql`
mutation AddAssetToPermit($permit: ID!,$truck: ID!,$startDate:DateTime!,$renewalDate: DateTime!) {
  addAssetToPermit(permit:$permit,truck:$truck,startDate:$startDate,renewalDate:$renewalDate) {
    ...PermitAllFields
  }
}
${PERMIT_FRAGMENT}
`;
