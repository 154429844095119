import gql from 'graphql-tag';

import { SERVICE_PLAN_FRAGMENT } from '../fragments/ServicePlanFragments';


export default gql`
mutation AddServicePlan($planName: String!, $measurementType: String!) {
	addServicePlan(planName:$planName,measurementType:$measurementType) {
		...ServicePlanAllFields
  }
}
${SERVICE_PLAN_FRAGMENT}
`;
