import React from 'react';

const planCard = ({plan, activeEquipmentCount,openCheckout,isCurrentPlan=false}) => 

		    <div className="card mb-4 shadow-sm">

		      <div className="card-body">
		        <h2 className="card-title pricing-card-title">{plan.price} <small className="text-muted"></small></h2>
		        <ul className="list-unstyled ">
		          <li>Up to {plan.unitCount} units</li>
		        </ul>
		        
		      </div>
		      <div className="card-footer">

		      		{plan.available === 1 && !isCurrentPlan && 
						<button onClick={() => openCheckout(plan.planId)} type="button" className="btn btn-block btn-primary">Get Started</button>
		      		}
		      		{!plan.available && 
						<em>You have {activeEquipmentCount} units in service</em>
		      		}
		      		{isCurrentPlan && 
						<em>Current Plan</em>
		      		}

		      </div>
		    </div>

export default planCard;