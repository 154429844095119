import React, { useState, useEffect } from 'react';
import {Modal,ModalHeader,ModalBody,ModalFooter, Form, FormGroup, Label, Input, Col, Button} from 'reactstrap';
import moment from 'moment';

import {useMutation} from '@apollo/client';
import UpdatePermitDates from '../../graphql/mutations/UpdatePermitDates';
import Permit from '../../graphql/queries/Permit';

export default function  UpdateDatesModal({permit,startDate,renewalDate,assignedTo,onComplete,modalVisible,setModalVisible}) {

	const [formFieldValues,setFormFieldValues] = useState({
		startDate,
		renewalDate
	});

	useEffect(() => {
		
		setFormFieldValues({startDate,renewalDate})
	},[startDate,renewalDate])

	const [updatePermitDate] = useMutation(UpdatePermitDates, {
		refetchQueries: [{query: Permit,variables: {id:permit}}]
	});

  	// simplify setting formFieldValues
  	const setValue =  ({name,value}) => {
  			
  		 setFormFieldValues({...formFieldValues,[name]:value})
  	}

  	const save = () => {
  		// send appropriate variables
  		let variables = {permit:permit};

  		// if assignedTo passed in, update it with start and renewal dates
  		if (assignedTo) {

  			let newAssignedTo = {
  				id: assignedTo.id,
  				truck: assignedTo.truck.id,
  				startDate:moment(formFieldValues.startDate),
  				renewalDate:moment(formFieldValues.renewalDate),
  				cancelled: assignedTo.cancelled
  			};

  			variables = {...variables,assignedTo: newAssignedTo}
  		}
  		// else update entire permit
  		else {
  			variables = {...variables,startDate:moment(formFieldValues.startDate),renewalDate:moment(formFieldValues.renewalDate)}
  		}
  		updatePermitDate({variables})
  			.then(() => {
  				if (onComplete) onComplete();
  				setModalVisible(false);
  			})
  	}



	return (
      <Modal backdrop={ 'static' } isOpen={modalVisible} toggle={() => setModalVisible(false)}  keyboard={true}>
        <ModalHeader toggle={() => setModalVisible(false)}>Update permit date for {(assignedTo && modalVisible) ? assignedTo.truck.identifier : "all permit vehicles"}</ModalHeader>
        <ModalBody>
	          <Form  style={{marginBottom: 20}}>
		            <FormGroup row>
		          		<Label sm={4}>*Start Date</Label>
		          		<Col sm={8}>
							<Input type="date" value={formFieldValues.startDate || ""} onChange={e => setValue({name:"startDate",value:e.target.value})} />
		              	</Col>
		            </FormGroup>
		            <FormGroup row>
		          		<Label sm={4}>*Renewal Date</Label>
		          		<Col sm={8}>
							<Input type="date" value={formFieldValues.renewalDate || ""} onChange={e => setValue({name:"renewalDate",value:e.target.value})} />
		              	</Col>
		            </FormGroup>
		      </Form>

        </ModalBody>
        <ModalFooter>
        	<Button color="primary" onClick={() => save()}>Update</Button>
        	<Button color="warning" onClick={() => setModalVisible(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>


	);

	

}

