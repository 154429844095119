import React from 'react';
import { Table, Alert, Spinner, Button} from 'reactstrap';
import { Link } from "react-router-dom";



import { useQuery, useMutation } from '@apollo/client';
import InspectionTemplates from '../../graphql/queries/InspectionTemplates'
import RemoveInspectionTemplate from '../../graphql/mutations/RemoveInspectionTemplate'


import Icon from '@mdi/react'
import {mdiCheck} from '@mdi/js'





export default function InspectionTemplateTable({cardHeight}) {
	

  const {data,loading,error} = useQuery(InspectionTemplates);
  const [removeInspectionTemplate,{loading:removing}] = useMutation(RemoveInspectionTemplate,{
    refetchQueries: [{query:InspectionTemplates}]
  })



	if (loading) return <Spinner />;
	if (error) return <Alert danger>Error loading templates</Alert>

  const remove = ({id,templateName}) => {
    if (window.confirm(`Are you sure you want to remove ${templateName}?`)) {
      removeInspectionTemplate({variables:{id}});
    }
  }

  return (
        <div className="table-responsive" style={cardHeight ? {height:cardHeight} : {}}>
                <Table className="align-items-center table-flush"  >

      <thead>
        <tr>
            <th>Template Name</th>
            <th>Applies To</th>
            <th className="text-center">Pre-trip?</th>
            <th className="text-center">Remove</th>

        </tr>
      </thead>
      <tbody>
          {data.inspectionTemplates
            .map(({
            id,
            templateName,
            appliesTo,
            isPretrip
          }) =>
            <tr key={id}>
              <th scope="row" style={{overflow: "hidden",maxWidth: 200}}>
                <Link to={`/InspectionTemplate/${id}`}>{templateName}</Link><br />
              </th>
              <td>{appliesTo.join(", ")}</td>
              <td className="text-center">{isPretrip && <Icon path={mdiCheck} size={1} />}</td>
              <td className="text-center">
                {removing && <Spinner size="sm" />}
                {!removing && <Button color="danger" onClick={() => remove({id,templateName})}>Remove</Button>}
              </td>
            </tr>
         )}

      </tbody>
    </Table>
    </div>
  );


}