import React from 'react';
import EmployeeForm from '../components/People/EmployeeForm';
import { Spinner } from 'reactstrap';

import { useMutation, useQuery } from '@apollo/client';
import UpdateEmployee from '../graphql/mutations/UpdateEmployee';
import Employee from '../graphql/queries/Employee';

import {useHistory} from 'react-router-dom';


function UpdateEmployeeScreen({match:{params:{id}}}) {
	const history = useHistory();
	//const id = navigation.getParam('id')

	const {data} = useQuery(Employee, {
		variables: {
			id
		}
	})

	const [updateEmployee,{loading}] = useMutation(UpdateEmployee,{

		onCompleted: () => history.goBack(),
		onError: error => {
				alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error updating employee . Please try again.`)
			}

	})


  


  	// save function. putting it here so view can take advantage of loading var of mutation
	const saveEmployee = async (variables) => {
		updateEmployee({variables})
	} 


	if (!data) return <Spinner />

	return (
			<>
				{loading && <div className="h-100 w-100 text-center" style={{padding: "50%",position:"absolute",zIndex: 100,background:"rgba(0,0,0,.5)"}}><Spinner /></div>}
				<EmployeeForm id={data.employee.id}  title="Edit" saveEmployee={saveEmployee} initialVariables={data.employee} />
			</>
		   )

	


}



export default UpdateEmployeeScreen;
