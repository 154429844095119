import gql from 'graphql-tag';

import { WORK_ORDER_FRAGMENT } from '../fragments/WorkOrderFragments';


export default gql`
mutation AssignTo($id:ID!, $assignedTo: ID!) {
  assignTo(id: $id, assignedTo:$assignedTo) {
    ...WorkOrderAllFields
  }
}
${WORK_ORDER_FRAGMENT}

`;