import React, { useState, useEffect } from 'react';

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,

	Label,
	Button,
	Input,
	FormGroup,
	Col
} from "reactstrap";


/*function formatPhone(phone) {
	if (!phone || phone.lenght === 0) {
		return "";
	}

	return `(${phone.substring(0)})`;
}*/

export default function PhoneModal({modalVisible=false,setModalVisible,setPhone,number,type="Mobile"}) {
	const [phoneType,setPhoneType] = useState("Mobile")
	const [phoneNumber,setPhoneNumber] = useState()

	useEffect(() => {
		setPhoneNumber(number);
		setPhoneType(type)
	},[number])


	const cancel = () => {
		setModalVisible(false);
        setPhoneNumber("");
        setPhoneType("Mobile");
	}

	const save = () => {
		setPhone({
			type:phoneType,
			number:phoneNumber
		})

		setModalVisible(false);
	    setPhoneNumber("");
	    setPhoneType("Mobile");
	}

	return (
      <Modal isOpen={modalVisible} toggle={() => cancel()}  backdrop={true} keyboard={true}>
        <ModalHeader toggle={() => cancel()}>Add Phone</ModalHeader>
        <ModalBody>
        	<FormGroup row>
        		<Label sm={2}>Type</Label>
        		<Col sm={10}>
		          <Input type="select" value={phoneType}  onChange={e => setPhoneType(e.target.value)}>
	              	<option>Select Type...</option>
	              	<option value="Mobile">Mobile</option>
	              	<option value="Home">Home</option>
		          </Input>
        		</Col>
        	</FormGroup>
        	<FormGroup row>
        		<Label sm={2}>Number</Label>
        		<Col sm={10}>
		          <Input type="tel" value={phoneNumber}  onChange={e => {
		          	const text = e.target.value;
				    const cleaned = ('' + text).replace(/\D/g, '')
				    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
				    if (match) {
				        const intlCode = (match[1] ? '+1 ' : ''),
				            number = [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');

				        setPhoneNumber(number);

				        return;
				    }


		          	setPhoneNumber(text)

		          }} />
        		</Col>
        	</FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => save()}>Save</Button>{' '}
          <Button color="secondary" onClick={() => cancel()}>Cancel</Button>
        </ModalFooter>
      </Modal>


	)
}