import React, {useState} from "react";

// reactstrap components
import {
  Spinner,
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,

Card, CardHeader, CardTitle, CardBody
}
 from "reactstrap";




import ServicePlanTable from '../components/ServicePlans/ServicePlanTable';

import { useMutation } from '@apollo/client';
import AddServicePlan from "../graphql/mutations/AddServicePlan"; 
import ServicePlans from "../graphql/queries/ServicePlans"; 

export default function ServicePlansScreen() {

  const [formFieldValues,setFormFieldValues] = useState({measurementType:"miles"})

  const [addServicePlan,{loading}] = useMutation(AddServicePlan, {
    refetchQueries:[{query:ServicePlans}]
  });

  const save = () => {

    const {planName,measurementType} = formFieldValues;

    // all fields required
    if (!planName || !measurementType) {
      alert("Please fill in all fields")
      return;
    }

    addServicePlan({variables:{planName,measurementType}})
      .then(() => setFormFieldValues({measurementType:"miles"}))
      .catch(error => alert(error.graphQLErrors && error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : `There was an error adding the service plan. Please try again.`))
  }

	return (

        <Container fluid>

               <Card className="shadow mb-4">
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Add Service Plan</CardTitle></CardHeader>
                  <CardBody>
                  <Row className="align-items-center">
                    <Col>

    			            <Form onSubmit={e => e.preventDefault()} className="">
    			              <FormGroup className="mb-0" >    			                
    			                  <Input placeholder="Plan Name (i.e. All Trucks, Flatbed Trailers, etc)" type="text"  value={formFieldValues.planName || ""}  onChange={(e) => setFormFieldValues({...formFieldValues,planName:e.target.value})} />
    			              </FormGroup>
                        <FormGroup className="mb-1" >

                            <Input type="select" 
                               value={formFieldValues.measurementType || ""}  onChange={(e) => setFormFieldValues({...formFieldValues,measurementType:e.target.value})}

                             >
                              <option value="miles">Track in miles</option>
                              <option value="hours">Track in hours</option>
                            </Input>
                        </FormGroup>
                        <Button block color="primary" onClick={() => save()} >
                          Create Service Plan 
                          {loading && <Spinner size="sm" className="ml-2" />}
                        </Button>
    			            </Form>
                    </Col>
                   {/* <div className="col text-right">
                      <Button color="primary" onClick={() => alert("create plan")}>
                      	Add Inventory
                      </Button>

                    </div>*/}
                  </Row>
                  </CardBody>
 
 
              </Card> 

               <Card className="shadow">
                <CardHeader style={{borderBottom: 0,background: "#ffffff",fontWeight: 600,fontSize:14}}><CardTitle className="m-0">Service Plans</CardTitle></CardHeader>
                <CardBody className="p-0">
                   <ServicePlanTable />
                </CardBody>
               </Card>


                        
                        
        </Container>

      );

}
